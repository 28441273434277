const StyledSelectBoxMap = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? '#fff' : '#000',
      paddingLeft : '30px',     
      borderBottom: '1px dotted #eee'

    }),
     control : (provided,state) => ({
      ...provided, 
      minHeight: '28px',
      borderRadius: '12px'
    }),
    menu : (provided,state) => ({
      ...provided, 
      margin : 0
    }),
    placeholder : (provided,state) => ({
     ...provided,
     fontSize: '17px'
    }),
        singleValue : (provided,state) => ({
      ...provided, 
            fontWeight: 'bold',  
             fontSize: '17px'              
    }),
    groupHeading : (provided, state) => ({
      backgroundColor: '#eee',
      padding: '10px',
      margin: 0,
      fontWeight : 'bold'
    })
  }

  export default StyledSelectBoxMap;