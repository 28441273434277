import React, { useEffect, useState, useRef } from "react";

import drawToolMarker from "../assets/drawtool_marker.svg";
import drawToolMarkerHov from "../assets/drawtool_marker_hov.svg";
import drawToolMarkerSelect from "../assets/drawtool_marker_select.svg";
import drawToolPolyline from "../assets/drawtool_polyline.svg";
import drawToolPolylineHov from "../assets/drawtool_polyline_hov.svg";
import drawToolPolylineSelect from "../assets/drawtool_polyline_select.svg";
import drawToolRouteCar from "../assets/drawtool_route_car.svg";
import drawToolRouteCarHov from "../assets/drawtool_route_car_hov.svg";

import drawToolRouteCarSelect from "../assets/drawtool_route_car_select.svg";
import drawToolRouteBikeSelect from "../assets/drawtool_route_bike_select.svg";
import drawToolRouteWalkSelect from "../assets/drawtool_route_walk_select.svg";
import drawToolCar from "../assets/drawtool_car.svg";
import drawToolCarHov from "../assets/drawtool_car_hov.svg";
import drawToolCarSelect from "../assets/drawtool_car_select.svg";
import drawToolBike from "../assets/drawtool_bike.svg";
import drawToolBikeHov from "../assets/drawtool_bike_hov.svg";
import drawToolBikeSelect from "../assets/drawtool_bike_select.svg";
import drawToolWalk from "../assets/drawtool_walk.svg";
import drawToolWalkHov from "../assets/drawtool_walk_hov.svg";
import drawToolWalkSelect from "../assets/drawtool_walk_select.svg";
import drawToolCircle from "../assets/drawtool_circle.svg";
import drawToolCircleHov from "../assets/drawtool_circle_hov.svg";
import drawToolCircleSelect from "../assets/drawtool_circle_select.svg";
import drawToolKML from "../assets/drawtool_kml.svg";
import drawToolKMLHov from "../assets/drawtool_kml_hov.svg";
import drawToolKMLSelect from "../assets/drawtool_kml_select.svg";

export default function SearchTools({ searchmode, resetPoints }) {
  const toolsRef = useRef();

  const [searchMode, setSearchMode] = useState("marker");
  const [searchToolTransportBar, setSearchToolTransportBar] = useState(false);

  useEffect(() => {
    document.addEventListener("mousemove", handleClickOutside, true);
  }, [toolsRef]);

  const handleClickOutside = (e) => {
    if (toolsRef.current && !toolsRef.current.contains(e.target)) {
      setSearchToolTransportBar(false);
    }
  };

  const searchModeSelect = (mode) => {
    resetPoints(true);
    setSearchMode(mode);
    searchmode(mode);
  };

  const searchModeRouteImgSelect = (drawMode) => {
    if (drawMode === "car") {
      return drawToolRouteCarSelect;
    } else if (drawMode === "bicycle") {
      return drawToolRouteBikeSelect;
    } else if (drawMode === "pedestrian") {
      return drawToolRouteWalkSelect;
    } else {
      return drawToolRouteCar;
    }
  };

  return (
    <div className="search-tools">
      <div
        onClick={() => searchModeSelect("marker")}
        alt="center marker tool"
        title="center marker tool">
        <img
          src={searchMode === "marker" ? drawToolMarkerSelect : drawToolMarker}
          alt=""
          onMouseOver={(e) =>
            (e.currentTarget.src =
              searchMode === "marker" ? drawToolMarkerSelect : drawToolMarkerHov)
          }
          onMouseOut={(e) =>
            (e.currentTarget.src = searchMode === "marker" ? drawToolMarkerSelect : drawToolMarker)
          }
        />
      </div>
      <div onClick={() => searchModeSelect("polyline")} alt="line tool" title="line tool">
        <img
          src={searchMode === "polyline" ? drawToolPolylineSelect : drawToolPolyline}
          alt=""
          onMouseOver={(e) =>
            (e.currentTarget.src =
              searchMode === "polyline" ? drawToolPolylineSelect : drawToolPolylineHov)
          }
          onMouseOut={(e) =>
            (e.currentTarget.src =
              searchMode === "polyline" ? drawToolPolylineSelect : drawToolPolyline)
          }
        />
      </div>
      <div className="drawtools-route" ref={toolsRef}>
        <div
          style={{ display: "inline-block", paddingRight: "4px", float: "left" }}
          onMouseEnter={() => setSearchToolTransportBar(true)}
          onClick={() => searchModeSelect("car")}
          alt="route tool"
          title="route tool">
          <img
            src={searchModeRouteImgSelect(searchMode)}
            alt=""
            onMouseOver={(e) =>
              (e.currentTarget.src =
                searchMode === "car" || searchMode === "bicycle" || searchMode === "pedestrian"
                  ? searchModeRouteImgSelect(searchMode)
                  : drawToolRouteCarHov)
            }
            onMouseOut={(e) =>
              (e.currentTarget.src =
                searchMode === "car" || searchMode === "bicycle" || searchMode === "pedestrian"
                  ? searchModeRouteImgSelect(searchMode)
                  : drawToolRouteCar)
            }
          />
        </div>

        <div className="drawtools-route-transport-bar">
          {searchToolTransportBar && (
            <div>
              <div
                className="drawtools-route-transport-item"
                onClick={() => searchModeSelect("car")}
                alt="car select"
                title="car select">
                <img
                  src={searchMode === "car" ? drawToolCarHov : drawToolCar}
                  alt=""
                  onMouseOver={(e) => (e.currentTarget.src = drawToolCarHov)}
                  onMouseOut={(e) => (e.currentTarget.src = drawToolCar)}
                />
              </div>
              <div
                className="drawtools-route-transport-item"
                onClick={() => searchModeSelect("bicycle")}
                alt="bike select"
                title="bike select">
                <img
                  src={searchMode === "bicycle" ? drawToolBikeHov : drawToolBike}
                  alt=""
                  onMouseOver={(e) => (e.currentTarget.src = drawToolBikeHov)}
                  onMouseOut={(e) => (e.currentTarget.src = drawToolBike)}
                />
              </div>
              <div
                className="drawtools-route-transport-item"
                onClick={() => searchModeSelect("pedestrian")}
                alt="walk select"
                title="walk select">
                <img
                  src={searchMode === "pedestrian" ? drawToolWalkHov : drawToolWalk}
                  alt=""
                  onMouseOver={(e) => (e.currentTarget.src = drawToolWalkHov)}
                  onMouseOut={(e) => (e.currentTarget.src = drawToolWalk)}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div onClick={() => searchModeSelect("circle")} alt="circle tool" title="circle tool">
        <img
          src={searchMode === "circle" ? drawToolCircleSelect : drawToolCircle}
          alt=""
          onMouseOver={(e) =>
            (e.currentTarget.src =
              searchMode === "circle" ? drawToolCircleSelect : drawToolCircleHov)
          }
          onMouseOut={(e) =>
            (e.currentTarget.src =
              searchMode === "circle" ? drawToolCircleSelect : drawToolCircle)
          }
        />
      </div>
     {/* <div onClick={() => searchModeSelect("kml")} alt="upload kml tool" title="upload kml tool">
        <img
          src={searchMode === "kml" ? drawToolKMLSelect : drawToolKML}
          alt=""
          onMouseOver={(e) =>
            (e.currentTarget.src =
              searchMode === "kml" ? drawToolKMLSelect : drawToolKMLHov)
          }
          onMouseOut={(e) =>
            (e.currentTarget.src =
              searchMode === "kml" ? drawToolKMLSelect : drawToolKML)
          }
        />
        </div>*/}
    </div>
  );
}
