// Dependencies
import { useState, useEffect } from "react";
//import { useMutation } from "@apollo/client";
import axios from "axios";

// Material ui
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

// js pages
import BootstrapDialogTitle from "./BootstrapDialogTitle";

export default function EditEventModal(props) {
  const {
    openEditRemote,
    eventId,
    eventTitle,
    locationTitle,
    eventDesc,
    dateOld,
    userID,
    sendOpenState,
    refetchData,
  } = props;

  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState(eventTitle);
  const [date, setDate] = useState(dateOld);
  const [outputDate, setOutputDate] = useState(null);
  const [description, setDescription] = useState(eventDesc);

  const handleDate = (newValue) => {
    setOutputDate(null);
    setDate(newValue);
    const dateString = JSON.stringify(newValue);
    const removeTime = dateString.substring(1, 11);
    setOutputDate(removeTime);
  };

  useEffect(() => {
    openEditRemote && setOpen(true);
  }, [openEditRemote, event]);

  const handleClose = () => {
    sendOpenState(false);
    return null;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    console.log("eventid: ", eventId);
    if (event !== "") {
      const body = {
        title: event,
        description: description,
        users_permissions_user: userID,
        date: outputDate === null ? dateOld : outputDate,
      };

      const updateRes = await axios({
        method: "PUT",
        url: `/api/myevents/updateevent/${eventId}`,
        data: body,
      });
      if (updateRes.status === 200) {
        refetchData();
        sendOpenState(false);
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Edit event
      </BootstrapDialogTitle>
      <DialogContent>
        <label>
          <h4>Name this event</h4>
        </label>
        <input
          placeholder={`e.g.: visiting ${locationTitle}`}
          value={event}
          type="text"
          onChange={(e) => setEvent(e.target.value)}
        />
        {<p style={{ color: "red" }}>please think of a name for your next trip</p>}
        <label>
          <h4>A short description</h4>
        </label>
        <textarea
          rows="5"
          cols="50"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <label>
          <h4>Date of visit</h4>
        </label>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            hiddenlabel="true"
            inputFormat="dd/MM/yyyy"
            value={date}
            onChange={handleDate}
            disablePast={true}
            clearable={true}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>

        <DialogActions>
          <button className="tstbtn medium radiusall" onClick={handleClose}>
            Cancel
          </button>
          <button className="tstbtn medium radiusall" onClick={handleSubmit}>
            OK
          </button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
