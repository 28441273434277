import gql from "graphql-tag";

const QUERY_RELEASENOTES = gql`
query ReleaseNotes{
  releasenotes (sort : "releasedate:desc")  {
    data {
      id
      attributes {
        version
        releasedate
        features
        bugfix
        removed
      }
    }
  }
}
`
export default QUERY_RELEASENOTES;