// dependencies
import React, { useRef, useEffect, useState } from "react";
import axios from "axios";
import { Animated, StyleSheet, SafeAreaView } from "react-native";
import { useNavigate } from "react-router-dom";

// assets
import heroMarker from "../assets/hero-marker.png";

import heroImg1 from "../assets/1_Krakow_Cloth_Hall.jpg";
import heroImg2 from "../assets/2_NorthCape.webp";
import heroImg3 from "../assets/3_Eilean_Donan_castle.jpg";
import heroImg4 from "../assets/4_Saint_Peters_Basilica.jpg";
import heroImg5 from "../assets/5_Maifellshnjukur.jpg";
import heroImg6 from "../assets/6_library_of_trinity_college_dublin.webp";
import heroImg7 from "../assets/7_Kyiv_PecherskLavra.jpg";

const FadeInImage = () => {
  const navigate = useNavigate();
  const [heroImg, setHeroImg] = useState(null);
  const [heroData, setHeroData] = useState(null);

  const fadeAnim = useRef(new Animated.Value(0)).current;

  const getHeroImg2 = () => {
    const d = new Date();
    let day = d.getDay();
    var id = day + 1;

    switch (id) {
      case 1:
        return heroImg1;
        break;
      case 2:
        return heroImg2;
        break;
      case 3:
        return heroImg3;
        break;
      case 4:
        return heroImg4;
        break;
      case 5:
        return heroImg5;
        break;
      case 6:
        return heroImg6;
        break;
      case 7:
        return heroImg7;
        break;
      default:
        return heroImg1;
    }
  };

  const getHeroImg = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/heroimages`)
      .then((data) => {
        const { tripdatum } = data.data;
        setHeroData(tripdatum && tripdatum);
      })
      .catch((err) => {
        console.log("error: ", err);
      });
  };
  useEffect(() => {
    const fadeIn = () => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 3000,
      }).start();
    };

    getHeroImg();
    fadeIn();
  }, [fadeAnim]);

  return (
    <SafeAreaView style={styles.container}>
      <Animated.View style={[styles.fadingContainer, { opacity: fadeAnim }]}>
        {<img id="heroimage" src={getHeroImg2()} alt="heroimg" />}
        {heroData && heroData !== null && (
          <div
            className="hero-infobar"
            onClick={() => navigate({ pathname: `/map/${heroData.Slug}` })}>
            <img src={heroMarker} alt="" width={20} height={20} />
            Daily inspiration: <span>{heroData.title + ", " + heroData.country}</span>
          </div>
        )}
      </Animated.View>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  fadingContainer: {
    backgroundColor: "#090a1a",
  },
  fadingText: {
    fontSize: 28,
  },
  buttonRow: {
    flexBasis: 100,
    justifyContent: "space-evenly",
    marginVertical: 16,
  },
});

export default FadeInImage;
