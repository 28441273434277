// Dependencies
import { useState, useEffect } from "react";
//import { useMutation } from "@apollo/client";
import axios from "axios";

// Material ui
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

// js pages
import BootstrapDialogTitle from "./BootstrapDialogTitle";

export default function EditTripModal({
  openEditRemote,
  tripId,
  tripGroup,
  tripDesc,
  fromDateOld,
  toDateOld,
  userID,
  sendOpenState,
  refetchData,
}) {
  const [open, setOpen] = useState(false);
  const [trip, setTrip] = useState(tripGroup);
  const [isEmpty, setIsEmpty] = useState(false);
  const [fromDate, setFromDate] = useState(fromDateOld);
  const [toDate, setToDate] = useState(toDateOld);
  const [errorMsgDate, setErrorMsgDate] = useState("");
  const [description, setDescription] = useState(tripDesc);

  const handleDateFrom = (newValue) => {
    console.log("newValue");
    if (newValue !== null) {
      const dateString = JSON.stringify(newValue);
      const removeTime = dateString.substring(1, 11);
      setFromDate(removeTime);
    } else {
      setFromDate(null);
    }
  };

  const handleDateTo = (newValue) => {
    if (newValue !== null) {
      const dateString = JSON.stringify(newValue);
      const removeTime = dateString.substring(1, 11);
      setToDate(removeTime);
    } else {
      setToDate(null);
    }
  };

  useEffect(() => {
    openEditRemote && setOpen(true);
  }, [openEditRemote, trip]);

  const handleClose = () => {
    sendOpenState(false);
    return null;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (trip !== "") {
      console.log("new group: ", trip);
      console.log("tripid: ", tripId);

      if (fromDate > toDate) {
        setErrorMsgDate("End date cannot be earlier than start date!");
        return null;
      }

      if (fromDate === null && toDate !== null) {
        setErrorMsgDate("A trip should have a start and end date or leave them both empty");
        return null;
      }

      if (toDate === null && fromDate !== null) {
        setErrorMsgDate("A trip should have a start and end date or leave them both empty");
        return null;
      }

      const body = {
        group: trip,
        description: description,
        users_permissions_user: userID,
        from: fromDate,
        to: toDate,
      };

      const updateRes = await axios({
        method: "PUT",
        url: `/api/mytrips/updatetrip/${tripId}`,
        data: body,
      });

      if (updateRes.status === 200) {
        refetchData();
        sendOpenState(false);
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Edit trip
      </BootstrapDialogTitle>
      <DialogContent>
        <label>
          <h4>Name your trip</h4>
        </label>
        <input value={trip} type="text" onChange={(e) => setTrip(e.target.value)} />
        {isEmpty && <p style={{ color: "red" }}>please think of a name for your next trip</p>}
        <label>
          <h4>A short description</h4>
        </label>
        <textarea
          rows="5"
          cols="50"
          name="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <label>
          <h4>Start date</h4>
        </label>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            hiddenlabel="true"
            inputFormat="dd/MM/yyyy"
            value={fromDate}
            onChange={handleDateFrom}
            clearable={true}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <label>
          <h4>End date</h4>
        </label>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            hiddenlabel="true"
            inputFormat="dd/MM/yyyy"
            value={toDate}
            onChange={handleDateTo}
            clearable={true}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <p style={{ color: "red" }}>{errorMsgDate}</p>
        <DialogActions>
          <button className="tstbtn medium radiusall" onClick={handleClose}>
            Cancel
          </button>
          <button className="tstbtn medium radiusall" onClick={handleSubmit}>
            OK
          </button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
