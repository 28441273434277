import gql from "graphql-tag";

const QUERY_CATEGORYGROUPS = gql`
query CategoryGroups {
  categoryGroups (sort: "group:asc") {
    data {
      id
      attributes {
        group
        categories (sort: "category:asc", pagination: { limit: 20 }) {
          data {
            id
            attributes {
              category
              category_sin
              category_uid
            }
          }
        }
      }
    }
  }
}
`;

export default QUERY_CATEGORYGROUPS;


