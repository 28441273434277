import tokml from "geojson-to-kml";

export default function generateKML(
  departuretitle,
  departure,
  arrivaltitle,
  arrival,
  polyline,
  transport
) {
  var convertedLatLng = [];

  for (var i = 0; i < polyline.length; i++) {
    convertedLatLng.push([polyline[i][1], polyline[i][0]]);
  }

  const geojsonObject = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [departure.lng, departure.lat],
        },
        properties: {
          name: departuretitle,
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: convertedLatLng,
        },
        properties: {
          name: "Route",
        },
      },
      {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [arrival.lng, arrival.lat],
        },
        properties: {
          name: arrivaltitle,
        },
      },
    ],
  };

  const kml = tokml(geojsonObject);
  const blob = new Blob([kml], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  const transportName = transport.charAt(0).toUpperCase() + transport.slice(1);
  link.download = `${transportName}-route-to-${arrivaltitle}.kml`;
  link.href = url;
  link.click();
}
