import { gql } from "@apollo/client";

const QUERY_DONATE = gql`
query Donate {
  donate {
    data {
      attributes {
        title
        image {
          data {
            attributes {
              url
              formats
            }
          }
        }
      }
    }
  }
}`

export default QUERY_DONATE;
