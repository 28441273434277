// Dependencies
import { useState, useEffect, useRef, memo, useCallback } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";

import {
  MapContainer,
  LayersControl,
  TileLayer,
  useMap,
  ScaleControl,
  FeatureGroup,
  Marker,
  ZoomControl,
} from "react-leaflet";
import axios from "axios";

import _ from "lodash";
import Helmet from "react-helmet";
import LinearProgress from "@mui/material/LinearProgress";

// js pages
import PaneA from "./PaneA";
import PaneB from "./PaneB";
import LocationMarker from "./LocationMarker";
import SearchTools from "./SearchTools";
import DrawToolTip from "./DrawToolTip";
import useWindowDimensions from "../utils/useWindowDimensions";
import SelectActivityList from "./SelectActivityList";
import SelectCategoryList from "./SelectCategoryList";
import {
  CenterPoiBounds,
  CenterPoiBoundsButton,
  MultiPoiBounds,
  CenterSinglePoi,
  CenterPoiBoundsTrip,
  CenterRoute,
  ZoomPoi,
  MultiZoom,
} from "./CenterPois";
import RenderRouteMap from "./RenderRouteMap";
import RenderRouteTransitMap from "./RenderRouteTransitMap";
import { StyledFormControlLabel, FloatSwitch } from "../styles/StyledSwitch";
import Focus from "./Focus";

// assets
import home from "../assets/home.svg";
import search from "../assets/search.svg";
import loop from "../assets/autorenew.svg";
import countries from "../utils/countries.json";
import layout0 from "../assets/layout0.svg";
import layout1 from "../assets/layout1.svg";
import layout0hover from "../assets/layout0-hover.svg";
import layout1hover from "../assets/layout1-hover.svg";

// styling
import "leaflet/dist/leaflet.css";

const center = [55.18, 17.58];

function GetHomeLocation({ callBackSendCoords }) {
  const map = useMap();
  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      callBackSendCoords(e.latlng);
    });
  }, [map]);
  return null;
}

function ShowMap() {
  const getPaneWidth = () => {
    var paneWidth = 350;

    return paneWidth;
  };

  // init
  const data = 0;
  const mapRef = useRef();
  const navigate = useNavigate();
  let { slug } = useParams();
  const { width, height } = useWindowDimensions();
  const [fromRouteHome, setFromRouteHome] = useState("");
  const [coordsFromHome, setCoordsFromHome] = useState(null);
  const [widthMapWrapper, setWidthMapWrapper] = useState(80);
  const [poiNearby, setPoiNearby] = useState([]);
  const [cat, setCat] = useState(0);
  const [act, setAct] = useState(0);
  const [centrePosition, setCentrePosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paneA, setPaneA] = useState({ width: getPaneWidth(), display: "inherit" });
  const [routeResult, setRouteResult] = useState(null);
  const [routeTransitResult, setRouteTransitResult] = useState(null);
  const [myTrips, setMyTrips] = useState(null);
  const [getHome, setGetHome] = useState(false);
  const [coordsHome, setCoordsHome] = useState(null);
  const [request, setRequest] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [limit, setLimit] = useState(10);
  const [resetAct, setResetAct] = useState(false);
  const [resetCat, setResetCat] = useState(false);
  const [myTripsInfo, setMyTripsInfo] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [maps, setMap] = useState(null);
  const [noPopup, setNoPopup] = useState(false);
  const [countCat, setCountCat] = useState(null);
  const [paneBopen, setPaneBopen] = useState(false);
  const [paneMove, setPaneMove] = useState(false);
  const [dataForPaneB, setDataForPaneB] = useState({});
  const [routeFocus, setRouteFocus] = useState(false);
  const [floatMode, setFloatMode] = useState(false);
  const [closePopup, setClosePopup] = useState(0);
  const [layoutView, setLayoutView] = useState(0);
  const [singleZoom, setSingleZoom] = useState(null);
  const [validateGeoQuery, setValidateGeoQuery] = useState("");
  const [lineMarkers, setLineMarkers] = useState([]);
  const [countLineMarkers, setCountLineMarkers] = useState(0);
  const [resetLineMarkers, setResetLineMarkers] = useState([]);
  const [searchMode, setSearchMode] = useState("marker");
  const [resetPoints, setResetPoints] = useState(false);

  const [KMLInput, setKMLInput] = useState(null);

  useEffect(() => {}, [searchMode]);

  const getSearchMode = useCallback(
    (c) => {
      setSearchMode(c);
    },
    [searchMode]
  );

  useEffect(() => {
    if (paneMove && maps) {
      setTimeout(() => maps.invalidateSize({ animate: true, duration: 2, easeLinearity: 1 }), 600);
      if (maps.invalidateSize()._size.x === 1142) setPaneMove(false);
    }

    if (poiNearby.length !== 0) {
      setLoading(false);
    }

    if (getHome) {
      setLoading(true);
      movePaneB("close");
      setRouteResult(null);
    }

    if (localStorage.getItem("takemethere") !== null) {
      var item = JSON.parse(localStorage.getItem("takemethere"));
      setPoiNearby([item]);

      localStorage.removeItem("takemethere");
      setMyTrips(null);
    } else if (localStorage.getItem("geoResponse") !== null) {
      localStorage.removeItem("tripGroup");
      var item = JSON.parse(localStorage.getItem("geoResponse"));
      localStorage.removeItem("geoResponse");

      if (item.activity) {
        setAct(item.activity);
        setMyTripsInfo({
          group: item.description.group,
          description: item.description.description,
        });
      }

      setCoordsFromHome(item.latLng);
      setCentrePosition(item.latLng);
      setMyTrips(null);
      setFromRouteHome(item.geoString);
      setLoading(true);
    } else if (localStorage.getItem("tripGroup") !== null) {
      var val4 = JSON.parse(localStorage.getItem("tripGroup"));

      setMyTrips(val4.items);
      setMyTripsInfo({ group: val4.group, description: val4.description });
      localStorage.removeItem("tripGroup");
    }
  }, [poiNearby, widthMapWrapper, data, coordsHome, getHome, centrePosition, paneMove]);

  useEffect(() => {}, []);

  const getPoiNearby = useCallback(
    (c) => {
      setPoiNearby(c);
      if (c.length > 1) {
        setSingleZoom(null);
      }
    },
    [poiNearby]
  );

  const getLineMarkers = useCallback(
    (c) => {
      setLineMarkers(c);
    },
    [lineMarkers]
  );

  const getCountry = (bak, country) => {
    if (country !== null) {
      const newObj = _.filter(bak, { country: country });
      setPoiNearby(newObj);
    } else {
      setPoiNearby(bak);
    }
  };

  async function getGeoData2(evt) {
    evt.preventDefault();
    setValidateGeoQuery("");

    const arr = [];
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/geocode`, {
        params: {
          location: request,
        },
      })
      .then((result) => {
        if (result.data.result.length > 1) {
          result.data.result.map((idx, i) => {
            const neighboorhood = idx.adminArea6;
            const city = idx.adminArea5;
            const county = idx.adminArea4;
            const state = idx.adminArea3;
            const country = countries.countries[idx.adminArea1];
            const latLng = idx.latLng;

            arr.push(
              Object.assign(
                {},
                neighboorhood ? { neighboorhood } : null,
                city ? { city } : null,
                county ? { county } : null,
                state ? { state } : null,
                { country },
                { latLng }
              )
            );
          });

          setSearchResults(arr);
        } else if (result.data.result.length === 1) {
          if (result.data.result[0].status === "error") {
            setValidateGeoQuery("This is probably not a place on this planet");
          } else if (result.data.result[0].status === "emptystring") {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/randomlocation`)
              .then((result) => {})
              .catch((err) => {});
          } else {
            const { adminArea6, adminArea5, adminArea4, adminArea3, adminArea1, latLng } =
              result.data.result[0];
            const country = countries.countries[adminArea1];

            const neighboorhood = adminArea6;
            const city = adminArea5;
            const county = adminArea4;
            const state = adminArea3;

            getCoords(
              Object.assign(
                {},
                neighboorhood ? { neighboorhood } : null,
                city ? { city } : null,
                county ? { county } : null,
                state ? { state } : null,
                { country },
                { latLng }
              )
            );
          }
        } else {
          setValidateGeoQuery("Sorry, but currently we do not cover places outside Europe");
        }
      })
      .catch((err) => {
        console.error("error: ", err);
      });
    setLoading(true);
  }

  const getCoords = (geoLocation) => {
    const geoString =
      (geoLocation.city ? geoLocation.city + ", " : "") +
      (geoLocation.county ? geoLocation.county + ", " : "") +
      (geoLocation.state ? geoLocation.state + ", " : "") +
      geoLocation.country;

    localStorage.removeItem("geoResponse");
    setFromRouteHome(geoString);
    setSearchResults(false);
    setCoordsFromHome(geoLocation.latLng);
    setCentrePosition(geoLocation.latLng);
    setMyTrips(null);
    setCoordsHome(null);
  };

  const resetPane = () => {
    setMyTrips(null);
    setCoordsHome(null);
    routeResult !== null && setRouteResult(null);
    routeTransitResult !== null && setRouteTransitResult(null);
    setRouteFocus(false);
    setSingleZoom(null);
    setLimit(10);
    localStorage.removeItem("mytrips");
    localStorage.removeItem("tripGroup");
    localStorage.removeItem("takemethere");
    localStorage.removeItem("geoResponse");
    movePaneB("close");
    navigate(`/map`);
  };

  const handleFromInput = (evt) => {
    evt.preventDefault();
    setRequest(evt.target.value);
    setFromRouteHome(evt.target.value);
  };

  const getActivity = (tagid) => {
    if (tagid.id === 0) {
      setResetAct(true);
    }

    setResetAct(false);
    setAct(tagid.id);
    setCat(0);
    setResetCat(true);
  };

  const getCategory = (categoryid) => {
    if (categoryid === 0) {
      setResetCat(true);
    }

    setCat(categoryid);
    setAct(0);
    setResetAct(true);
  };

  const getLimit = () => {
    if (poiNearby.length > 0) {
      setFloatMode(false);
      setLimit(limit === 0 ? 20 : limit + 10);
      setClosePopup(dataForPaneB.travelid);
      setRouteResult(null);
    }
  };

  const handleFLoatMode = (event) => {
    setFloatMode(event.target.checked);
  };

  const getRouteResult = (res) => {
    setRouteResult(res);
    setClosePopup(dataForPaneB.travelid);
  };

  const getArticleData = (data) => {
    setPoiNearby(data);
    const newArray = data.map((i) => [i.lat, i.lng]);
  };

  const getSingleLocation = (data) => {
    setPoiNearby([data]);
    const obj = {
      travelid: data.id,
      route: [data.lat, data.lng],
      title: data.title,
      sfInfo: data.silent_factor_json,
      mode: "details",
    };

    setDataForPaneB(obj);
    setRouteResult(null);
    setPaneBopen(true);
    movePaneB("open");
  };

  const getDetails = (idx) => {
    if (idx) {
      const obj = {
        travelid: idx.id,
        route: [idx.lat, idx.lng],
        sfInfo: idx.silent_factor_json,
        title: idx.title,
        mode: "details",
      };
      setDataForPaneB(obj);
      movePaneB("open");
    }
  };

  const movePaneB = (direction) => {
    if (direction === "open") {
      setPaneMove(true);
      setPaneBopen(true);
      setWidthMapWrapper(60);
    } else {
      setPaneMove(true);
      setPaneBopen(false);
      setWidthMapWrapper(80);
    }
  };

  const changeView = (view) => {
    setLayoutView(view);
    setTimeout(() => maps.invalidateSize({ animate: true, duration: 2, easeLinearity: 1 }), 600);
  };

  const paneBStyle = () => {
    if (paneBopen) {
      if (layoutView === 0) {
        return {
          height: "100%",
          left: "350px",
          width: "350px",
        };
      } else if (layoutView === 1) {
        return { height: "60%", left: "350px" };
      }
    } else if (!paneBopen) {
      if (layoutView === 0) {
        return { height: "100%", left: "0%", width: "0%" };
      } else if (layoutView === 1) {
        return { height: "0%", left: "20%", width: "0%" };
      }
    }
  };

  const mapStyle = () => {
    if (paneBopen && layoutView === 0) {
      return { height: "100%", width: "calc(100% - 700px)" };
    } else {
      return { height: "100%", width: "calc(100% - 350px)" };
    }
  };

  const totalReset = () => {
    setPoiNearby([]);
    movePaneB("close");
    setLimit(0);
  };

  return (
    <>
          <Helmet>
          <title>MAP - Silence Spotter</title>
          <meta name="description" content="Advanced search tool for tourist attractions in Europe" />
        </Helmet>
      <div className="wrapper-special">
        <div className={"route-input"}>
          <div className={"from-container"}>
            <form id="from-form" onSubmit={getGeoData2}>
              <div
                onClick={() => setGetHome(true)}
                alt="get current location"
                title="get current location"
                className={"from-container-homebtn"}>
                <img src={home} id="center" alt="" />
              </div>
              <div className={"from-container-icon"}>
                {maps ? (
                  <CenterPoiBoundsButton map={maps} centerpos={centrePosition} poi={poiNearby} />
                ) : null}
              </div>
              <input
                id="datafield-from"
                type="text"
                placeholder={"Any destination (in Europe...)"}
                name="request"
                title={fromRouteHome}
                value={fromRouteHome}
                onChange={(e) => handleFromInput(e)}
              />
              {searchResults && (
                <div className={"sugglist sugglist-map"}>
                  {searchResults.map((idx, i) => {
                    return (
                      <div className={"sugglist-items"} key={i} onClick={() => getCoords(idx)}>
                        {idx.neighboorhood}
                        {idx.neighboorhood && ", "}
                        {idx.city}
                        {idx.city && ", "}
                        {idx.county}
                        {idx.county && ", "}
                        {idx.state}
                        {idx.state && ", "}
                        {idx.country}{" "}
                      </div>
                    );
                  })}
                </div>
              )}
              {validateGeoQuery !== "" && (
                <div className="sugglist sugglist-map errormsg">{validateGeoQuery}</div>
              )}
              <input
                id={"searchsubmit"}
                type="image"
                src={search}
                value=""
                alt="search"
                width={20}
                height={20}
              />
            </form>
          </div>
          <div className="filters-activity">
            <SelectActivityList
              act={act}
              reset={resetAct}
              resetBack={() => setResetAct(false)}
              sendObjToParent={(c) => getActivity(c)}
            />
          </div>
          <div className="filters-category">
            <SelectCategoryList
              reset={resetCat}
              countcategories={countCat}
              resetBack={() => setResetCat(false)}
              parentCallback={(childData) => getCategory(childData)}
            />
          </div>
          <div className={"tools-loadmore"}>
            <button
              className={"tstbtn loadmore radiusleft " + (poiNearby ? "enabled" : "disabled")}
              style={{ backgroundColor: poiNearby.length > 0 ? "#059ed7" : "#eee" }}
              title={poiNearby ? "load 10 more" : "inactive until you make your choice in the map"}
              onClick={() => getLimit()}>
              <p>load more</p>
            </button>
            <button
              className={"tstbtn reset-btn radiusright " + (poiNearby ? "enabled" : "disabled")}
              style={{ backgroundColor: poiNearby.length > 0 ? "#059ed7" : "#eee" }}
              title="reset"
              onClick={() => totalReset()}>
              <img src={loop} alt="" width={20} height={20} />
            </button>
          </div>

          <div className="tools-layout">
            <img
              onClick={() => changeView(0)}
              title="vertical split layout"
              alt="vertical split layout"
              style={{ cursor: "pointer", marginRight: "2px" }}
              src={layoutView === 0 ? layout0hover : layout0}
              width={32}
              height={32}
            />
            <img
              onClick={() => changeView(1)}
              title="horizontal split layout"
              alt="horizontal split layout"
              style={{ cursor: "pointer" }}
              src={layoutView === 1 ? layout1hover : layout1}
              width={32}
              height={32}
            />
          </div>
          {loading && (
            <div className="linear-loader">
              <LinearProgress />
            </div>
          )}
        </div>
        <div style={{ height: height - 126 }} className={"container-wrapper"}>
          <div
            className={"pane-wrapper paneA"}
            style={{ display: paneA.display, width: paneA.width + "px" }}>
            <div className={"pane-header"}>
              <div className={"pane-header-title"}>
                <span>{poiNearby.length}</span> {`LOCATION${poiNearby.length === 1 ? "" : "S"}`}
              </div>
            </div>

            <div className={"pane-content"}>
              <PaneA
                itemData={poiNearby && poiNearby}
                getActivity={dataForPaneB.travelid}
                tripInfo={myTripsInfo}
                limit={limit}
                width={width}
                act={act}
                centrePosition={centrePosition}
                sendCatToParent={(cat) => setCat(cat)}
                sendActToParent={(act) => setAct(act)}
                sendCountryToParent={(bak, country) => getCountry(bak, country)}
                sendLimitToParent={(limit) => setLimit(limit)}
                sendLinkIdToParent={() => movePaneB("open")}
                sendSingleLocationToParent={getSingleLocation}
                sendDataToPaneB={(c) => setDataForPaneB(c)}
                countCat={(c) => setCountCat(c)}
                closePopupCB={(c) => setNoPopup(c)}
                closeRoute={() => setRouteResult(null)}
                singleZoom={(c) => setSingleZoom(c)}
                sendArticleToParent={(c) => getArticleData(c)}
              />
            </div>
          </div>

          <div
            className={layoutView === 0 ? "pane-wrapper paneB" : "pane-wrapper paneBmax"}
            style={paneBStyle()}>
            {dataForPaneB && (
              <PaneB
                centrePos={centrePosition}
                centreGeostring={fromRouteHome}
                dataForPaneB={dataForPaneB}
                routeCB={(c) => getRouteResult(c)}
                routeTransitCB={(c) => setRouteTransitResult(c)}
                routeFocus={(c) => setRouteFocus(c)}
              />
            )}
          </div>
          <div className={layoutView === 0 ? "map-wrapper" : "map-wrapper-max"} style={mapStyle()}>
            {errorMsg && (
              <div className="maploader">
                <div className="error-container">
                  <div className="error-container-close" onClick={() => setErrorMsg(false)}>
                    X
                  </div>
                  <p>{errorMsg}</p>
                </div>
              </div>
            )}
            <div className={loading ? "freeze-overlap" : ""}></div>
            <MapContainer
              doubleClickZoom={false}
              ref={setMap}
              style={{ height: "100%" }}
              whenCreated={(mapInstance) => {
                mapRef.current = mapInstance;
              }}
              center={center}
              zoom={width >= 1120 ? 4 : 3}
              scrollWheelZoom={true}
              zoomControl={false}
              tap={false}>
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Streets">
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mark78/clb0xvbhr002j15t0mdh9w96r/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFyazc4IiwiYSI6ImNtM3lyYnV4bjFscjkybXNjOGU3aDk1ancifQ.BTSazRZr5ACYk9nYKcHdKw"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite">
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mark78/cl1j8oyim002z14qbxemsa2hk/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFyazc4IiwiYSI6ImNtM3lyYnV4bjFscjkybXNjOGU3aDk1ancifQ.BTSazRZr5ACYk9nYKcHdKw"
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
              <ScaleControl position="bottomright" imperial={false} />
              <ZoomControl position="bottomright" />
              <LocationMarker
                pois={poiNearby}
                coordsHome={coordsHome}
                selectedMarker={dataForPaneB && dataForPaneB.travelid}
                coordsFromHomePage={coordsFromHome}
                coordsFromMytrips={myTrips}
                category={cat}
                activity={act}
                limit={limit}
                noPopup={noPopup}
                closePopup={closePopup}
                floatMode={floatMode}
                searchMode={searchMode}
                resetPoints={resetPoints}
                resetLineMarkers={resetLineMarkers}
                KMLInput={KMLInput}
                parentCallback={(c) => getPoiNearby(c)}
                sendDetailsToParent={(c) => getDetails(c)}
                sendPositionToParent={(c) => setCentrePosition(c)}
                sendFromToParent={(c) => setFromRouteHome(c)}
                sendHomeStateToParent={(c) => setGetHome(false)}
                sendLimitToParent={(limit) => setLimit(limit)}
                sendLinkIdToParent={(c) => movePaneB("open")}
                sendLineMarkersToParent={(c) => getLineMarkers(c)}
                resetSingleZoom={() => setSingleZoom(null)}
                loading={(c) => setLoading(c)}
                reset={() => resetPane()}
                closePopupCB={(c) => setNoPopup(false)}
                countLineMarkers={(c) => setCountLineMarkers(c)}
                errorMsg={(c) => setErrorMsg(c)}
                openPane={(c) => getDetails(c)}
              />
              {routeResult ? (
                <RenderRouteMap centreposition={centrePosition} routeresult={routeResult} />
              ) : null}
              {routeTransitResult ? (
                <RenderRouteTransitMap
                  centreposition={centrePosition}
                  routeresult={routeTransitResult}
                />
              ) : null}
              {getHome && <GetHomeLocation callBackSendCoords={(c) => setCoordsHome(c)} />}
              {myTrips && poiNearby ? <CenterPoiBoundsTrip map={maps} poi={poiNearby} /> : null}
            </MapContainer>
            <SearchTools
              searchmode={(c) => getSearchMode(c)}
              resetPoints={(c) => setResetPoints(c)}
            />
            <div>
              <DrawToolTip
                drawMode={searchMode}
                markers={countLineMarkers}
                errorMsg={errorMsg}
                result={poiNearby.length}
                kmlFileInput={(c) => setKMLInput(c)}
              />
            </div>

            {poiNearby !== null && (
              <StyledFormControlLabel
                control={
                  <FloatSwitch
                    checked={floatMode}
                    onChange={handleFLoatMode}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="FLOAT CENTRE"
              />
            )}
            {
              <Focus
                map={maps && maps}
                data={poiNearby}
                center={centrePosition}
                singleZoom={singleZoom}
              />
            }
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(ShowMap);
