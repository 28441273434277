// icons
import iconAmusementParks from "../../assets/m_amusementparks.png";
import iconArchSites from "../../assets/m_archaeology.png";
import iconBathhouses from "../../assets/m_bathhouses.png";
import iconBeaches from "../../assets/m_beaches.png";
import iconBuddTem from "../../assets/m_buddhisttemples.png";
import iconBurialPlaces from "../../assets/m_burialplaces.png";
import iconCastles from "../../assets/m_castles.png";
import iconCanyon from "../../assets/m_canyon.png";
import iconCathedrals from "../../assets/m_cathedrals.png";
import iconCaves from "../../assets/m_caves.png";
import iconChurches from "../../assets/m_churches.png";
import iconGlaciers from "../../assets/m_glaciers.png";
import iconIndfac from "../../assets/m_industrialfacilities.png";
import iconLighthouses from "../../assets/m_lighthouses.png";
import iconMonasteries from "../../assets/m_buddhisttemples.png";
import iconMosques from "../../assets/m_mosques.png";
import iconMountains from "../../assets/m_mountains.png";
import iconMuseums from "../../assets/m_museums.png";
import iconNationalParks from "../../assets/m_nationalparks.png";
import iconNaturalSprings from "../../assets/m_naturalsprings.png";
import iconRockFormations from "../../assets/m_rockformations.png";
import iconTowers from "../../assets/m_towers.png";
import iconViewPoints from "../../assets/m_viewpoints.png";
import iconVolcanoes from "../../assets/m_volcanoes.png";
import iconWaterfalls from "../../assets/m_waterfalls.png";
import iconWinterSports from "../../assets/m_wintersports.png";
import iconZoos from "../../assets/m_zoos.png";
import iconTouristicObjects from "../../assets/m_touristicobjects.png";

export default function getIcon(icon) {
  var categoryIcon;

  switch (icon) {
    case "amusement_parks":
      categoryIcon = iconAmusementParks;
      break;
    case "archaeology":
      categoryIcon = iconArchSites;
      break;
    case "beaches":
      categoryIcon = iconBeaches;
      break;
    case "other_bathhouses":
      categoryIcon = iconBathhouses;
      break;
    case "buddhist temple":
      categoryIcon = iconBuddTem;
      break;
    case "burial_places":
      categoryIcon = iconBurialPlaces;
      break;
    case "canyons":
      categoryIcon = iconCanyon;
      break;
    case "castles":
      categoryIcon = iconCastles;
      break;
    case "cathedrals":
      categoryIcon = iconCathedrals;
      break;
    case "caves":
      categoryIcon = iconCaves;
      break;
    case "churches":
      categoryIcon = iconChurches;
      break;
    case "glaciers":
      categoryIcon = iconGlaciers;
      break;
    case "industrial_facilities":
      categoryIcon = iconIndfac;
      break;
    case "lighthouses":
      categoryIcon = iconLighthouses;
      break;
    case "mosques":
      categoryIcon = iconMosques;
      break;
    case "monasteries":
      categoryIcon = iconMonasteries;
      break;
    case "mountain_peaks":
      categoryIcon = iconMountains;
      break;
    case "museums":
      categoryIcon = iconMuseums;
      break;
    case "national_parks":
      categoryIcon = iconNationalParks;
      break;
    case "natural_springs":
      categoryIcon = iconNaturalSprings;
      break;
    case "gardens_and_parks":
      categoryIcon = iconNationalParks;
      break;
    case "nature_reserves":
      categoryIcon = iconNationalParks;
      break;
    case "rock_formations":
      categoryIcon = iconRockFormations;
      break;
    case "synagogues":
      categoryIcon = iconMosques;
      break;
    case "towers":
      categoryIcon = iconTowers;
      break;
    case "touristic_objects":
      categoryIcon = iconTouristicObjects;
      break;
    case "view_points":
      categoryIcon = iconViewPoints;
      break;
    case "volcanoes":
      categoryIcon = iconVolcanoes;
      break;
    case "waterfalls":
      categoryIcon = iconWaterfalls;
      break;
    case "winter_sports":
      categoryIcon = iconWinterSports;
      break;
    case "zoos":
      categoryIcon = iconZoos;
      break;
    default:
      categoryIcon = iconTouristicObjects;
  }

  return categoryIcon;
}
