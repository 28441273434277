// Dependencies
import { useEffect, useRef, useState } from "react";
import { useCurrentUser, useDispatchCurrentUser } from "./CurrentUser";
import { useNavigate, Link } from "react-router-dom";
import SEO from "../component/SEO";
import CircularProgress from "@mui/material/CircularProgress";

export default function Login() {
  const dispatch = useDispatchCurrentUser();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordRef = useRef();
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      navigate("/user");
    }
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const body = {
      identifier: emailRef.current.value,
      password: passwordRef.current.value,
    };

    try {
      const response = await fetch(`/api/auth/local`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      const data = await response.json();

      if (!data.user) {
        throw data;
      }
      setLoading(false);

console.log("user data: ", data);

      dispatch({ type: "LOGIN", user: data.user });
      navigate("/user");
    } catch (err) {
      console.log("err: ", err.message);
      setLoading(false);
      setErrorMsg(err.message);
    }
  };

  return (
    <>
    <SEO title={'Login'}/>
      <div className="wrapper login-wrapper">
        {loading && (
          <div className="maploader">
            <div className={"maploader-inner"}>
              <CircularProgress style={{ color: "white" }} />
            </div>
          </div>
        )}
        <div className="auth-wrapper login">
          <form onSubmit={handleSubmit}>
            <fieldset id="sign_up">
              <legend>
                <h4 style={{ display: "inline-block" }}>
                  <strong>LOGIN</strong>&nbsp;or
                </h4>
                <Link to="/register">
                  <h5 style={{ display: "inline-block", paddingLeft: "5px" }}>register</h5>
                </Link>
              </legend>
              <div style={{ marginBottom: "14px" }}>
                <input
                  ref={emailRef}
                  type="email"
                  name="email-address"
                  placeholder="Email"
                  autoComplete="on"
                  id="email-address"
                  required
                />
              </div>
              <div style={{ marginBottom: "14px" }}>
                <input
                  ref={passwordRef}
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="on"
                  id="password"
                  required
                />
              </div>
            </fieldset>
            {errorMsg !== null && <p style={{ color: "red" }}>{errorMsg}</p>}
            <div style={{ marginBottom: "14px" }}>
              <input id={"loginsubmit"} type="submit" value="Sign in" />
            </div>
            <div>
              <span style={{ float: "right" }}>
                <Link to="/forgotpassword">Forgot your password?</Link>
              </span>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
