import React, { useEffect, useState } from "react";

import ApexChartDemo from "./ApexChartDemo";

export default function PaneInfoTab({dataForPaneB}) {

 // console.log("dataForPaneB: ", dataForPaneB);

  return (
    <div>
      this is information about the location
      <ApexChartDemo data={dataForPaneB}/>
    </div>
  );
}
