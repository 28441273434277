import { memo } from 'react'
import axios from "axios";

export function getPoiNearby(center, category, activity, limit) {

  if (Array.isArray(center) === true) {
    var lat = center[0];
    var lng = center[1];
  } else {
    lat = center.lat;
    lng = center.lng;
  }

  const getRes = axios.get(`${process.env.REACT_APP_API_URL}/api/maps`, {
    params: {
      lat: lat,
      lng: lng,
      category: category,
      act: activity,
      limit: limit === 0 ? 10 : limit,
    },
  });

  return getRes;
}


