import React from "react";
import { Polyline } from "react-leaflet";

export default function RenderRouteMap({ centreposition, routeresult }) {
  const { polyline } = routeresult.polyline;

  return (
    <div>
      {
        <Polyline
          dashArray={"4, 4"}
          dashOffset={"4"}
          positions={[
            [centreposition.lat, centreposition.lng],
            [polyline[0][0], polyline[0][1]],
          ]}
        />
      }
      {<Polyline weight={4} color={"#059ed7"} positions={polyline} />}
      {
        <Polyline
          dashArray={"4, 4"}
          dashOffset={"4"}
          positions={[
            polyline[polyline.length - 1],
           
            [routeresult.arrival.lat, routeresult.arrival.lng],
          ]}
        />
      }
    </div>
  );
}
