import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from "@apollo/client";
import client from "./utils/apolloClient";
import { CurrentUserProvider } from "./component/CurrentUser.js";
import './index.css';
//import './responsive.css';
import './responsiveheader.css';
import './responsiveheader.css';
import './responsive990-1299.css';
import './responsive500-989.css';
import './responsive365-499.css';
import './responsive-364.css';
import './responsive-700.css';
import App from './component/App';

//import reportWebVitals from './reportWebVitals';

ReactDOM.render(
   <CurrentUserProvider>
  <Router>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </Router>
  </CurrentUserProvider>,
  document.getElementById('root')
);


//reportWebVitals();
