// Dependencies
import { useQuery } from "@apollo/react-hooks";

// js pages
import Footer from "./Footer";
import Helmet from "react-helmet";

// assets
import personGreenIcon from "../assets/person_green.svg";
import personOrangeIcon from "../assets/person_orange.svg";
import personRedIcon from "../assets/person_red.svg";
import favorite from "../assets/favorite.svg";

// queries
import QUERY_HOWITWORKS from "../queries/QUERY_HOWITWORKS";

const Query = ({ children, query }) => {
  const { data, loading, error } = useQuery(query);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({ data });
};

export default function HowItWorks() {
  return (
    <Query query={QUERY_HOWITWORKS}>
      {({ data: { howitwork } }) => {
        const { title, image } = howitwork.data.attributes;

        return (
          <>
             <Helmet>
          <title>How it Works - Silence Spotter</title>         
        <meta name="description" content="Getting started with Silence Spotter" /> 
        </Helmet>
            <div className={"wrapper-container"}>
              <div id="single-article" className={"wrapper"}>
                <div
                  className="wrapper-img"
                  style={{
                    background: `#fff url(${image.data.attributes.url}) no-repeat center`,
                    backgroundSize: "cover",
                  }}></div>
                <div className="page-container">
                  <div className="page-content vsmall">
                    <div className="item-title">
                      <h1>{title}</h1>
                    </div>
                    <div className={"item-wrapper"}>
                      <blockquote>
                        “Two roads diverged in a wood, and I – I took the one less traveled by, and
                        that has made all the difference.” -{" "}
                        <span>
                          <em> from 'The Road Not Taken' by Robert Frost</em>
                        </span>
                      </blockquote>

                      <h2>Why should I use Silence Spotter?</h2>
                      <p>
                        Do you know what happens to places like Dubrovnik in Croatia after it was
                        featured as a set for the popular TV series <em>Game of Thrones?</em> Or the
                        Austrian village of Hallstatt after the Chinese built a duplicate of it?
                      </p>
                      <p>
                        Tour operators often recommend them as dream destinations, which is quite
                        correct – visiting them in the extremely overcrowded and overpriced summer
                        period would be more of a nightmare for most of us!
                      </p>
                      <p>
                        We can truthfully say that mass tourism produces an adverse effect on
                        authenticity, and often results in a poor and disappointing travel
                        experience.{" "}
                      </p>
                      <p>
                        <strong>
                          That is why we want to encourage you and give you some sound advice on
                          finding fabulous holiday destinations, preferably that give you the
                          feeling that no other tourist has ever been there before you.
                        </strong>
                      </p>
                      <p>Here are a few examples: </p>
                      <p>
                        You can queue up for an hour or more to enter Saint Peter’s Cathedral in the
                        Vatican City, or follow our advice and visit a lovely church just outside
                        the centre of Rome to admire some beautiful frescoes in complete serenity,
                        with no one around except a few nuns engrossed in silent prayer. Perhaps the
                        beautiful ceilings were not painted by Michelangelo, but does that really
                        matter?{" "}
                      </p>
                      <p>
                        If you like mountaineering you can try to reach the top of Mont Blanc, just
                        because it’s a famous mountain, and obediently follow the masses
                        endeavouring to climb this thing every day. Instead, you can find a
                        neighbouring mountain in the Alps that almost no one knows about and be
                        stunned by magical views and no living soul around. You will return home
                        with a story no one else has ever told! OK, it may not be the highest, but
                        is that really so important?
                      </p>
                      <p>
                        Visiting a tourist attraction that you learned about on television, from a
                        book or on the internet just to see it in real life should not be your only
                        incentive for travelling. Travelling is, above all, a wonderful opportunity
                        to create your very own, unique story, and to feel like a modern-day
                        explorer. And the less popular and crowded a location, the more likely
                        you’ll experience it like that.{" "}
                      </p>
                      <p>
                        Apart from helping you avoid masses of tourists, we also want to help you
                        deal with decision anxiety. An overload of things to do or see at your
                        chosen destination often results in choices you may regret later. That is
                        why Silence Spotter presents you with the 10 most interesting options in
                        the direct vicinity of your chosen location, which can be expanded or
                        narrowed down based on your interests.{" "}
                      </p>
                      <h2>How to get the most out of our app</h2>
                      <h3>DISCOVER LOCATIONS</h3>
                      <ul>
                        <li>
                          <h4>The destination field (front page and map)</h4>
                          <p>
                            Enter your desired destination in this field. When the result of your
                            query presents you with multiple options, choose the one that appeals to
                            you most – and off you go!
                          </p>
                        </li>
                        <li>
                          <h4>The map (map)</h4>
                          <p>
                            If you have a good eye for geography, you might want to find the nearest
                            locations by simply clicking on the map.
                          </p>
                        </li>
                        <li>
                          <h4>The location field (map)</h4>
                          <p>
                            If you already know the name of the location you want to visit, just
                            type it and press enter. If you don’t, the autocomplete feature will
                            assist you in finding the right name.
                          </p>
                        </li>
                        <li>
                          <h4>The Home button (map)</h4>
                          <p>
                            If you have arrived somewhere and don’t know the exact name of the place
                            or geographic location, press the Home button. After a confirm from your
                            browser, it will give you the locations near you. (This will not work if
                            you are using a VPN.)
                          </p>
                        </li>
                      </ul>
                      <h3 id="silentrating">HOW TO USE THE SILENCE RATING</h3>
                      <p>
                        Every location presented on Silence Spotter has a 'Silence rating' that
                        ranges from 0.5 (not a living soul around) to 5 (extremely crowded). It is a
                        rough, overall indication of how quiet or crowded a place is expected to be.
                        The factor is derived from the total number of pictures taken at a
                        particular geographic location within a certain radius and subsequently
                        uploaded to the largest photo database in the world.
                      </p>
                      <p>
                        <strong>
                          The basic premise is: the quieter the location, the more interesting – its
                          beauty may not even have been discovered yet!{" "}
                        </strong>
                      </p>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <img src={personGreenIcon} alt="" />
                            </td>
                            <td>I was / we were alone here</td>
                          </tr>
                          <tr>
                            <td>
                              <img src={personOrangeIcon} alt="" />
                              <img src={personOrangeIcon} alt="" />
                            </td>
                            <td>a few people in the vicinity</td>
                          </tr>
                          <tr>
                            <td>
                              <img src={personOrangeIcon} alt="" />
                              <img src={personOrangeIcon} alt="" />
                              <img src={personOrangeIcon} alt="" />
                            </td>
                            <td>A few more people</td>
                          </tr>
                          <tr>
                            <td>
                              <img src={personRedIcon} alt="" />
                              <img src={personRedIcon} alt="" />
                              <img src={personRedIcon} alt="" />
                              <img src={personRedIcon} alt="" />
                            </td>
                            <td>A bit crowded</td>
                          </tr>
                          <tr>
                            <td>
                              <img src={personRedIcon} alt="" />
                              <img src={personRedIcon} alt="" />
                              <img src={personRedIcon} alt="" />
                              <img src={personRedIcon} alt="" />
                              <img src={personRedIcon} alt="" />
                            </td>
                            <td>Extremely crowded</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>&nbsp;</p>
                      <p>
                        The rating is updated frequently for every location to gauge its current
                        popularity and represents an average of the situation you can expect all
                        year round. Please bear in mind that spots with the highest rating can be
                        less crowded in off-peak seasons (and vice-versa).{" "}
                      </p>
                      <p>
                        You can be assured that all data used to determine the silence rating has
                        been gathered pursuant to the General Data Protection Regulation (GDPR). We
                        do not use methods such as collecting meta data from Wi-Fi access points or
                        other controversial crowd data calculations.
                      </p>

                      <h3>PLAN YOUR TRIPS</h3>

                      <h4>Travel Planner (only for registered users)</h4>
                      <p>
                        {" "}
                        Keep track of your leisure or business activities on your personal page.{" "}
                      </p>
                      <p>
                        {" "}
                        The first step in all of this is to create a trip. Each trip can be filled
                        with events. A location selected on the map using the{" "}
                        <span>
                          <img src={favorite} alt="" width="20" height="20" />
                        </span>{" "}
                        icon, can be paired to an event. You can create as many events in as many
                        trips as you like
                      </p>
                      <p>
                        On your user page, a created trip is automatically sorted into one if these
                        groups:
                      </p>
                      <ul>
                        <li>
                          <h4>Active trip</h4>
                          <p>A trip has a start date in the past and an end date in the future. </p>
                        </li>
                        <li>
                          <h4>Future trips</h4>
                          <p>
                            A trip with a start and end date in the future is considered a bucket
                            list.
                          </p>
                        </li>
                        <li>
                          <h4>Past trips</h4>
                          <p>
                            A trip has a start and end date in the past, or no dates at all if it is
                            a list or an activity you are planning to undertake. If you for instance
                            want to keep track of all the mountains you’ve climbed so far and you
                            plan to add more in the future, you can create a trip without a date.{" "}
                          </p>
                        </li>
                      </ul>
                      <h3>MAKE MEMORIES</h3>
                      <h4>Memories (only for registered users)</h4>
                      <p>
                        After you have visited a location, we invite you to jot down your
                        experiences, for yourself as a digital memento for future reference, or to
                        share your experience with other travellers. Some travel websites call these
                        reviews, but we call them memories: what’s important is your personal
                        experience of a location rather than the location itself, after all!
                      </p>
                      <p>Memories can be created for events in active trips and past trips.</p>
                      <h2>What's under the hood?</h2>
                      <p>
                        We want to be transparent about the technology we use. The app brings
                        several open-source data models together to form our own creative
                        environment, in which we present you with reliable information. Objective
                        data, such as texts and images about locations, is extracted from
                        Opentripmap, Openstreetmap and Wikipedia. Routes are rendered by Open Route
                        Service and (reverse) geocoding – conversion from addresses to geographic
                        coordinates and vice versa – is produced real-time by MapQuest. We use the
                        Flickr API to gather data to generate the silence rating and Google Translate
                        API to translate every description into English.
                      </p>
                      <blockquote>
                        'Our system currently contains almost 50,000 locations in Europe and this
                        number is growing every day!'
                      </blockquote>
                      <p>
                        We don’t use nasty algorithms that give you misleading data. In other words:
                        when people all across the world click on the same spot in the map, they
                        will all receive the exact same information.{" "}
                      </p>
                      <h2>The future of Silence Spotter</h2>
                      <p>
                        It takes time to build a database and review data, so the scope of the
                        locations presented is limited to countries in Europe. When we are past the
                        beta test phase, other continents will follow soon!{" "}
                      </p>
                      <p>
                        More features are often added to the planner and the user page, making user
                        experience faster and more intuitive. To sum up some features that will be
                        added in the near future:
                      </p>
                      <ul>
                        <li>Generate routes for public transport</li>
                        <li>Use machine learning techniques for a more reliable silence rating</li>
                        <li>Filter your search using tags</li>
                        <li>
                          Add lines (e.g. tourist train rides, famous hiking trails, ski slopes) and
                          regions (e.g. parks, nature reserves)
                        </li>
                      </ul>
                      <p>
                        As this app is still in its beta phase, there may still be some
                        imperfections. Don’t hesitate to email us (
                        <span style={{ textDecoration: "underline" }}>info@markovmedia.nl</span>) if
                        you:
                      </p>
                      <ul>
                        <li>are missing any locations that we definitely should add;</li>
                        <li>would like to see some features that you’re missing right now;</li>
                        <li>have discovered a technical or content error.</li>
                      </ul>
                      <p>
                        <strong>Your help is highly appreciated!</strong>
                      </p>
                      <p>
                        A lot of effort is put into Silence Spotter each and every day to make this
                        the best platform for your travel needs. We are ambitious enough to say that
                        we will be among the world’s leading travel websites one day!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-wrapper">
                <Footer />
              </div>
            </div>
          </>
        );
      }}
    </Query>
  );
}
