import { useEffect, useCallback, memo } from "react";

function Focus({ map, data, center, singleZoom }) {
  useEffect(() => {
    if (map !== null && singleZoom) {
      onFlyBoundsNoCenter();
    }

    if (map !== null && center && data.length > 0 && !singleZoom) {
      onFitBoundsCenter();
    } else if (map !== null && !center && data.length > 0 && !singleZoom) {
      onFitBoundsNoCenter()
    }
  }, [center, data, singleZoom]);

  function onFlyBoundsNoCenter() {
    map.flyTo(singleZoom, 15);
    return null;
  }

  function onFitBoundsNoCenter() {
    map.fitBounds(data);
    return null;
  }


  function onFitBoundsCenter() {
    var highestLat = 0;
    var highestLng = 0;

    data.forEach((e) => {
      if (highestLat < Math.abs(center.lat - e.lat)) {
        highestLat = Math.abs(center.lat - e.lat);
      }
      if (highestLng < Math.abs(center.lng - e.lng)) {
        highestLng = Math.abs(center.lng - e.lng);
      }
    });

    var aLat = center.lat + highestLat;
    var bLat = center.lat + highestLat;
    var cLat = center.lat - highestLat;
    var dLat = center.lat - highestLat;
    var bLng = center.lng + highestLng;
    var cLng = center.lng + highestLng;
    var aLng = center.lng - highestLng;
    var dLng = center.lng - highestLng;

    const bounds = [
      [aLat, aLng],
      [bLat, bLng],
      [cLat, cLng],
      [dLat, dLng],
    ];

    map.fitBounds(bounds);
    return null;
  }

  const onMove = useCallback(() => {
    map.getCenter();
  }, [map]);

  useEffect(() => {
    if (map) {
      map.on("move", onMove);
      return () => {
        map.off("move", onMove);
      };
    }
  }, [map, onMove]);

  return null;
}

export default memo(Focus);
