// Dependencies
import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";

// Material ui
import { useCurrentUser } from "../component/CurrentUser";
import EditTripModal from "../modals/EditTripModal";
import EditEventModal from "../modals/EditEventModal";
import CreateMemoryModal from "../modals/CreateMemoryModal";
import EditMemoryModal from "../modals/EditMemoryModal";

// icons
import markerIcon from "../assets/marker.svg";
import markerHoverIcon from "../assets/marker_hover.svg";
import editIcon from "../assets/settings.svg";
import editHoverIcon from "../assets/settings_hover.svg";
import deleteIcon from "../assets/delete.svg";
import deleteHoverIcon from "../assets/delete_hover.svg";
import dropDownIcon from "../assets/arrow_drop_down.svg";
import dropDownHoverIcon from "../assets/arrow_drop_down_hover.svg";
import dropUpIcon from "../assets/arrow_drop_up.svg";
import dropUpHoverIcon from "../assets/arrow_drop_up_hover.svg";


/*const Person = () => (
	<Icon style={{height:'initial', width:'initial'}}>
	<img alt='' src={person} height={30} width={15}/>
	</Icon>
	)

const PersonEmpty = () => (
	<Icon style={{height:0, width:0}}>
	<img alt='' src={personEmpty} height={30} width={15}/>
	</Icon>
	)*/

export default function ActiveTrips({ data, refetchData }) {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const [activeEvent, setActiveEvent] = useState(null);
  const [openActiveTrip, setOpenActiveTrip] = useState(true);
  const [activeEditMemory, setActiveEditMemory] = useState(null);
  const [openEditActiveTrip, setOpenEditActiveTrip] = useState(false);
  const [activeMemory, setActiveMemory] = useState(null);

  const popDownActiveTrip = () => {
    if (openActiveTrip !== true) {
      setOpenActiveTrip(true);
    } else {
      setOpenActiveTrip(false);
    }
  };

  //console.log("data activeTrips: ", data)

  const getDate = (date) => {
    if (date !== null) {
      const d = new Date(date);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const displayDate = d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
      return displayDate;
    } else return null;
  };

  const showTripOnMap = (group, description, data) => {
    //console.log("tripid to show on map: ", tripid)
    console.log("showonmap group: ", group);
    console.log("showonmap group: ", description);
    console.log("showonmap group: ", data);

    var idArr = [];
    data.forEach((idx) => {
      idArr.push(idx.tripdatum.id);
    });

    console.log("idArr: ", idArr);

    const sendObj = { group: group, description: description, items: idArr };
    localStorage.setItem("tripGroup", JSON.stringify(sendObj));
    localStorage.removeItem("itemGeoData");
    localStorage.removeItem("geoResponse");
    navigate({ pathname: "/planner" });
  };

  const deleteTrip = async (tripid) => {
    console.log("tripid to delete: ", tripid);

    console.log("idx: ", tripid);
    const deleteRes = await axios.delete(`/api/trip/deletetrip/${tripid}`);
    console.log("deleteRes: ", deleteRes);
    if (deleteRes.status === 200) {
      console.log("trip deleted");
      refetchData();
    }
  };

  const deleteEvent = async (eventid) => {
    console.log("eventId to delete: ", eventid);
    //console.log("idx: ", tripid)
    const deleteRes = await axios.delete(`/api/myevents/deleteevent/${eventid}`);
    console.log("deleteRes: ", deleteRes);
    if (deleteRes.status === 200) {
      console.log("trip deleted");
      refetchData();
    }
  };

  return (
    <div>
      <div className="db-container-header">
        <h3>ACTIVE TRIP</h3>{" "}
      </div>
      <div className="db-container-content">
        <div className="db-trip">
          <div className="db-trip-header activetrip">
            <div className={"popupbtns btn-activetrip"}>
              {openActiveTrip ? (
                <div onClick={() => popDownActiveTrip(true)}>
                  {
                    <img
                      src={dropDownIcon}
                      alt="items down"
                      width={15}
                      height={8}
                      onMouseOver={(e) => (e.currentTarget.src = dropDownHoverIcon)}
                      onMouseOut={(e) => (e.currentTarget.src = dropDownIcon)}
                    />
                  }{" "}
                </div>
              ) : (
                <div onClick={() => popDownActiveTrip(false)}>
                  {
                    <img
                      src={dropUpIcon}
                      alt="items up"
                      width={15}
                      height={8}
                      onMouseOver={(e) => (e.currentTarget.src = dropUpHoverIcon)}
                      onMouseOut={(e) => (e.currentTarget.src = dropUpIcon)}
                    />
                  }
                </div>
              )}
            </div>
            {data.length !== 0 ? (
              <div>
                <h4 onClick={() => popDownActiveTrip(true)}>
                  {data[0].group}
                  <span className="db-trip-title-postfix">({data[0].events.length} events)</span>
                </h4>
                <p className="db-trip-date">
                  {getDate(data[0].from)} - {getDate(data[0].to)}
                </p>
                {data[0].description && <p>{data[0].description}</p>}
                <div className="btn-group-trips">
                  {data[0].events.length !== 0 && (
                    <img
                      src={markerIcon}
                      alt="show on map"
                      onClick={() =>
                        showTripOnMap(data[0].group, data[0].description, data[0].events)
                      }
                      width={25}
                      height={25}
                      title={"show on map"}
                      onMouseOver={(e) => (e.currentTarget.src = markerHoverIcon)}
                      onMouseOut={(e) => (e.currentTarget.src = markerIcon)}
                    />
                  )}
                  <img
                    src={editIcon}
                    onClick={() => setOpenEditActiveTrip(true)}
                    width={25}
                    height={25}
                    alt={"edit"}
                    title={"edit"}
                    onMouseOver={(e) => (e.currentTarget.src = editHoverIcon)}
                    onMouseOut={(e) => (e.currentTarget.src = editIcon)}
                  />
                  <img
                    src={deleteIcon}
                    onClick={() => {
                      if (window.confirm("Are you sure you wish to delete this event?"))
                        deleteTrip(data[0].id);
                    }}
                    width={25}
                    height={25}
                    onMouseOver={(e) => (e.currentTarget.src = deleteHoverIcon)}
                    onMouseOut={(e) => (e.currentTarget.src = deleteIcon)}
                    alt="delete trip"
                  />
                </div>
              </div>
            ) : (
              <p>You currently have no active trips</p>
            )}
          </div>
          {openEditActiveTrip && (
            <EditTripModal
              openEditRemote={true}
              tripId={data[0].id}
              tripGroup={data[0].group}
              tripDesc={data[0].description}
              fromDateOld={data[0].from}
              toDateOld={data[0].to}
              userID={user.id}
              sendOpenState={() => setOpenEditActiveTrip(false)}
              refetchData={() => refetchData()}
            />
          )}
          {openActiveTrip &&
            data.length !== 0 &&
            data[0].events.map((idx, i) => {
              return (
                <div className="trip-locations activetrips" key={i}>
                  <div className="trip-locations-header">
                    <h4>{idx.title}</h4>
                    <p className="db-trip-date">{getDate(idx.date)}</p>
                    {idx.description && <p>{idx.description}</p>}
                    <div className="btn-group-events">
                      <img
                        src={editIcon}
                        onClick={() => setActiveEvent(i)}
                        width={20}
                        height={20}
                        alt={"edit event"}
                        title={"edit event"}
                        onMouseOver={(e) => (e.currentTarget.src = editHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = editIcon)}
                      />
                      <img
                        src={deleteIcon}
                        onClick={() => {
                          if (window.confirm("Are you sure you wish to delete this trip?"))
                            deleteEvent(idx.id);
                        }}
                        alt="delete event"
                        title="delete event"
                        width={20}
                        height={20}
                        onMouseOver={(e) => (e.currentTarget.src = deleteHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = deleteIcon)}
                      />
                    </div>
                    {activeEvent === i && (
                      <EditEventModal
                        openEditRemote={true}
                        eventId={idx.id}
                        eventTitle={idx.title}
                        locationTitle={idx.tripdatum.title}
                        memory={idx.tripdatum.memories}
                        eventDesc={idx.description}
                        dateOld={idx.date}
                        userID={user.id}
                        sendOpenState={() => setActiveEvent(null)}
                        refetchData={() => refetchData()}
                      />
                    )}
                    {activeMemory === i && (
                      <CreateMemoryModal
                        tripDataId={idx.tripdatum.id}
                        openRemote={true}
                        parentCallback={() => setActiveMemory(null)}
                        refetchData={() => refetchData()}
                      />
                    )}
                    {activeEditMemory === i && (
                      <EditMemoryModal
                        openRemote={true}
                        tripDataId={idx.tripdatum.id}
                        userID={user.id}
                        memoryId={idx.tripdatum.memories[0].id}
                        oldTitle={idx.tripdatum.memories[0].title}
                        oldComment={idx.tripdatum.memories[0].comment}
                        oldDate={idx.date}
                        parentCallback={() => setActiveEditMemory(null)}
                        refetchData={() => refetchData()}
                      />
                    )}
                  </div>
                  {idx.tripdatum && (
                    <div>
                      <div className="trip-location-container">
                        <div className="trip-location-img">
                          {idx.tripdatum.image !== null && (
                            <Link to={`/article/${idx.tripdatum.Slug}`}>
                              <img
                                src={`${idx.tripdatum.image.formats.thumbnail.url}`}
                                alt={idx.tripdatum.title}
                                title={idx.tripdatum.title}
                              />
                            </Link>
                          )}
                        </div>
                        <div className="trip-locations-content">
                          <Link to={`/article/${idx.tripdatum.Slug}`}>
                            <h4>{idx.tripdatum.title}</h4>
                          </Link>
                          <p className="trip-location-category">
                            {idx.tripdatum.categories[0].category_sin}
                          </p>
                          <p className="trip-location-country">{idx.tripdatum.country}</p>
                        </div>
                      </div>
                      {idx.tripdatum.memories.length === 0 ? (
                        <p className="memory-button" onClick={() => setActiveMemory(i)}>
                          Make memory
                        </p>
                      ) : (
                        <p className="memory-button" onClick={() => setActiveEditMemory(i)}>
                          Edit memory
                        </p>
                      )}

                      {idx.tripdatum.memories.length !== 0 && (
                        <div className="trip-locations-memory">
                          <div>
                            <ul className="taglist">
                              {idx.tripdatum.memories[0].title &&
                                idx.tripdatum.memories[0].title.split(", ").map((idx, i) => {
                                  return <li key={i}>{idx}</li>;
                                })}
                            </ul>
                          </div>
                          <div>
                            <p>{idx.tripdatum.memories[0].comment}</p>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
