// Dependencies
import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Stack } from "@mui/material";

// JS pages
import Footer from "./Footer";

// Icons
import homeIcon from "../assets/home_big.svg";
import mapIcon from "../assets/gis_world-map.svg";

export default function NotFound(props) {
  return (
    <>
      <div className={"wrapper-container"}>
        <div id="single-article" className={"wrapper"}>
          <div
            className="wrapper-img"
            style={{
              background: `#fff url(https://res.cloudinary.com/hebd059oa/image/upload/v1671374686/notfound_bg_li8esd.jpg) no-repeat center`,
              backgroundSize: "cover",
            }}></div>
          <div className="page-container">
            <div className="page-content vsmall">
              <div className="item-title">
                <h1>Page Not Found</h1>
              </div>
              <div className={"item-wrapper"}>
                <div id="notfound-container">
               
                  <Box sx={{ height: "150px" }}>
                    <h2 style={{ textAlign: "center" }}>It's a bit too quiet here...</h2>
                    <p style={{ textAlign: "center" }}>But we have many other pages</p>
                  </Box>
                  <Box sx={{ height: "150px" }}>
                    <Stack spacing={0} direction="row">
                    <Link to="/">
                      <div className="notfound-btn" id="notfound-btn-left">
                     
                      <p>HOME</p>
                        <img src={homeIcon} alt="home" width={73} height={75} />                         
                     
                       
                      </div>
                      </Link>
                      <Link to="map">
                      <div className="notfound-btn" id="notfound-btn-right">
                    
                       <p>MAP</p>
                        <img src={mapIcon} alt="home" width={66} height={66} /> 
                        </div>
                       
                       
                    
                      </Link>
                    </Stack>
                  </Box>
               
                </div>
              </div>
            </div>
          </div>
          <div className="footer-wrapper">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
