import TextField from "@mui/material/TextField";
import { styled } from '@mui/system';

const StyledTextField = styled(TextField, {})({

  ".MuiAutocomplete-inputRoot": { 
     height: '35px' 
  },

".MuiInputBase-input" : {
      backgroundColor: 'transparent',     
      borderRadius : 7,
          '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: '#000000',   
    }
},


   ".MuiInput-underline::before" : {
  borderBottom: "none"
   }
})

export default StyledTextField;