// Dependencies
import { useEffect, useReducer, useContext, createContext } from "react";
import axios from 'axios';

const CurrentUserStateContext = createContext();
const CurrentUserDispatchContext = createContext();

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
    return {...action.user, isAuthenticated: true };
    case "LOGOUT":
    return { isAuthenticated: false };
    default:
    throw new Error(`unknown action ${action.type}`);
  }
};

export const CurrentUserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { isAuthenticated: false });

  useEffect(() => {

   const fetchUser = async () => {
    const res = await axios.get(`/api/users/me`) 
    const { status } = res.data

    if (status !== 'no jwt') {
      const user = res.data
      dispatch({ type: "LOGIN", user });
 }
}

fetchUser();
}, []);

  return (
    <CurrentUserDispatchContext.Provider value={dispatch}>
    <CurrentUserStateContext.Provider value={state}>
    {children}
    </CurrentUserStateContext.Provider>
    </CurrentUserDispatchContext.Provider>
    );
};

export const useCurrentUser = () => useContext(CurrentUserStateContext);
export const useDispatchCurrentUser = () => useContext(CurrentUserDispatchContext);