import { useState, useEffect } from "react";

export default function DrawToolTip({ drawMode, markers, errorMsg, result, kmlFileInput }) {
  const [ selectedFile, setSelectedFile ] = useState(null);
  const [isOpen, setIsOpen] = useState(true);

  const header = (mode) => {
    var str = "";
    switch (mode) {
      case "marker":
        str = "Get attractions around a point on the map";
        break;
      case "polyline":
        str = "Get attractions near a line with multiple vertices";
        break;
      case "route":
        str = "Get attractions near a route between 2 points";
        break;
      case "car":
        str = "Get attractions near a route by car between 2 points";
        break;
      case "bicycle":
        str = "Get attractions near a route by bicycle between 2 points";
        break;
      case "pedestrian":
        str = "Get attractions near a route by foot between 2 points";
        break;
      case "circle":
        str = "Get attractions within a circle with a chosen center and radius";
        break;
      case "kml":
        str = "Get attractions near a polyline in an imported KML file";
        break;
      default:
        str = "";
    }
    return str;
  };

  const instructionsSwitch = (mode) => {
    switch (mode) {
       case "marker":
        return caseMarker();
        break;
      case "polyline":
        return casePolyline();
        break;
      case "route":
        return caseRoute();
        break;
      case "car":
        return caseRoute();
        break;
      case "bicycle":
        return caseRoute();
        break;
      case "pedestrian":
        return caseRoute();
        break;
      case "circle":
        return caseCircle();
        break;
      default:
        return "";
    }
  };

  const caseMarker = () => {
    var str = "";
    if (markers === 0) {
      str = "Click to create a center marker";
    } 
    return str;
  };


  const casePolyline = () => {
    var str = "";
    if (markers === 0) {
      str = "Click to create the first point";
    } else if (markers === 1) {
      str = "Click to create the second point";
    } else if (markers > 1) {
      str =
        "Create as many points as you like. Finish the polyline by clicking the last point twice";
    }
    return str;
  };

  const caseRoute = () => {
    var str = "";
    if (markers === 0 || markers > 1) {
      str = "Click to create starting point";
    } else if (markers === 1) {
      str = "Click to create end point";
    }
    return str;
  };

  const caseCircle = () => {
    var str = "";
    if (markers === 0 || markers > 1) {
      str = "Click on the map to define the center";
    } else if (markers === 1) {
      str = "Decide the radius and click to finish the circle";
    }
    return str;
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      setSelectedFile(e.target.files[0]);
    }
  };

  const handleUploadClick = (event) => {
    event.preventDefault();
    if (!selectedFile) {
      alert("Please, select a KML file first!");
      return;
    } else {
      kmlFileInput(selectedFile);
    }
  };

  return (
    <div>
      {isOpen ? (
        <div className="searchtools-help">
          <div className="error-container-close" onClick={() => setIsOpen(false)}>
            X
          </div>
          <h3>{header(drawMode)}</h3>
          {
            <p className="searchtools-help-content" style={{ fontSize: "13px", color: "black" }}>
              {instructionsSwitch(drawMode)}
            </p>
          }
          {errorMsg !== "" && <p style={{ fontSize: "13px", color: "black" }}>{errorMsg} </p>}
          {drawMode === "kml" && (
            <div>
              <input type="file" accept=".kml" onChange={handleFileChange} />

              {selectedFile && (
                <div style={{ marginTop: "4px" }}>
                  <button onClick={handleUploadClick}>Upload</button>
                </div>
              )}
            </div>
          )}
        </div>
      )  : <div onClick={() => setIsOpen(true)} className="searchtools-help-closed">?</div>}
    </div>
  );
}
