// Dependencies
import { useState, useEffect } from "react";
import axios from "axios";

// Material ui
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import BootstrapDialogTitle from "./BootstrapDialogTitle";

export default function CreateTripModal({ openRemote, userID, parentCallBack, refetchData }) {
  const [open, setOpen] = useState(false);
  const [trip, setTrip] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [outputDateFrom, setOutputDateFrom] = useState(null);
  const [outputDateTo, setOutputDateTo] = useState(null);
  const [description, setDescription] = useState("");

  const [errorMsgDate, setErrorMsgDate] = useState(false);

  const handleDateFrom = (newValue) => {
    setOutputDateFrom(null);
    setFromDate(newValue);
    const dateString = JSON.stringify(newValue);
    const removeTime = dateString.substring(1, 11);
    setOutputDateFrom(removeTime);
  };

  const handleDateTo = (newValue) => {
    setOutputDateTo(null);
    setToDate(newValue);
    const dateString = JSON.stringify(newValue);
    const removeTime = dateString.substring(1, 11);
    setOutputDateTo(removeTime);
  };

  useEffect(() => {
    openRemote && setOpen(true);
    parentCallBack();
  }, [openRemote, trip]);

  const handleClose = () => {
    setOpen(false);
    return null;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    if (trip === "") {
      setIsEmpty(true);
      return null;
    }

    if (outputDateFrom > outputDateTo) {
      setErrorMsgDate("End date cannot be earlier than start date!");
      return null;
    }

    if (trip !== "") {
      const body = {
        group: trip,
        description: description,
        users_permissions_user: userID,
        from: outputDateFrom,
        to: outputDateTo,
      };

      const createRes = await axios.post(`/api/mytrips/createtrip`, body);

      if (createRes.status === 200) {
        setOpen(false);
        setIsEmpty(false);
        setOutputDateTo(null);
        setOutputDateFrom(null);
        setFromDate(new Date());
        setToDate(new Date());
        refetchData();
      }
    }
  };

  return (
    <Dialog fullWidth aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Create new trip
      </BootstrapDialogTitle>
      <DialogContent>
        <label>
          <h4>Name your trip</h4>
        </label>
        <input
          placeholder="e.g. horseback riding in Bulgaria"
          type="text"
          onChange={(e) => setTrip(e.target.value)}
        />
        <label>
          <h4>A short description</h4>
        </label>
        <textarea
          rows="5"
          cols="50"
          name="description"
          onChange={(e) => setDescription(e.target.value)}
        />
        {isEmpty && <p style={{ color: "red" }}>please think of a name for your next trip</p>}
        <div className="dates">
          <div className="date-from">
            <label>
              <h4>Start date</h4>
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                hiddenlabel="true"
                inputFormat="dd/MM/yyyy"
                value={fromDate}
                onChange={handleDateFrom}
                clearable={true}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className="date-to">
            <label>
              <h4>End date</h4>
            </label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                hiddenlabel="true"
                inputFormat="dd/MM/yyyy"
                value={toDate}
                onChange={handleDateTo}
                clearable={true}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
            <p style={{ color: "red" }}>{errorMsgDate}</p>
          </div>
        </div>
        <DialogActions>
          <button className="tstbtn medium radiusall" onClick={handleClose}>
            Cancel
          </button>
          <button className="tstbtn medium radiusall" onClick={handleSubmit}>
            OK
          </button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
