// Dependencies
import React, { useState, useEffect } from "react";
import axios from "axios";

// js pages
import { useCurrentUser } from "../component/CurrentUser";
import CreateTripModal from "../modals/CreateTripModal";

import ActiveTrips from "./ActiveTrips";
import FutureTrips from "./FutureTrips";
import PastTrips from "./PastTrips";

import Footer from "../public_pages/Footer";

export default function MyTrips({ tripInfo }) {
  const user = useCurrentUser();
  const [userData, setUserData] = useState(null);
  const [pastTrips, setPastTrips] = useState([]);
  const [activeTrips, setActiveTrips] = useState([]);
  const [futureTrips, setFutureTrips] = useState([]);
  const [openTripModal, setOpenTripModal] = useState(false);

  const refetchData = async () => {
    const res = await axios.get(`api/users/me`);
    setUserData(res.data);
    //console.log("res.data:" , res.data)
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();

    today = yyyy + "-" + mm + "-" + dd;

    const pTrips = [];
    const fTrips = [];
    const aTrips = [];

    res.data.trips.map((idx) => {
      if ((idx.from < today && idx.to < today) || (idx.from === null && idx.to === null)) {
        pTrips.push(idx);
      } else if (idx.from < today && idx.to > today) {
        aTrips.push(idx);
      } else {
        fTrips.push(idx);
      }
    });

    setPastTrips(pTrips);
    setActiveTrips(aTrips);
    setFutureTrips(fTrips);
  };

  useEffect(() => {
    refetchData();
  }, [user]);

  const createNewTrip = () => {
    setOpenTripModal(true);
  };

  return (
    <div className="wrapper-container">
      <div className="wrapper">
        <div className={"page-content small"}>
          <div>
            <div className="top-section">
              <h3>Welcome,&nbsp; {user.username}!</h3>
              <button className="db-createtrip-btn" onClick={createNewTrip}>
                Create Trip
              </button>
            </div>
            <div className="db-full-width-container">
              <ActiveTrips data={activeTrips} refetchData={() => refetchData()} />
            </div>
            <div className="db-left-pane-container">
              <FutureTrips data={futureTrips} refetchData={() => refetchData()} />
            </div>
            <div className="db-right-pane-container">
              <PastTrips data={pastTrips} refetchData={() => refetchData()} />
            </div>
            <CreateTripModal
              openRemote={openTripModal}
              userID={user.id}
              parentCallBack={() => setOpenTripModal(false)}
              refetchData={() => refetchData()}
            />
          </div>
        </div>
        <div className="footer-wrapper">
          <Footer />
        </div>
      </div>
    </div>
  );
}
