// Dependencies
import { Link } from 'react-router-dom';

// assets
/*import facebook from '../assets/facebook.svg';
import instagram from '../assets/instagram.svg';
import facebookHover from '../assets/facebook_hover.svg';
import instagramHover from '../assets/instagram_hover.svg';*/

export default function Footer() {
	
	return(
		<div className="footer-container">
		<div className="page-content small">
		<div className="footer-inner-left">

		</div>
		<div className="footer-center">
		<ul style={{ listStyle: 'none'}}>
		<li >
		<a href="https://www.markovmedia.nl" target="_blank" rel="noreferrer">Markov Media</a> ©2024
		</li>
		&nbsp;|&nbsp;
		<li>
		  <Link to="/release-notes">Release Notes</Link>
			</li>
		</ul>
		</div>

		<div className="footer-inner-right">

		</div>
		</div>
		</div>
		)

}