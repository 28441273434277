// Dependencies
import React, { useEffect, useState } from "react";

// Js Pages
import { MemoizedPaneDetailsTab, PaneDetailsTab } from "./PaneDetailsTab";
//import PaneDetailsTab from "./PaneDetailsTab";
import PaneRouteTab from "./PaneRouteTab";
import PaneInfoTab from "./PaneInfoTab";

export default function PaneB({
  centrePos,
  centreGeostring,
  dataForPaneB,
  routeCB,
  routeTransitCB,
  routeFocus,
}) {
  const [openPane, setOpenPane] = useState("details");

  useEffect(() => {
    if (
      dataForPaneB.mode === "car" ||
      dataForPaneB.mode === "bicycle" ||
      dataForPaneB.mode === "pedestrian" ||
      dataForPaneB.mode === "transit"
    ) {
      setOpenPane("route");
    } else if (dataForPaneB.mode === "details") {
      setOpenPane("details");
    } 
  }, [dataForPaneB]);

  const detailsOpen = () => {
    setOpenPane("details");
  };

  const routeOpen = () => {
    setOpenPane("route");
  };

  const infoOpen = () => {
    setOpenPane("info");
  };

  return (
    <>
      <div className="pane-header">
        <div
          className={
            openPane === "details" ? "pane-header-inner" : "pane-header-inner detailstabclose"
          }
          style={{ width:  "50%" }}
          onClick={() => detailsOpen()}>
          details
        </div>
        <div
          className={openPane === "route" ? "pane-header-inner" : "pane-header-inner routetabclose"}
          style={{ width:  "50%" }}
          onClick={() => routeOpen()}>
          route
        </div>
        {/*dataForPaneB.sfInfo !== null && (
          <div
            className={openPane === "info" ? "pane-header-inner" : "pane-header-inner infotabclose"}
            style={{ width: "33.33%" }}
            onClick={() => infoOpen()}>
            predictions
          </div>
        )*/}
      </div>     
        <div className="pane-content">
          <div className="pane-content-container">
          {openPane === "details" && dataForPaneB.travelid !== undefined ? (
            <MemoizedPaneDetailsTab travelideas={dataForPaneB.travelid} />
          ) : null}
          {openPane === "route" ? (
            <PaneRouteTab
              centrePos={centrePos}
              centreGeostring={centreGeostring}
              dataForPaneB={dataForPaneB}
              routeCB={routeCB}
              routeTransitCB={routeTransitCB}
              routeFocus={routeFocus}
            />
          ) : null}
          {openPane === "info" ? <PaneInfoTab dataForPaneB={dataForPaneB.sfInfo} /> : null}
        </div>
      </div>
    </>
  );
}
