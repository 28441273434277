import { useCallback, useEffect, memo } from "react";

import centerImg from "../assets/center.png";
import nocenter from "../assets/nocenter.png";

const CenterPoiBounds = memo(function CenterPoiBounds({ map, centerpos, poi }) {
  //console.log("centerpoibounds: ", poi);

  function onFitBounds() {
    var highestLat = 0;
    var highestLng = 0;

    poi.forEach((e) => {
      if (highestLat < Math.abs(centerpos.lat - e.lat)) {
        highestLat = Math.abs(centerpos.lat - e.lat);
      }
      if (highestLng < Math.abs(centerpos.lng - e.lng)) {
        highestLng = Math.abs(centerpos.lng - e.lng);
      }
    });

    var aLat = centerpos.lat + highestLat;
    var bLat = centerpos.lat + highestLat;
    var cLat = centerpos.lat - highestLat;
    var dLat = centerpos.lat - highestLat;
    var bLng = centerpos.lng + highestLng;
    var cLng = centerpos.lng + highestLng;
    var aLng = centerpos.lng - highestLng;
    var dLng = centerpos.lng - highestLng;

    const bounds = [
      [aLat, aLng],
      [bLat, bLng],
      [cLat, cLng],
      [dLat, dLng],
    ];

    map.fitBounds(bounds);
    return null;
  }

  const onMove = useCallback(() => {
    map.getCenter();

  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  useEffect(() => {
    onFitBounds();
  }, [centerpos, poi]);

  return null;
});

const CenterPoiBoundsButton = memo(function CenterPoiBoundsButton({ map, centerpos, poi }) {
  //console.log("CenterPoiBoundsButton: ", poi);

  function onFitBounds() {
    var highestLat = 0;
    var highestLng = 0;

    poi.forEach((e) => {
      if (highestLat < Math.abs(centerpos.lat - e.lat)) {
        highestLat = Math.abs(centerpos.lat - e.lat);
      }
      if (highestLng < Math.abs(centerpos.lng - e.lng)) {
        highestLng = Math.abs(centerpos.lng - e.lng);
      }
    });

    var aLat = centerpos.lat + highestLat;
    var bLat = centerpos.lat + highestLat;
    var cLat = centerpos.lat - highestLat;
    var dLat = centerpos.lat - highestLat;
    var bLng = centerpos.lng + highestLng;
    var cLng = centerpos.lng + highestLng;
    var aLng = centerpos.lng - highestLng;
    var dLng = centerpos.lng - highestLng;

    const bounds = [
      [aLat, aLng],
      [bLat, bLng],
      [cLat, cLng],
      [dLat, dLng],
    ];
    map.fitBounds(bounds);
    return null;
  }

  const onMove = useCallback(() => {
    map.getCenter();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  return (
    <img
      src={poi ? centerImg : nocenter}
      onClick={onFitBounds}
      id="center"
      alt="reset center position"
      title="reset center position"
    />
  );
});

const MultiPoiBounds = memo(function MultiPoiBounds({ map, poi }) {
  console.log("MultiPoiBounds: ", poi);

  function onFitBounds() {
  
    const bounds = poi.map((a) => ({ lat: a.lat, lng: a.lng }));

    map.fitBounds(bounds);
    return null;
  }

  const onMove = useCallback(() => {
    map.getCenter();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  useEffect(() => {
    onFitBounds();
  }, [poi]);

  return null;
});




const CenterSinglePoi = memo(function CenterSinglePoi({ map, singleFocus }) {
 
  function onCenter() {
    map.flyTo(singleFocus, 15);
    return null;
  }

  const onMove = useCallback(() => {
    map.getCenter();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  useEffect(() => {
    onCenter();
  }, [singleFocus]);

  return null;
});

export function CenterPoiBoundsTrip({ map, poi }) {
  //console.log("CenterPoiBoundsTrip: ", poi);

  const bounds = poi.map((a) => ({ lat: a.lat, lng: a.lng }));
  map.fitBounds(bounds);
  return null;
}

const CenterRoute = memo(function CenterRoute({ map, data }) {
  //console.log("centerRoute: ", data);
  map.fitBounds(data);
  return null;
});

export function ZoomPoi({ map, coords }) {
 // console.log("Zoompoi: ", coords);

  map.flyTo(coords, 15);
  return null;
}

export function MultiZoom({ map, coords }) {
  //console.log("Multizoom: ", coords);
  map.fitBounds(coords);
  return null;
}

export { CenterPoiBounds, CenterPoiBoundsButton, MultiPoiBounds, CenterSinglePoi, CenterRoute };
