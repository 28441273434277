// Dependencies
import { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";

import { ParticleTest } from "../public_pages/ParticleTest";

// assets

import fpSynIcon1 from "../assets/searchspot.svg";
import fpSynIcon2 from "../assets/highlight.svg";
import fpSynIcon3 from "../assets/summary-route.svg";
import fpSynIcon4 from "../assets/summary-list.svg";

function FadeInSection({ children, speed }) {
  const domRef = useRef();
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.unobserve(domRef.current);
      }
    });

    observer.observe(domRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={domRef}
      style={{ transition: `opacity ${speed}s ease-out, transform ${speed}s ease-out` }}
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}>
      {children}
    </div>
  );
}

export default function FPSummary() {
  const navigate = useNavigate();
  const linkTo = (link) => {
    navigate(`/${link}`);
  };

  const mainHeader = (
    <div className="fp-syn-item">
      <h2>Crowd free spots for independent travelers</h2>
    </div>
  );

  const mainList = (
    <div>
      <div className={"fp-syn-item"}>
        <ul>
          <li>
            <span>
              <img src={fpSynIcon1} width={34} height={34} />
            </span>
            <p>
              Type any place in Europe as a starting point, or click on the map at the desired spot
            </p>
          </li>
          <li>
            <span>
              <img src={fpSynIcon2} width={34} height={34} />
            </span>
            <p> We present 10 nearest things to do or to see ordered by quietness and serenity</p>
          </li>
          <li>
            <span>
              <img src={fpSynIcon3} width={34} height={34} />
            </span>
            <p> Expand or filter your search with categories or activities</p>
          </li>
          <li>
            <span>
              <img src={fpSynIcon4} width={34} height={34} />
            </span>
            <p>Calculate routes from your starting point by car, bike foot or public transport</p>
          </li>
        </ul>
      </div>
    </div>
  );

  const subListHeader = (
    <div className="fp-syn-item">
      <h3>Benefits of visiting quiet destinations</h3>
    </div>
  );

  const subList = (
    <div>
      <ul>
        <li>More authentic</li>
        <li>Generally cheaper</li>
        <li>Less waiting in lines</li>

        <li>More social interactions</li>
        <li>No stress or overstimulation</li>
        <li>Supports local tourism</li>
      </ul>
    </div>
  );

  const c2a = (
    <div>
      <button onClick={() => linkTo("howitworks")} className={"tstbtn fp-syn-readmorebtn"}>
        <p>
          find out more
          <span>
            <FontAwesomeIcon className="icon-solid" icon={faCaretRight}></FontAwesomeIcon>
          </span>
        </p>
      </button>
    </div>
  );

  return (
    <div className={"fp-syn-container"}>
      {mainHeader}

      {mainList}
      <div className="fp-syn-container-sub">
        {subListHeader}
        {subList}
      </div>
      {c2a}
    </div>
  );
}
