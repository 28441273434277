import React, { useState } from "react";
import Chart from "react-apexcharts";

const testData = [
  {
    data: [
      { x: 1672617600000, y: 7.2, remarks: "vacation" },
      { x: 1672704000000, y: 7.5, remarks: null },
      { x: 1672790400000, y: 7.2, remarks: "weather good" },
      { x: 1672876800000, y: 7.4, remarks: "vacation" },
      { x: 1672963200000, y: 7.8, remarks: null },
    ],
  },
];

export default function ApexChartDemo({ data }) {
  //console.log("data:", data);

  const timestamp2date = (timestamp) => {
    const daysOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(timestamp);
    const dayOfWeek = d.getDay();
    const dayOfMonth = d.getDate();
    const month = d.getMonth();
    const year = d.getFullYear();

    return daysOfWeek[dayOfWeek] + " " + dayOfMonth + " " + months[month] + " " + year;
  };

  const [options, setOptions] = useState({
    chart: {
      id: "area-datetime",
      height: 350,
      zoom: {
        autoScaleYaxis: true,
      },
    },
    
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    annotations: {},
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },

    xaxis: {
      type: "datetime",
    },
    yaxis: {
      min: 0,
      max: 10.0,
      tickAmount: 5,
      title: { text: "Silence Factor" },
    },

    annotations: {
      xaxis: [
        {
          x: new Date().getTime(),
          borderColor: "#000",
          strokeDashArray: 1,
          strokeWidth: "5px",
          width: "4px",
          fillColor: "#c2c2c2",
          yAxisIndex: 0,
          label: {
            show: true,
            text: "Today",
            style: {
              color: "#fff",
              background: "#000",
            },
          },
        },
      ],
    },
    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {       
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        var testString = data.remarks.length !== 0 ? '<p><strong>Remarks: </strong></p>' : '';
        data.remarks.map((idx) => {
          testString = testString + '<p>' + idx + '</p>';
        });

        return (
          '<div class="chart-tooltip"><p><strong>Date: </strong>' +
          timestamp2date(data.x) +
          '</p>' +
          '<p><strong>Silence Factor: </strong>' +
          data.y +
          '</p>' +
          '<p><strong>Accuracy: </strong></p>' +                 
          testString +
          '</div>'
        );
      },
    },
    grid: {
      show: true,
      borderColor: "#90A4AE",
      strokeDashArray: 1,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 1,
        colorStops: [
          {
            offset: 0,
            color: "#B72424",
            opacity: 1,
          },
          {
            offset: 20,
            color: "#D17229",
            opacity: 1,
          },
          {
            offset: 40,
            color: "#EAC12E",
            opacity: 1,
          },
          {
            offset: 80,
            color: "#3EB524",
            opacity: 1,
          },
          {
            offset: 100,
            color: "#3EB524",
            opacity: 1,
          },
        ],
      },
    },
  });

  return <Chart options={options} series={[data]} type="line" width={"100%"} height={320} />;
}
