import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  
  return (
    <DialogTitle sx={{ m: 0, color: '#fff', backgroundColor: '#049bd3', height: '30px', padding: '10px', marginBottom: '14px' }} {...other}>
    {children}
    {onClose ? (
      <IconButton 
         sx={{
        position:'absolute',
        right: '8px',
        top: '8px',
        color:'#fff'
      }} 
      aria-label="close"
      onClick={onClose}
      
      >
      <CloseIcon />
      </IconButton>
      ) : null}
    </DialogTitle>
    );
};

export default BootstrapDialogTitle;