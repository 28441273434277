// Dependencies
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Rating from "@mui/material/Rating";
import { Icon } from "@mui/material";
import { styled } from "@mui/styles";

// js pages
import SEO from "./SEOsinglePage";
import { useCurrentUser } from "./CurrentUser";
import CreateMemoryModal from "../modals/CreateMemoryModal";
import Footer from "../public_pages/Footer";
import SaveToTrip from "./SaveToTrip";
import NotFound from "../public_pages/NotFound";

// queries
import Query from "./Query";
import QUERY_SINGLELOCATION from "../queries/QUERY_SINGLELOCATION";

// styling
import "../index.css";
import "../normalize.css";
import "../skeleton.css";

// icons
import takemethere from "../assets/takemethere2.svg";
import takemethereHover from "../assets/takemethere2_hover.svg";
import personGreenIcon from "../assets/person_green.svg";
import personOrangeIcon from "../assets/person_orange.svg";
import personRedIcon from "../assets/person_red.svg";
import personEmpty from "../assets/person_empty.png";

const PersonGreen = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img alt="" src={personGreenIcon} height={30} width={19} />
  </Icon>
);

const PersonOrange = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img alt="" src={personOrangeIcon} height={30} width={19} />
  </Icon>
);

const PersonRed = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img alt="" src={personRedIcon} height={30} width={19} />
  </Icon>
);

const PersonEmpty = () => (
  <Icon style={{ height: 0, width: 0 }}>
    <img alt="" src={personEmpty} height={30} width={19} />
  </Icon>
);

const StyledRating = styled(Rating)({
  root: {
    "&.MuiRating-root": {
      fontSize: "1.8rem",
    },
  },
});

function OneTripdata() {
  let { Slug } = useParams();
  const navigate = useNavigate();
  const user = useCurrentUser();

  const [open, setOpen] = useState(false);

  const sendToMap = (data) => {

    console.log("data  sendToMap: ", data)
    const { title, lat, image, categories, silentFactor } = data.data[0].attributes;
    
    const lng = data.data[0].attributes.lon
    const { id } = data.data[0];

    var obj = {
      title: title,
      category_sin: categories.data[0].attributes.category_sin,
      category_uid: categories.data[0].attributes.category_uid,
      url: image.data.attributes.url,
      formats: { thumbnail : { url :image.data.attributes.formats.thumbnail.url}} ,
      silent_factor: silentFactor,
      lat: lat,
      lng: lng,
      id: id,      
    };

    localStorage.setItem("takemethere", JSON.stringify(obj));
    navigate({
      pathname: "/map",
    });
  };

  const getDate = (date) => {
    const d = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const displayDate = d.getDay() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
    return displayDate;
  };

  const checkIfSaved = (id) => {
    var trips = JSON.parse(localStorage.getItem("mytrips"));
    trips = trips ? trips : [];
    const found = trips.find((element) => element === id);
    if (found !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Query query={QUERY_SINGLELOCATION} Slug={Slug}>
      {({ data: { tripdata } }) => {
        if (tripdata.data.length != 0) {
          var { id } = tripdata.data[0];
          const {
            title,
            translated_content,
            url,
            height,
            wikilink,
            wikipedia,
            summitpost,
            image,
            country,
            silentFactor,
            enjoymentFactor,
            silentFactorJSON,
          } = tripdata.data[0].attributes;
          const { category_sin } = tripdata.data[0].attributes.categories.data[0].attributes;

          return (
            <>
              <SEO seo={tripdata} />

              <div className={"wrapper-container"}>
                <div className={"wrapper"}>
                  <div
                    className="wrapper-img"
                    style={{
                      background: `#fff url(${image.data.attributes.url}) no-repeat center`,
                      backgroundSize: "cover",
                    }}></div>
                  <div className="page-container">
                    <div className="page-content small">
                      <div className="item-title">
                        <h1>{title}</h1>
                        <div className={"item-e-rate"} title={"Enjoyment: " + enjoymentFactor}>
                          {enjoymentFactor > 0 && (
                            <StyledRating
                              name="enjoyment-rating"
                              precision={0.5}
                              value={enjoymentFactor}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                      <div className={"item-wrapper"}>
                        <div className={"item-header-row1"}>
                          <div className={"item-header-left"}>
                            <div className={"item-header-row2-right"}>
                              <div className={"item-btn-savetrip"}>
                                <div>
                                  <SaveToTrip
                                    article={tripdata.data[0].id}
                                    userID={user.id}
                                    isSaved={checkIfSaved(id)}
                                  />
                                </div>
                              </div>
                              <div className={"item-btn-tmt"}>
                                {
                                  <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => sendToMap(tripdata)}>
                                    <img
                                      src={takemethere}
                                      alt={"Take me there!"}
                                      width={25}
                                      height={25}
                                      title={"Take me there!"}
                                      onMouseOver={(e) => (e.currentTarget.src = takemethereHover)}
                                      onMouseOut={(e) => (e.currentTarget.src = takemethere)}
                                    />
                                  </div>
                                }
                              </div>
                            </div>
                            <h6>
                              {category_sin} in {country}
                            </h6>
                          </div>
                          <div className={"item-header-right"}>
                            <Rating
                              name="silent-rating"
                              precision={1}
                              value={silentFactor}
                              readOnly
                              icon={
                                silentFactor < 2 ? (
                                  <PersonGreen />
                                ) : silentFactor >= 2 && silentFactor < 4 ? (
                                  <PersonOrange />
                                ) : (
                                  <PersonRed />
                                )
                              }
                              emptyIcon={<PersonEmpty />}
                            />
                            <p
                              style={{
                                color:
                                  silentFactor < 2
                                    ? "#01BD14"
                                    : silentFactor >= 2 && silentFactor < 4
                                    ? "#BD8801"
                                    : "#BD0101",
                              }}>
                              <strong>{silentFactor.toFixed(1)}</strong>
                            </p>
                          </div>
                        </div>
                        <div className={"item-main"}>
                          <div className={"item-leftcol"}>
                            <div className={"item-image"}>
                              {image !== null && (
                                <div>
                                  <Zoom>
                                    <img
                                      src={`${image.data.attributes.url}`}
                                      width={600}
                                      alt={title}
                                      title={title}
                                    />
                                  </Zoom>
                                  <div className={"wikilink wikilink-singleitem"}>
                                    <a href={wikilink} target="_blank" rel="noreferrer">
                                      <p>&copy; image source</p>
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                            {height !== null && <p>Height: {height} m</p>}
                          </div>

                          <div className={"item-rightcol"}>
                            <div className={"item-content"}>
                              <p>{translated_content}</p>
                              {url && (
                                <div className="detail-url">
                                  <a href={url} target="_blank" rel="noreferrer">
                                    website
                                  </a>
                                </div>
                              )}
                              {wikipedia && (
                                <div className="detail-url">
                                  <a href={wikipedia} target="_blank" rel="noreferrer">
                                    wikipedia
                                  </a>
                                </div>
                              )}
                              {summitpost && (
                                <div className="detail-url">
                                  <a href={summitpost} target="_blank" rel="noreferrer">
                                    summitpost
                                  </a>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div>
                        { /*  <ApexChartDemo data={silentFactorJSON} /> */}
                        </div>

                        <div>
                          <div className={"detail-memory-footer"}>
                            <div className={"detail-memory-footer-content"}>                            
                              <ul>
                                {tripdata &&
                                  tripdata.data[0].attributes.memories.data.map((idx, i) => {
                                    return (
                                      <div key={i} className={"review-section"}>
                                        <li>
                                          <div className={"detail-memory-username"}>
                                            <p>
                                              {
                                                idx.attributes.users_permissions_user.data
                                                  .attributes.username
                                              }
                                            </p>
                                          </div>                                         
                                          <div className={"detail-memory-header"}>
                                            <div className={"detail-memory-date"}>
                                              {getDate(idx.attributes.createdAt)}
                                            </div>
                                            <div className={"e-rate"}>
                                              <Rating
                                                name="enjoyment-rating"
                                                precision={0.5}
                                                size="medium"
                                                value={parseFloat(idx.attributes.enjoyment)}
                                                readOnly
                                              />
                                            </div>
                                          </div>
                                          <div className={"detail-memory-title"}>
                                            <div>
                                              <ul className="taglist">
                                                {idx.attributes.title &&
                                                  idx.attributes.title.split(", ").map((idx, i) => {
                                                    return <li key={i}>{idx}</li>;
                                                  })}
                                              </ul>
                                            </div>
                                          </div>
                                          <div className={"detail-memory-comment"}>
                                            <p>
                                              {idx.attributes.comment &&
                                                idx.attributes.comment.substring(0, 80)}
                                            </p>
                                          </div>
                                        </li>
                                      </div>
                                    );
                                  })}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <CreateMemoryModal
                        tripDataId={id}
                        openRemote={open}
                        parentCallback={() => setOpen(false)}
                      />
                    </div>
                  </div>
                </div>
                <div className="footer-wrapper">
                  <Footer />
                </div>
              </div>
            </>
          );
        } else {
          return <NotFound />;
        }
      }}
    </Query>
  );
}

export default OneTripdata;
