import gql from "graphql-tag";

const QUERY_TAGGROUPS = gql`
query TagGroups {
  tagGroups {
    data {
      id
      attributes {
        group
        tags (sort: "tag:asc") {
          data {
            id
            attributes {
              tag
              description
            }
          }
        }
      }
    }
  }
}

`;

export default QUERY_TAGGROUPS;

