// Dependencies
import React, { useEffect, useState } from "react";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";

// JS pages
import getMarker from "./helpers/getMarker";

// styling
import StyledTextField from "../styles/StyledTextField";

const OPTIONS_LIMIT = 10;
const defaultFilterOptions = createFilterOptions();

const filterOptions = (options, state) => {
  if (state.inputValue === "") {
    return defaultFilterOptions(options, state).slice(0, 0);
  } else {
    return defaultFilterOptions(options, state).slice(0, OPTIONS_LIMIT);
  }
};

const AutoSuggestTo = ({ reset, parentCallbackTo }) => {
  const [tagList, setTagList] = useState([]);
  const [value, setValue] = useState(null);
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/getalltags`).then((response) => {
      setTagList(response.data[0]);
      setCategories(response.data[1]);
    });
  }, []);

  useEffect(() => {
    reset !== null && setValue(null);
  }, [reset]);

  const catConversion = (id) => {
    var obj = categories.find((o) => o.id === id);
    return obj.category_uid;
  };

  const getValue = (v) => {
   
    setValue(v);

    if (v !== null) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/getonetag/${v.id}`)
        .then((response) => {
          parentCallbackTo(response.data);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    }
  };

  return (
    <>
      {tagList && (
        <Autocomplete
          id="custom-autocomplete"
          filterOptions={filterOptions}
          value={value}
          hiddenlabel="true"
          options={tagList}
          noOptionsText={"Type something, like 'Eiffel Tower'..."}
          getOptionLabel={(option) => `${option.title}`}
          isOptionEqualToValue={(option, value) => option.title === value.title}
          onChange={(event, newValue) => {
            getValue(newValue);
          }}
          renderOption={(props, option) => (
            <Box component="li" sx={{ "& > img": { mr: 2, flexShrink: 0 } }} {...props}>
              <img
                loading="lazy"
                width="32"
                src={getMarker(catConversion(option.category_id))}
                alt=""
              />
              <div>
                <div className="suggestions-to-field title"> {option.title}</div>
                <div className="suggestions-to-field country">{option.country}</div>
              </div>
            </Box>
          )}
          renderInput={(params) => {
            return (
              <StyledTextField
                {...params}
                variant="standard"
                placeholder={"any tourist attraction..."}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default AutoSuggestTo;
