// Dependencies
import React from "react";
import { useQuery } from "@apollo/react-hooks";
import DOMPurify from "dompurify";

// js pages
import Footer from "../public_pages/Footer";

// queries
import QUERY_RELEASENOTES from "../queries/QUERY_RELEASENOTES";

const Query = ({ children, query }) => {
  const { data, loading, error } = useQuery(query);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;

  return children({ data });
};

const getDate = (date) => {
  if (date !== null) {
    const d = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const displayDate = d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
    return displayDate;
  } else return null;
};

export default function ReleaseNotes() {
  return (
    <Query query={QUERY_RELEASENOTES}>
      {({ data: { releasenotes } }) => {
        console.log("releasenote: ", releasenotes.data);
        // const { title, content } = releasenotes.data.attributes;
        return (
          <>
            <div className={"wrapper-container"}>
              <div id="single-article" className={"wrapper"}>
                <div
                  className="wrapper-img"
                  style={{
                    background: `#fff url('https://res.cloudinary.com/hebd059oa/image/upload/v1667404001/matrix_g3437d5ff2_1920_jpg_2feba132de.jpg') no-repeat center`,
                    backgroundSize: "cover",
                  }}></div>
                <div className="page-container">
                  <div className="page-content vsmall">
                    <div className="item-title">
                      <h1>Release notes</h1>
                    </div>
                    <div className={"item-wrapper"}>
                      {releasenotes.data.map((idx) => {
                        const { version, releasedate, features, bugfix, removed } = idx.attributes;
                        return (
                          <div className="release-item" key={idx.id}>
                            <div className="release-item-header">
                              <h3>{getDate(releasedate)}</h3>
                              <h6>{version}</h6>
                            </div>
                            {features && (
                              <>
                                <h4>NEW FEATURES</h4>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(features),
                                  }}></div>
                              </>
                            )}
                            {bugfix && (
                              <>
                                <h4>BUGFIXES</h4>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(bugfix),
                                  }}></div>
                              </>
                            )}
                            {removed && (
                              <>
                                <h4>REMOVED</h4>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(removed),
                                  }}></div>
                              </>
                            )}
                          </div>
                        );
                      })}

                      {/*}  <ReactMarkdown>{content}</ReactMarkdown> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-wrapper">
                <Footer />
              </div>
            </div>
          </>
        );
      }}
    </Query>
  );
}
