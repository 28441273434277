import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

//const token = localStorage.getItem('token')
//console.log("token: ", token)
const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_URL}/graphql`
});

const authLink = setContext((_, { headers }) => {

  return {
    headers: {
      ...headers,
    
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});



export default client;




