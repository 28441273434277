// Dependencies
import { useState, useEffect } from "react";
import axios from "axios";

// Material ui
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import BootstrapDialogTitle from "./BootstrapDialogTitle";

// JS pages
import StyledTextField from "../styles/StyledTextField";

export default function SaveToTripModal({ savedItem, openRemote, itineraries, isSavedCB }) {
  const [open, setOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(0);

  useEffect(() => {
    openRemote && setOpen(true);
  }, [openRemote]);

  const handleClose = () => {
    setOpen(false);
  };

  const save = async () => {
    if (itineraries.length !== 0) {
      if (selectedTrip === null) return null;
      const body = { trip: selectedTrip, location: savedItem };
      const updateRes = await axios.post(`/api/myevents/createevent`, body);
      console.log("updateRes: ", updateRes);
      if (updateRes.status === 200) {
        setOpen(false);
        isSavedCB(true);
      }
    } else {
      setOpen(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedTrip(value);
  };

  return (
    <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Save as event
      </BootstrapDialogTitle>
      <DialogContent>
        <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
          <FormControl sx={{ m: 1, width: "220px" }}>
            {itineraries !== null && itineraries.length !== 0 ? (
              <StyledTextField
                value={selectedTrip}
                onChange={handleChange}
                select
                label="select trip">
                {itineraries?.map((option) => {
                  return (
                    <MenuItem style={{ display: "block" }} key={option.id} value={option.id}>
                      {option.group ?? option.group}
                    </MenuItem>
                  );
                })}
              </StyledTextField>
            ) : (
              <p>First create a trip in the planner</p>
            )}
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <button className="tstbtn medium radiusall" onClick={handleClose}>
          Cancel
        </button>
        <button className="tstbtn medium radiusall" onClick={save}>
          OK
        </button>
      </DialogActions>
    </Dialog>
  );
}
