// Dependencies
import { useState, useEffect, useRef, memo, useCallback, createRef } from "react";
import {
  MapContainer,
  LayersControl,
  TileLayer,
  useMap,
  ScaleControl,
  ZoomControl,
} from "react-leaflet";
import _ from "lodash";
import Helmet from "react-helmet";
import axios from "axios";

import LinearProgress from "@mui/material/LinearProgress";

// js pages
import PaneA from "./PaneA";
import PaneB from "./PaneB";
import LocationMarker from "./LocationMarker";
import useWindowDimensions from "../utils/useWindowDimensions";
import SelectActivityList from "./SelectActivityList";
import SelectCategoryList from "./SelectCategoryList";
import {
  CenterPoiBounds,
  CenterPoiBoundsButton,
  CenterSinglePoi,
  CenterPoiBoundsTrip,
  CenterRoute,
  ZoomPoi,
  MultiZoom,
} from "./CenterPois";
import RenderRouteMap from "./RenderRouteMap";
import RenderRouteTransitMap from "./RenderRouteTransitMap";
import { StyledFormControlLabel, FloatSwitch } from "../styles/StyledSwitchMobile";
import SearchTools from "./SearchTools";
import DrawToolTip from "./DrawToolTip";
import Focus from "./Focus";

// assets
import home from "../assets/home.svg";
import filterbtn from "../assets/filterbtn.svg";
import search from "../assets/search.svg";
import tab_map from "../assets/tab_map_off.svg";
import tab_map_active from "../assets/tab_map_on.svg";
import tab_list from "../assets/tab_list_off.svg";
import tab_list_active from "../assets/tab_list_on.svg";
import tab_details from "../assets/tab_details_off.svg";
import tab_details_active from "../assets/tab_details_on.svg";
import loop from "../assets/autorenew.svg";
import countries from "../utils/countries.json";

// styling
import "leaflet/dist/leaflet.css";

const center = [55.18, 17.58];

function GetHomeLocation({ callBackSendCoords }) {
  const map = useMap();
  useEffect(() => {
    map.locate().on("locationfound", function (e) {
      callBackSendCoords(e.latlng);
    });
  }, [map]);
  return null;
}

function ShowMap() {
  const data = 0;
  const mapRef = useRef();
  const { width, height } = useWindowDimensions();
  const [fromRouteHome, setFromRouteHome] = useState("");
  const [coordsFromHome, setCoordsFromHome] = useState(null);
  const [widthMapWrapper, setWidthMapWrapper] = useState(100);
  const [displayMapWrapper, setDisplayMapWrapper] = useState("inherit");
  const [mapActive, setMapActive] = useState(true);
  const [poiNearby, setPoiNearby] = useState([]);

  const [activity, setActivity] = useState(null);
  const [isTravelIdea, setIsTravelIdea] = useState(false);
  const [cat, setCat] = useState(0);
  const [act, setAct] = useState(0);
  const [centrePosition, setCentrePosition] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paneA, setPaneA] = useState({ width: 0, display: "none" });
  const [paneB, setPaneB] = useState({ width: 0, display: "none" });
  const [routeResult, setRouteResult] = useState(null);
  const [routeTransitResult, setRouteTransitResult] = useState(null);
  const [myTrips, setMyTrips] = useState(null);
  const [getHome, setGetHome] = useState(false);
  const [coordsHome, setCoordsHome] = useState(null);
  const [request, setRequest] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [newCoords, setNewCoords] = useState(null);
  const [limit, setLimit] = useState(10);
  const [resetAct, setResetAct] = useState(false);
  const [resetCat, setResetCat] = useState(false);
  const [singleLocation, setSingleLocation] = useState(null);
  const [myTripsInfo, setMyTripsInfo] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [maps, setMap] = useState(null);

  const [noPopup, setNoPopup] = useState(false);
  const [countCat, setCountCat] = useState(null);
  const [paneBopen, setPaneBopen] = useState(false);
  const [paneMove, setPaneMove] = useState(false);
  const [dataForPaneB, setDataForPaneB] = useState({});
  const [routeFocus, setRouteFocus] = useState(false);
  const [floatMode, setFloatMode] = useState(false);
  const [closePopup, setClosePopup] = useState(0);
  const [singleZoom, setSingleZoom] = useState(null);
  const [multiZoom, setMultiZoom] = useState(null);
  const [validateGeoQuery, setValidateGeoQuery] = useState("");
  const [countLineMarkers, setCountLineMarkers] = useState(0);
  const [searchMode, setSearchMode] = useState("marker");
  const [resetPoints, setResetPoints] = useState(false);
  const [isOpenFilters, setIsOpenFilters] = useState(false);

  const [KMLInput, setKMLInput] = useState(null);

  useEffect(() => {}, [searchMode]);

  const getSearchMode = useCallback(
    (c) => {
      setSearchMode(c);
    },
    [searchMode]
  );

  useEffect(() => {
    setTimeout(() => maps.invalidateSize({ animate: true, duration: 1, easeLinearity: 1 }), 600);
  }, [maps, isOpenFilters]);

  useEffect(() => {
    if (poiNearby.length !== 0) {
      setLoading(false);
    }

    if (getHome) {
      setLoading(true);
      setRouteResult(null);
    }

    if (activity) {
      localStorage.removeItem("takemethere");
      setIsTravelIdea(true);
    }

    if (localStorage.getItem("takemethere") !== null) {
      var item = JSON.parse(localStorage.getItem("takemethere"));
      setIsTravelIdea(true);

      getSingleLocation(item);
      localStorage.removeItem("takemethere");

      setMyTrips(null);
    } else if (localStorage.getItem("geoResponse") !== null) {
      localStorage.removeItem("tripGroup");
      var item = JSON.parse(localStorage.getItem("geoResponse"));
      localStorage.removeItem("geoResponse");

      if (item.activity) {
        setAct(item.activity);
        setMyTripsInfo({
          group: item.description.group,
          description: item.description.description,
        });
      }

      setCoordsFromHome(item.latLng);
      setCentrePosition(item.latLng);
      setMyTrips(null);
      setFromRouteHome(item.geoString);
      setLoading(true);
    } else if (localStorage.getItem("tripGroup") !== null) {
      var val4 = JSON.parse(localStorage.getItem("tripGroup"));
      setSingleLocation(null);
      setMyTrips(val4.items);
      setMyTripsInfo({ group: val4.group, description: val4.description });
      localStorage.removeItem("tripGroup");
    }
  }, [
    poiNearby,
    activity,
    widthMapWrapper,
    data,
    coordsHome,
    getHome,
    centrePosition,
    paneMove,
    dataForPaneB,
  ]);

  const getPoiNearby = useCallback(
    (c) => {
      setPoiNearby(c);
      if (c.length > 1) {
        setSingleZoom(null);
      }
    },
    [poiNearby]
  );

  const getCountry = (bak, country) => {
    if (country !== null) {
      const newObj = _.filter(bak, { country: country });
      setPoiNearby(newObj);
    } else {
      setPoiNearby(bak);
    }
  };

  const openMap = () => {
    setPaneA({ width: 0, display: "none" });
    setPaneB({ width: 0, display: "none" });
    setDisplayMapWrapper("initial");
    setWidthMapWrapper(100);
    setMapActive(true);
  };

  const openPaneA = () => {
    if (width < 1120) {
      setPaneA({ width: 100, display: "inherit" });
      setPaneB({ width: 0, display: "none" });
      setMapActive(false);
    }
  };

  const openPaneB = () => {
    if (width < 1120) {
      setPaneB({ width: 100, display: "inherit" });
      setPaneA({ width: 0, display: "none" });
      setMapActive(false);
      setPaneBopen(true);
    }
  };

  async function getGeoData2(evt) {
    evt.preventDefault();
    setValidateGeoQuery("");
    setLoading(true);

    const arr = [];
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/geocode`, {
        params: {
          location: request,
        },
      })
      .then((result) => {
        if (result.data.result.length > 1) {
          result.data.result.map((idx, i) => {
            const neighboorhood = idx.adminArea6;
            const city = idx.adminArea5;
            const county = idx.adminArea4;
            const state = idx.adminArea3;
            const country = countries.countries[idx.adminArea1];
            const latLng = idx.latLng;

            arr.push(
              Object.assign(
                {},
                neighboorhood ? { neighboorhood } : null,
                city ? { city } : null,
                county ? { county } : null,
                state ? { state } : null,
                { country },
                { latLng }
              )
            );
          });

          setSearchResults(arr);
        } else if (result.data.result.length === 1) {
          if (result.data.result[0].status === "error") {
            setValidateGeoQuery("This is probably not a place on this planet");
          } else if (result.data.result[0].status === "emptystring") {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/randomlocation`)
              .then((result) => {
                /*  localStorage.setItem(
                  "geoResponse",
                  JSON.stringify({
                    geoString: result.data.geoString,
                    latLng: result.data.latLng,
                    activity: activity !== null ? activity.id : 0,
                    description:
                      activity !== null && activity.description !== null
                        ? activity.description
                        : "",
                  })
                );
                navigate({ pathname: "/map" });*/
              })
              .catch((err) => {});
          } else {
            const { adminArea6, adminArea5, adminArea4, adminArea3, adminArea1, latLng } =
              result.data.result[0];
            const country = countries.countries[adminArea1];

            const neighboorhood = adminArea6;
            const city = adminArea5;
            const county = adminArea4;
            const state = adminArea3;

            getCoords(
              Object.assign(
                {},
                neighboorhood ? { neighboorhood } : null,
                city ? { city } : null,
                county ? { county } : null,
                state ? { state } : null,
                { country },
                { latLng }
              )
            );
          }
        } else {
          setValidateGeoQuery("Sorry, but currently we do not cover places outside Europe");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.error("error: ", err);
      });
  }

  const getCoords = (geoLocation) => {
    const geoString =
      (geoLocation.city ? geoLocation.city + ", " : "") +
      (geoLocation.county ? geoLocation.county + ", " : "") +
      (geoLocation.state ? geoLocation.state + ", " : "") +
      geoLocation.country;

    localStorage.removeItem("geoResponse");
    setNewCoords(geoLocation.latLng);
    setFromRouteHome(geoString);
    setSearchResults(false);
    setCoordsFromHome(geoLocation.latLng);
    setCentrePosition(geoLocation.latLng);
    setMyTrips(null);
    setCoordsHome(null);
  };

  const resetPane = () => {
    setMyTrips(null);
    setSingleLocation(null);
    setCoordsHome(null);
    setIsTravelIdea(null);
    routeResult !== null && setRouteResult(null);
    routeTransitResult !== null && setRouteTransitResult(null);
    setActivity(null);
    setRouteFocus(false);
    setLimit(10);
    localStorage.removeItem("mytrips");
    localStorage.removeItem("tripGroup");
    localStorage.removeItem("itemGeoData");
    localStorage.removeItem("geoResponse");
    setWidthMapWrapper(100);
  };

  const handleFromInput = (evt) => {
    evt.preventDefault();
    setRequest(evt.target.value);
    setFromRouteHome(evt.target.value);
  };

  /*const openLinkItem = () => {
    openPaneB();
  };*/

  const getActivity = (tagid) => {
    if (tagid.id === 0) {
      setResetAct(true);
    }

    setResetAct(false);
    setAct(tagid.id);
    setCat(0);
    setResetCat(true);
  };

  const getCategory = (categoryid) => {
    if (categoryid === 0) {
      setResetCat(true);
    }

    setCat(categoryid);
    setAct(0);
    setResetAct(true);
  };

  const getLimit = () => {

    if (poiNearby.length > 0) {
      setFloatMode(false);
      setLimit(limit === 0 ? 20 : limit + 10);
      setClosePopup(dataForPaneB.travelid);
      setRouteResult(null);
    }

   // setFloatMode(false);
    //setLimit(limit === 0 ? 20 : limit + 10);
   // setNoPopup(true);
   // setRouteResult(null);
   // setClosePopup(dataForPaneB.travelid);
   // openMap();
  };

  const handleFLoatMode = (event) => {
    setFloatMode(event.target.checked);
  };

  const getArticleData = (data) => {
    setPoiNearby(data);
    const newArray = data.map((i) => [i.lat, i.lng]);
    setMultiZoom(data.map((i) => [i.lat, i.lng]));
  };

  const getSingleLocation = (data) => {
    setPoiNearby([data]);

    const obj = {
      travelid: data.id,
      route: [data.lat, data.lng],
      title: data.title,
      mode: "details",
    };

    setDataForPaneB(obj);
    setRouteResult(null);
    setPaneBopen(true);
  };

  const getDetails = (idx) => {};

  const openBFromPopupLink = (idx) => {
    if (idx) {
      setActivity(idx);
      const obj = {
        travelid: idx.id,
        route: [idx.lat, idx.lng],
        title: idx.title,
        mode: "details",
      };
      setDataForPaneB(obj);
      openPaneB();
    }
  };

  const routeInputHeight = () => {
    if (width >= 500) {
      return isOpenFilters ? 80 : 36;
    } else {
      return isOpenFilters ? 125 : 36;
    }
  };

  const containerWrapperHeight = () => {
    if (width >= 500) {
      return {
        height: `calc(100% - ${isOpenFilters ? 255 : 166}px)`,
        zIndex: isOpenFilters ? 1 : 1000,
      };
    } else {
      return {
        height: `calc(100% - ${isOpenFilters ? 239 : 150}px)`,
        zIndex: isOpenFilters ? 1 : 1000,
      };
    }
  };

  const tabStyle = () => {
    const obj = {
      zIndex: isOpenFilters ? 1 : 1010,
    };

    return obj;
  };

  const changeOpacity = () => {
    const obj = { opacity: isOpenFilters ? 10 : 0 };

    return obj;
  };

  const routeInputOverlayHeigt = () => {
    return isOpenFilters ? 0 : 0;
  };

  const totalReset = () => {
    setPoiNearby([]);
    setLimit(0);
  };

  return (
    <>
    
        <Helmet>
          <title>MAP - Silence Spotter</title>
          <meta name="description" content="Advanced search tool for tourist attractions in Europe" />
        </Helmet>
      
      <div className="wrapper-special">
        <div className={"route-input"} style={{ height: `${routeInputHeight()}px` }}>
          <div className={"from-container"}>
            <form id="from-form" onSubmit={getGeoData2}>
              <div
                onClick={() => setGetHome(true)}
                alt="get current location"
                title="get current location"
                className={"from-container-homebtn"}>
                <img src={home} id="center" alt="" />
              </div>

              <input
                id="datafield-from"
                type="text"
                placeholder={"Any destination (in Europe...)"}
                name="request"
                title={fromRouteHome}
                value={fromRouteHome}
                onChange={(e) => handleFromInput(e)}
              />
              {searchResults && (
                <div className={"sugglist sugglist-map"}>
                  {searchResults.map((idx, i) => {
                    return (
                      <div className={"sugglist-items"} key={i} onClick={() => getCoords(idx)}>
                        {idx.neighboorhood}
                        {idx.neighboorhood && ", "}
                        {idx.city}
                        {idx.city && ", "}
                        {idx.county}
                        {idx.county && ", "}
                        {idx.state}
                        {idx.state && ", "}
                        {idx.country}{" "}
                      </div>
                    );
                  })}
                </div>
              )}
              {validateGeoQuery !== "" && (
                <div className="sugglist sugglist-map errormsg">{validateGeoQuery}</div>
              )}
              <input
                id={"searchsubmit"}
                type="image"
                src={search}
                value=""
                alt="search"
                width={20}
                height={20}
              />
              <div
                onClick={() => setIsOpenFilters(isOpenFilters ? false : true)}
                alt="show filters"
                title="show filters"
                className="from-container-openfiltersbtn">
                <img src={filterbtn} id="center" alt="" />
              </div>
            </form>
          </div>
          <div>
            <div>
              <div
                className={isOpenFilters ? "filters-activity filters-close" : "filters-activity"}
                style={changeOpacity()}>
                <SelectActivityList
                  act={act}
                  reset={resetAct}
                  resetBack={() => setResetAct(false)}
                  sendObjToParent={(c) => getActivity(c)}
                />
              </div>
              <div
                className={isOpenFilters ? "filters-category filters-close" : "filters-category"}
                style={changeOpacity()}>
                <SelectCategoryList
                  reset={resetCat}
                  countcategories={countCat}
                  resetBack={() => setResetCat(false)}
                  parentCallback={(childData) => getCategory(childData)}
                />
              </div>
            </div>
          </div>
        </div>
        {width < 1120 && (
          <div className={"tabs"} style={tabStyle()}>
            <div
              className="route-input-overlay"
              style={{ height: `${routeInputOverlayHeigt()}px` }}></div>
            <button
              className={"tstbtn tabbtn"}
              style={{ backgroundColor: mapActive === true ? "#059ED7" : "#C4C4C4" }}
              onClick={() => openMap()}>
              <img src={mapActive === true ? tab_map_active : tab_map} alt="show map" />
            </button>
            {
              <button
                className={"tstbtn tabbtn"}
                style={{ backgroundColor: paneA.width === 100 ? "#059ED7" : "#C4C4C4" }}
                onClick={() => openPaneA()}>
                <img src={paneA.width === 100 ? tab_list_active : tab_list} alt="show list" />
              </button>
            }
            {paneBopen && (
              <button
                className={"tstbtn tabbtn"}
                style={{ backgroundColor: paneB.width === 100 ? "#059ED7" : "#C4C4C4" }}
                onClick={() => openPaneB()}>
                <img
                  src={paneB.width === 100 ? tab_details_active : tab_details}
                  alt="show details"
                />
              </button>
            )}
            <div className={"tools-loadmore"}>
              <button
                className={"tstbtn loadmore radiusleft " + (poiNearby ? "enabled" : "disabled")}
                style={{ backgroundColor: poiNearby.length > 0 ? "#059ed7" : "#eee" }}
                title={
                  poiNearby ? "load 10 more" : "inactive until you make your choice in the map"
                }
                onClick={() => getLimit()}>
                <p>load more</p>
              </button>
              <button
                className={"tstbtn reset-btn radiusright " + (poiNearby ? "enabled" : "disabled")}
                style={{ backgroundColor: poiNearby ? "#059ed7" : "#eee" }}
                title="reset"
                onClick={() => totalReset()}>
                <img src={loop} alt="" width={20} height={20} />
              </button>
            </div>
          </div>
        )}
        <div style={containerWrapperHeight()} className={"container-wrapper"}>
          <div
            className="map-wrapper"
            style={{ display: displayMapWrapper, width: widthMapWrapper + "%" }}>
            {errorMsg && (
              <div className="maploader">
                <div className="error-container">
                  <div className="error-container-close" onClick={() => setErrorMsg(false)}>
                    X
                  </div>
                  <p>{errorMsg}</p>
                </div>
              </div>
            )}
            {loading && (
              <div className="linear-loader">
                <LinearProgress />
              </div>
            )}
            <div className={loading ? "freeze-overlap" : ""}></div>
            <MapContainer
              ref={setMap}
              style={{ height: "100%" }}
              whenCreated={(mapInstance) => {
                mapRef.current = mapInstance;
              }}
              center={center}
              zoom={width >= 990 ? 4 : 3}
              scrollWheelZoom={true}
              zoomControl={false}
              tap={false}>
              <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Streets">
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mark78/clb0xvbhr002j15t0mdh9w96r/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFyazc4IiwiYSI6ImNrcnMzbDBtdjEzN3oydnBmcWY0ODl6a20ifQ._qiRAAbWQfrHsGdoFNjmnA"
                  />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Satellite">
                  <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, &copy; <a href="https://www.mapbox.com/">Mapbox</a>'
                    url="https://api.mapbox.com/styles/v1/mark78/cl1j8oyim002z14qbxemsa2hk/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibWFyazc4IiwiYSI6ImNrcnMzbDBtdjEzN3oydnBmcWY0ODl6a20ifQ._qiRAAbWQfrHsGdoFNjmnA"
                  />
                </LayersControl.BaseLayer>
              </LayersControl>
              <ScaleControl position="bottomright" imperial={false} />
              <ZoomControl position="bottomright" />
              <LocationMarker
                pois={poiNearby}
                coordsHome={coordsHome}
                selectedMarker={dataForPaneB && dataForPaneB.travelid}
                coordsFromHomePage={coordsFromHome}
                coordsFromMytrips={myTrips}
                category={cat}
                activity={act}
                limit={limit}
                noPopup={noPopup}
                closePopup={closePopup}
                floatMode={floatMode}
                searchMode={searchMode}
                parentCallback={(c) => getPoiNearby(c)}
                sendDetailsToParent={(c) => getDetails(c)}
                sendPositionToParent={(c) => setCentrePosition(c)}
                sendFromToParent={(c) => setFromRouteHome(c)}
                sendHomeStateToParent={(c) => setGetHome(false)}
                sendLimitToParent={(limit) => setLimit(limit)}
                sendLinkIdToParent={() => openPaneB()}
                loading={(c) => setLoading(c)}
                reset={() => resetPane()}
                closePopupCB={(c) => setNoPopup(false)}
                countLineMarkers={(c) => setCountLineMarkers(c)}
                openPane={(c) => openBFromPopupLink(c)}
                errorMsg={(c) => {
                  setErrorMsg(c);
                }}
              />
              {routeResult ? (
                <RenderRouteMap centreposition={centrePosition} routeresult={routeResult} />
              ) : null}
              {routeTransitResult ? (
                <RenderRouteTransitMap
                  centreposition={centrePosition}
                  routeresult={routeTransitResult}
                />
              ) : null}
              {getHome && <GetHomeLocation callBackSendCoords={(c) => setCoordsHome(c)} />}
              {myTrips && poiNearby ? <CenterPoiBoundsTrip map={maps} poi={poiNearby} /> : null}
            </MapContainer>
            <SearchTools
              searchmode={(c) => getSearchMode(c)}
              resetPoints={(c) => setResetPoints(c)}
            />
            <div className="drawtools-ttips">
              <DrawToolTip
                drawMode={searchMode}
                markers={countLineMarkers}
                errorMsg={errorMsg}
                result={poiNearby.length}
                kmlFileInput={(c) => setKMLInput(c)}
              />
            </div>
            {poiNearby !== null && (
              <StyledFormControlLabel
                control={
                  <FloatSwitch
                    checked={floatMode}
                    onChange={handleFLoatMode}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
              />
            )}
            {
              <Focus
                map={maps && maps}
                data={poiNearby}
                center={centrePosition}
                singleZoom={singleZoom}
              />
            }
          </div>
          <div
            className={"pane-wrapper paneA"}
            style={{ display: paneA.display, width: paneA.width + "%" }}>
            <div className={"pane-header"}>
              <div className={"pane-header-title"}>
                <span>{poiNearby.length}</span> {`LOCATION${poiNearby.length === 1 ? "" : "S"}`}
              </div>
            </div>
            <div className={"pane-content"}>
              <PaneA
                itemData={poiNearby && poiNearby}
                getActivity={dataForPaneB.travelid}
                tripInfo={myTripsInfo}
                limit={limit}
                width={width}
                act={act}
                centrePosition={centrePosition}
                sendCatToParent={(cat) => setCat(cat)}
                sendActToParent={(act) => setAct(act)}
                sendCountryToParent={(bak, country) => getCountry(bak, country)}
                sendLimitToParent={(limit) => setLimit(limit)}
                sendLinkIdToParent={() => openPaneB()}
                sendSingleLocationToParent={getSingleLocation}
                sendDataToPaneB={(c) => setDataForPaneB(c)}
                countCat={(c) => setCountCat(c)}
                closePopupCB={(c) => setNoPopup(c)}
                closeRoute={() => setRouteResult(null)}
                singleZoom={(c) => setSingleZoom(c)}
                multiZoom={(c) => setMultiZoom(c)}
                sendArticleToParent={(c) => getArticleData(c)}
              />
            </div>
          </div>
          <div
            className={"pane-wrapper paneB"}
            style={{ display: paneB.display, width: paneB.width + "%" }}>
            <PaneB
              centrePos={centrePosition}
              centreGeostring={fromRouteHome}
              activity={activity}
              dataForPaneB={dataForPaneB}
              routeCB={(c) => setRouteResult(c)}
              routeTransitCB={(c) => setRouteTransitResult(c)}
              routeFocus={(c) => setRouteFocus(c)}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(ShowMap);
