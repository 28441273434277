// Dependencies
import { useState, useEffect } from "react";
import axios from "axios";

// Material ui
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";

// js pages
import BootstrapDialogTitle from "./BootstrapDialogTitle";

export default function EditTripModal({ openRemote, user, parentCallBack, refetchData }) {
  const [open, setOpen] = useState(false);
  const [username, setUsername] = useState(user.username);
  const [email, setEmail] = useState(user.email);

  useEffect(() => {
     openRemote && setOpen(true);
  }, [openRemote, user]);

  const handleClose = () => {
    parentCallBack();
    return null;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const body = { username: username, email: email };

    const updateRes = await axios({
      method: "PUT",
      url: `/api/users-permissions/users/me`,
      data: body,
    });

    if (updateRes.status === 200) {
      refetchData();
      parentCallBack();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Edit profile
      </BootstrapDialogTitle>
      <DialogContent>
        <label>
          <h4>Username</h4>
        </label>
        <input value={username} type="text" onChange={(e) => setUsername(e.target.value)} />
        {/*isEmpty && <p style={{color : 'red'}}>Your name cannot be left blank</p> */}
        <label>
          <h4>Email</h4>
        </label>
        <input value={email} type="text" onChange={(e) => setEmail(e.target.value)} />
        <DialogActions>
          <button className="tstbtn medium radiusall" onClick={handleClose}>
            Cancel
          </button>
          <button className="tstbtn medium radiusall" onClick={handleSubmit}>
            Update
          </button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
