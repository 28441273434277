import { gql } from "@apollo/client";

const QUERY_HOWITWORKS = gql`
query Howitworks {
  howitwork {
    data {
      attributes {
        title
        image {
          data {
            attributes {
              url
              formats
            }
          }
        }
      }
    }
  }
}
`


export default QUERY_HOWITWORKS;


