import { useState, memo } from "react";
import gql from "graphql-tag";
import axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import { useQuery } from "@apollo/react-hooks";

const getAddressFromGeoCode = async (id, lat, lng) => {
  const getRes = await axios.get(`${process.env.REACT_APP_API_URL}/api/getandstoregeocode`, {
    params: {
      id: id,
      lat: lat,
      lng: lng,
    },
  });

  return getRes;
};

const ONETRIPDATA_QUERY = gql`
  query Tripdatum($id: ID!) {
    tripdatum(id: $id) {
      data {
        id
        attributes {
          title
          Slug
          xid
          content
          translated_content
          street
          postalCode
          City
          country
          lat
          lon
          categories {
            data {
              attributes {
                category
                category_sin
              }
            }
          }
          image {
            data {
              attributes {
                url
                formats
              }
            }
          }
          memories(filters: { publish: { eq: true } }) {
            data {
              id
              attributes {
                title
                comment
                enjoyment
                date
                createdAt
                users_permissions_user {
                  data {
                    id
                    attributes {
                      username
                    }
                  }
                }
              }
            }
          }
          silentFactor
          enjoymentFactor
          url
          wikipedia
          summitpost
          tags {
            data {
              attributes {
                tag
              }
            }
          }
        }
      }
    }
  }
`;

const OneTrip = ({ children, id }) => {

  /*if (id === "") {
    id = "all";
  }*/

  const { data, loading, error } = useQuery(ONETRIPDATA_QUERY, {
    variables: { id: id },
  });

  if (loading)
    return (
      <div style={{ marginTop: "-17px" }}>
        <LinearProgress />
      </div>
    );
  if (error) return <p>Error: {JSON.stringify(error)}</p>;

  return children({ data });
};

export default memo(OneTrip);
