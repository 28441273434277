import gql from "graphql-tag";

const QUERY_SINGLELOCATION = gql`
query Tripdata($Slug: StringFilterInput) {
  tripdata(filters: { Slug: $Slug }) {
    data {
      id
      attributes {
        title
        Slug
        xid
        content
        translated_content
        lat
        lon
        url
        height   
        wikilink        
        wikipedia
        summitpost
        country
        categories {
          data {
            attributes {
              category
              category_sin
              category_uid
            }
          }
        }
        image {
          data {
            attributes {
              url
              formats
            }
          }
        }
        memories (filters: {publish: { eq: true}}) {
          data {
            id
            attributes {
              title
              comment
              enjoyment
              date
              createdAt
              users_permissions_user {
                data {
                  id
                  attributes {
                    username
                  }
                }
              }
            }
          }
        }
        silentFactor
        enjoymentFactor
      }
    }
  }
}
`



export default QUERY_SINGLELOCATION;