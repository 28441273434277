import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
//import _ from "lodash";
import axios from "axios";

// material ui
import { useCurrentUser } from "../component/CurrentUser";
import EditTripModal from "../modals/EditTripModal";
import EditEventModal from "../modals/EditEventModal";

// icons
import markerIcon from "../assets/marker.svg";
import markerHoverIcon from "../assets/marker_hover.svg";
import editIcon from "../assets/settings.svg";
import editHoverIcon from "../assets/settings_hover.svg";
import deleteIcon from "../assets/delete.svg";
import deleteHoverIcon from "../assets/delete_hover.svg";
import dropDownIcon from "../assets/arrow_drop_down.svg";
import dropDownHoverIcon from "../assets/arrow_drop_down_hover.svg";
import dropUpIcon from "../assets/arrow_drop_up.svg";
import dropUpHoverIcon from "../assets/arrow_drop_up_hover.svg";

export default function FutureTrips({ data, refetchData }) {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const [openEditTrip, setOpenEditTrip] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);
  const [openItemsTrips, setOpenItemsTrips] = useState(null);

  const popDownItemsFutureTrips = (id) => {
    if (openItemsTrips !== id) {
      setOpenItemsTrips(id);
    } else {
      setOpenItemsTrips(null);
    }
  };

  const getDate = (date) => {
    if (date !== null) {
      const d = new Date(date);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const displayDate = d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
      return displayDate;
    } else return null;
  };

  const showTripOnMap = (group, description, data) => {
    var idArr = [];
    data.forEach((idx) => {
      idArr.push(idx.tripdatum.id);
    });

	const sendObj = { group: group, description: description, items: idArr };
    localStorage.setItem("tripGroup", JSON.stringify(sendObj));
    localStorage.removeItem("itemGeoData");
    localStorage.removeItem("geoResponse");
    navigate({ pathname: "/map" });
  };

  const deleteTrip = async (tripid) => {
    const deleteRes = await axios.delete(`/api/trip/deletetrip/${tripid}`);
    if (deleteRes.status === 200) {
      refetchData();
    }
  };

  const deleteEvent = async (eventid) => {
    const deleteRes = await axios.delete(`/api/myevents/deleteevent/${eventid}`);
    if (deleteRes.status === 200) {
      refetchData();
    }
  };

  return (
    <div>
      <div className="db-container-header">
        <h3>TRAVEL BUCKET LIST</h3>
      </div>
      <div className="db-container-content">
        {data &&
          data.map((idx, i) => {
            return (
              <div style={{ position: "relative" }} key={i}>
                <div className={"popupbtns"}>
                  {openItemsTrips !== i ? (
                    <div onClick={() => popDownItemsFutureTrips(i)}>
                      {idx.events.length > 0 && (
                        <img
                          src={dropDownIcon}
                          alt="items down"
                          width={15}
                          height={8}
                          onMouseOver={(e) => (e.currentTarget.src = dropDownHoverIcon)}
                          onMouseOut={(e) => (e.currentTarget.src = dropDownIcon)}
                        />
                      )}{" "}
                    </div>
                  ) : (
                    <div onClick={() => popDownItemsFutureTrips(i)}>
                      {idx.events.length > 0 && (
                        <img
                          src={dropUpIcon}
                          alt="items up"
                          width={15}
                          height={8}
                          onMouseOver={(e) => (e.currentTarget.src = dropUpHoverIcon)}
                          onMouseOut={(e) => (e.currentTarget.src = dropUpIcon)}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="db-trip" key={i}>
                  <div className="db-trip-header">
                    <h4 onClick={() => popDownItemsFutureTrips(i)}>
                      {idx.group}
                      <span className="db-trip-title-postfix">({idx.events.length} events)</span>
                    </h4>
                    <p className="db-trip-date">
                      {getDate(idx.from)} - {getDate(idx.to)}
                    </p>
                    <p className="db-trip-description">{idx.description}</p>
                    <div className="btn-group-trips">
                      <img
                        src={markerIcon}
                        alt="show on map"
                        onClick={() => showTripOnMap(idx.group, idx.description, idx.events)}
                        width={25}
                        height={25}
                        title={"show on map"}
                        onMouseOver={(e) => (e.currentTarget.src = markerHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = markerIcon)}
                      />
                      <img
                        src={editIcon}
                        onClick={() => setOpenEditTrip(i)}
                        width={25}
                        height={25}
                        alt={"edit"}
                        title={"edit"}
                        onMouseOver={(e) => (e.currentTarget.src = editHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = editIcon)}
                      />
                      <img
                        src={deleteIcon}
                        alt="delete trip"
                        onClick={() => {
                          if (window.confirm("Are you sure you wish to delete this trip?"))
                            deleteTrip(idx.id);
                        }}
                        width={25}
                        height={25}
                        onMouseOver={(e) => (e.currentTarget.src = deleteHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = deleteIcon)}
                      />
                    </div>
                  </div>
                  {openEditTrip === i && (
                    <EditTripModal
                      openEditRemote={true}
                      tripId={idx.id}
                      tripGroup={idx.group}
                      tripDesc={idx.description}
                      fromDateOld={idx.from}
                      toDateOld={idx.to}
                      userID={user.id}
                      sendOpenState={() => setOpenEditTrip(null)}
                      refetchData={() => refetchData()}
                    />
                  )}
                  {openItemsTrips === i &&
                    idx.events.map((idx, i) => {
                      return (
                        <div className="trip-locations" key={i}>
                          <div className="trip-locations-header">
                            {idx.title && <h4>{idx.title}</h4>}
                            <p className="db-trip-date">{getDate(idx.date)}</p>
                            {idx.description && <p>{idx.description}</p>}
                            <div className="btn-group-events">
                              <img
                                src={editIcon}
                                onClick={() => setActiveEvent(i)}
                                width={20}
                                height={20}
                                alt={"edit event"}
                                title={"edit event"}
                                onMouseOver={(e) => (e.currentTarget.src = editHoverIcon)}
                                onMouseOut={(e) => (e.currentTarget.src = editIcon)}
                              />
                              <img
                                src={deleteIcon}
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "Are you sure you wish to delete this event? This cannot be undone!"
                                    )
                                  )
                                    deleteEvent(idx.id);
                                }}
                                width={20}
                                height={20}
                                alt={"delete event"}
                                title={"delete event"}
                                onMouseOver={(e) => (e.currentTarget.src = deleteHoverIcon)}
                                onMouseOut={(e) => (e.currentTarget.src = deleteIcon)}
                              />
                            </div>
                            {activeEvent === i && (
                              <EditEventModal
                                openEditRemote={true}
                                eventId={idx.id}
                                eventTitle={idx.title}
                                locationTitle={idx.tripdatum.title}
                                memory={idx.tripdatum.memories}
                                eventDesc={idx.description}
                                dateOld={idx.date}
                                userID={user.id}
                                sendOpenState={() => setActiveEvent(null)}
                                refetchData={() => refetchData()}
                              />
                            )}
                          </div>
                          {idx.tripdatum && (
                            <div>
                              <div className="trip-location-container">
                                <div className="trip-location-img">
                                  {idx.tripdatum.image !== null && (
                                    <Link to={`/article/${idx.tripdatum.Slug}`}>
                                      <img
                                        src={`${idx.tripdatum.image.formats.thumbnail.url}`}
                                        alt={idx.tripdatum.title}
                                        title={idx.tripdatum.title}
                                      />
                                    </Link>
                                  )}
                                </div>
                                <div className="trip-locations-content">
                                  <Link to={`/article/${idx.tripdatum.Slug}`}>
                                    <h4>{idx.tripdatum.title}</h4>
                                  </Link>
                                  <p className="trip-location-category">
                                    {idx.tripdatum.categories[0].category_sin}
                                  </p>
                                  <p className="trip-location-country">{idx.tripdatum.country}</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
