// Dependencies
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import  { Helmet }  from "react-helmet";
import axios from "axios";

// js pages
import SelectActivityList from "./SelectActivityList";
import FpSummary2 from "../public_pages/FPSummary";
import FPHelp from "../public_pages/FPHelp";
import Footer from "../public_pages/Footer";
import FadeInImage from "../public_pages/FadeinImage";
import countries from "../utils/countries.json";
import Counting from "../public_pages/Counting";

// styling
import "../index.css";

import overlayImg from "../assets/summary-bg.png";

export default function Home() {
  // init
  const navigate = useNavigate();
  const contentTop = useRef();
  const [request, setRequest] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [activity, setActivity] = useState(null);
  const [resetAct, setResetAct] = useState(false);
  const [tag, setTag] = useState(null);
  const [count, setCount] = useState(0);
  const [validateGeoQuery, setValidateGeoQuery] = useState("");

  useEffect(() => {
    /*axios
      .get(`${process.env.REACT_APP_API_URL}/api/locations/count`)
      .then((result) => {
        setCount(result.status === 200 ? result.data : 28894);
      })
      .catch((error) => {
        console.log("an error occured");
      });*/
  }, []);

  useEffect(() => {
    localStorage.removeItem("itemGeoData");
  }, []);

  async function getGeoData(evt) {
    evt.preventDefault();

    const arr = [];
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/geocode`, {
        params: {
          location: request,
        },
      })
      .then((result) => {
        if (result.data.result.length > 1) {
          result.data.result.map((idx, i) => {
            const neighboorhood = idx.adminArea6;
            const city = idx.adminArea5;
            const county = idx.adminArea4;
            const state = idx.adminArea3;
            const country = countries.countries[idx.adminArea1];
            const latLng = idx.latLng;

            arr.push(
              Object.assign(
                {},
                neighboorhood ? { neighboorhood } : null,
                city ? { city } : null,
                county ? { county } : null,
                state ? { state } : null,
                { country },
                { latLng }
              )
            );
          });

          setSearchResults(arr);
        } else if (result.data.result.length === 1) {
          if (result.data.result[0].status === "error") {
            setValidateGeoQuery(
              "This is probably not a place on this planet. Could there be a typo in it?"
            );
          } else if (result.data.result[0].status === "emptystring") {
            axios
              .get(`${process.env.REACT_APP_API_URL}/api/randomlocation`)
              .then((result) => {
                localStorage.setItem(
                  "geoResponse",
                  JSON.stringify({
                    geoString: result.data.geoString,
                    latLng: result.data.latLng,
                    activity: activity !== null ? activity.id : 0,
                    description:
                      activity !== null && activity.description !== null
                        ? activity.description
                        : "",
                  })
                );
                navigate({ pathname: "/map" });
              })
              .catch((err) => {});
          } else {
            const { adminArea6, adminArea5, adminArea4, adminArea3, adminArea1, latLng } =
              result.data.result[0];
            const country = countries.countries[adminArea1];

            const neighboorhood = adminArea6;
            const city = adminArea5;
            const county = adminArea4;
            const state = adminArea3;

            heroDispatchToMap(
              Object.assign(
                {},
                neighboorhood ? { neighboorhood } : null,
                city ? { city } : null,
                county ? { county } : null,
                state ? { state } : null,
                { country },
                { latLng }
              )
            );
          }
        } else {
          setValidateGeoQuery("Sorry, but currently we do not cover places outside Europe");
        }
        // }
      })
      .catch((err) => {
        console.error("error: ", err);
      });
  }

  const heroDispatchToMap = async (geoLocation) => {
    const geoString =
      (geoLocation.city ? geoLocation.city + ", " : "") +
      (geoLocation.county ? geoLocation.county + ", " : "") +
      (geoLocation.state ? geoLocation.state + ", " : "") +
      geoLocation.country;

    localStorage.setItem(
      "geoResponse",
      JSON.stringify({
        geoString: geoString,
        latLng: geoLocation.latLng,
        activity: activity !== null ? activity.id : 0,
        description: activity !== null && activity.description !== null ? activity.description : "",
      })
    );

    navigate({ pathname: "/map" });
  };

  const getActivity = (tagid) => {
    if (tagid === 0) {
      setResetAct(true);
      setTag(null);
    }
    setActivity(tagid);
  };

  return (
    <>
      <Helmet>
        <title>Silence Spotter</title>
        <meta name="description" content="Crowd free destinations for independent travelers" /> 
      </Helmet>
      <div className={"wrapper-container"}>
        <div className="wrapper">
          <div className="hero-wrapper" id="mainhero">
            <div className="hero-form">
              <div className="route-container">
                <form id="hero-form-container" onSubmit={getGeoData}>
                  <div className="hero-inputactivity">
                    <h2 id="head-2">WHAT TO DO</h2>
                    <SelectActivityList
                      reset={resetAct}
                      sendObjToParent={(childData) => getActivity(childData)}
                      sendDescToParent={(c) => setTag(c)}
                      resetBack={() => setResetAct(false)}
                      styling={"StyledSelectBoxHero"}
                    />
                  </div>
                  <div className="hero-inputplace">
                    <h2 id="head-2">ANYWHERE</h2>
                    <input
                      id={"heroinputfield"}
                      type="text"
                      placeholder="any destination (in Europe)..."
                      name="request"
                      onChange={(e) => setRequest(e.target.value)}
                    />
                    {searchResults && (
                      <div className={"sugglist"}>
                        {searchResults.map((idx, i) => {
                          return (
                            <div
                              className={"sugglist-items"}
                              key={i}
                              onClick={() => heroDispatchToMap(idx)}>
                              {idx.neighboorhood}
                              {idx.neighboorhood && ", "}
                              {idx.city}
                              {idx.city && ", "}
                              {idx.county}
                              {idx.county && ", "}
                              {idx.state}
                              {idx.state && ", "}
                              {idx.country}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="hero-inputsubmit">
                    <input id={"herosubmit"} type="submit" value="FIND" />
                  </div>
                </form>
                <p>{validateGeoQuery}</p>
              </div>
            </div>
            <FadeInImage />
          </div>
          <div className="wide">
            <div style={{ float: "left", clear: "both" }} ref={contentTop}>
              &nbsp;
            </div>       
            <div id="whatabout">         
              <div className={"small summary-container"}>
                <div style={{ position: 'absolute'}}><img src={overlayImg} width="100%" height="100%"/></div>
                <FpSummary2 />
              </div>      
            </div>
            <div id="highlights">{/* <Highlights />*/}</div>
            <div id="counting">
              <Counting count={count} />
            </div>
            <div id="FPHelp">
              <div className="page-content small help-features">
                <FPHelp />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-wrapper">
          <Footer />
        </div>
      </div>
    </>
  );
}
