import React from "react";
import { useQuery } from "@apollo/react-hooks";

const Query = ({ children, query, xid, Slug }) => {
	const { data, loading, error } = useQuery(query, {
		variables: { Slug: { "contains": Slug} }
	});

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error: {JSON.stringify(error)}</p>;

//console.log("data: " + JSON.stringify(data))

  return children({data})
}

      export default Query;

