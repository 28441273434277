// Dependencies
import Helmet from 'react-helmet';

export default function SEO({title}) {

	return (
		 <Helmet>
        <title>{title}</title>
        <meta name={title} />
        </Helmet> 
		)
}