// Dependencies
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { subscribeUser } from "@strapi-newsletter/react";

// styling
import "../index.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import profilepic from "../assets/profilePic.png";
import quotes from "../assets/quotes.svg";

export default function FPHelp() {
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState(null);
  const navigate = useNavigate();
  const linkTo = (link) => {
    navigate(`/${link}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email === "") {
      await subscribeUser(email, `${process.env.REACT_APP_API_URL}`)
        .then((result) => {
          if (result) {
            setMsg("Email address added! Thank you!");
          } else {
            setMsg("Something went wrong..");
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          setMsg("Something went wrong..");
        });
    } else {
      setMsg("Please, enter a valid email address");
    }
  };

  return (
    <div>
      <div className={"fp-help-container"}>
        <div className={"fp-help-intro"}>
          <img src={quotes} width="33" height="24" />
          <p>
            Arriving in a destination unknown to you can be a challenge. Have you read the Lonely Planet from A to Z or searched the internet for hours and know exactly what to do? Or are you someone like
            me who, despite of being quickly overstimulated, still finds himself following the
            masses and randomly doing touristy things?
          </p>
          <p>
            For independent travelers who recognize this problem I created Silence Spotter, a travel
            guide with a unique approach for finding things to do or to see around your destination.
          </p>
          <p>           
            On the assumption that this app will contribute to a pleasant travel experience, I wish
            you good luck on your next journey!
          </p>
          <p  style={{ paddingTop: '20px',  paddingBottom: 0 }}  className="signature">Mark</p>
            <p style={{ paddingTop: 0 }} className="signature"><em>from Markov Media</em></p>

      
        </div>

        <div className="fp-help-container-large">
          <div className={"fp-help-item"}>
            <h3>Funding</h3>
            <p>
              This app is ad free and has no 'sponsored links'. We survive solely from kind
              donations. If you love what we do, consider donating the price of a beer or coffee to
              support us!
            </p>
            <button onClick={() => linkTo("donate")} className="tstbtn fphlp-btn radiusall">
              <p>Donate</p>
              <span>
                <FontAwesomeIcon className="icon-solid" icon={faCaretRight}></FontAwesomeIcon>
              </span>
            </button>
          </div>
          <div className={"fp-help-item"}>
            <h3>Newsletter</h3>
            <p>
              Once in a while we let you know what's happening here, for instance when we release
              new exciting features!
            </p>
            <form onSubmit={handleSubmit}>
              <input
                style={{ border: "none", height: "41px" }}
                type="email"
                name="email address"
                placeholder="Email Address"
                onChange={(e) => setEmail(e.target.value)}
              />
              {msg && <h5 style={{ color: "#e8e50e" }}>{msg}</h5>}
              <input id="fp-help-btn" type="submit" value="Subscribe" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
