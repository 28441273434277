import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";

import axios from "axios";

// material ui
import { useCurrentUser } from "../component/CurrentUser";
import EditTripModal from "../modals/EditTripModal";
import EditEventModal from "../modals/EditEventModal";
import CreateMemoryModal from "../modals/CreateMemoryModal";
import EditMemoryModal from "../modals/EditMemoryModal";

// icons
import markerIcon from "../assets/marker.svg";
import markerHoverIcon from "../assets/marker_hover.svg";
import editIcon from "../assets/settings.svg";
import editHoverIcon from "../assets/settings_hover.svg";
import deleteIcon from "../assets/delete.svg";
import deleteHoverIcon from "../assets/delete_hover.svg";
import dropDownIcon from "../assets/arrow_drop_down.svg";
import dropDownHoverIcon from "../assets/arrow_drop_down_hover.svg";
import dropUpIcon from "../assets/arrow_drop_up.svg";
import dropUpHoverIcon from "../assets/arrow_drop_up_hover.svg";


export default function PastTrips({ data, refetchData }) {
  const user = useCurrentUser();
  const navigate = useNavigate();

  const [activeEditMemory, setActiveEditMemory] = useState(null);
  const [openEditPastTrip, setOpenEditPastTrip] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);
  const [openItemsPastTrips, setOpenItemsPastTrips] = useState(null);
  const [activeMemory, setActiveMemory] = useState(null);

  const popDownItemsPastTrips = (id) => {
    if (openItemsPastTrips !== id) {
      setOpenItemsPastTrips(id);
    } else {
      setOpenItemsPastTrips(null);
    }
  };

  const getDate = (date) => {
    if (date !== null) {
      const d = new Date(date);
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const displayDate = d.getDate() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
      return displayDate;
    } else return null;
  };

  const showTripOnMap = (group, description, data) => {

//console.log('data showTripOnMap: ', data)

    var idArr = [];
    data.forEach((idx) => {
      idArr.push(idx.tripdatum.id);
    });

    const sendObj = { group: group, description: description, items: idArr };
    localStorage.setItem("tripGroup", JSON.stringify(sendObj));
    localStorage.removeItem("itemGeoData");
    localStorage.removeItem("geoResponse");
    navigate({ pathname: "/map" });
  };

  const deleteTrip = async (tripid) => {
    const deleteRes = await axios.delete(`/api/trip/deletetrip/${tripid}`);
    if (deleteRes.status === 200) {
      refetchData();
    }
  };

  const deleteEvent = async (eventid) => {
    const deleteRes = await axios.delete(`/api/myevents/deleteevent/${eventid}`);
    if (deleteRes.status === 200) {
      refetchData();
    }
  };

  return (
    <div>
      <div className="db-container-header">
        <h3>TRAVEL HISTORY</h3>
      </div>
      <div className="db-container-content">
        {data &&
          data.map((idx, i) => {
            return (
              <div style={{ position: "relative" }} key={i}>
                <div className={"popupbtns"}>
                  {openItemsPastTrips !== i ? (
                    <div onClick={() => popDownItemsPastTrips(i)}>
                      {idx.events.length > 0 && (
                        <img
                          src={dropDownIcon}
                          alt="items down"
                          width={15}
                          height={8}
                          onMouseOver={(e) => (e.currentTarget.src = dropDownHoverIcon)}
                          onMouseOut={(e) => (e.currentTarget.src = dropDownIcon)}
                        />
                      )}{" "}
                    </div>
                  ) : (
                    <div onClick={() => popDownItemsPastTrips(i)}>
                      {idx.events.length > 0 && (
                        <img
                          src={dropUpIcon}
                          alt="items up"
                          width={15}
                          height={8}
                          onMouseOver={(e) => (e.currentTarget.src = dropUpHoverIcon)}
                          onMouseOut={(e) => (e.currentTarget.src = dropUpIcon)}
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="db-trip" key={i}>
                  <div className="db-trip-header">
                    <h4 onClick={() => popDownItemsPastTrips(i)}>
                      {idx.group}
                      <span className="db-trip-title-postfix">({idx.events.length} events)</span>
                    </h4>
                    <p className="db-trip-date">
                      {getDate(idx.from)} - {getDate(idx.to)}
                    </p>
                    <p className="db-trip-description">{idx.description}</p>
                    <div className="btn-group-trips">
                      {idx.events.length !== 0 && (
                        <img
                          src={markerIcon}
                          onClick={() => showTripOnMap(idx.group, idx.description, idx.events)}
                          width={25}
                          height={25}
                          alt={"show on map"}
                          title={"show on map"}
                          onMouseOver={(e) => (e.currentTarget.src = markerHoverIcon)}
                          onMouseOut={(e) => (e.currentTarget.src = markerIcon)}
                        />
                      )}
                      <img
                        src={editIcon}
                        onClick={() => setOpenEditPastTrip(i)}
                        width={25}
                        height={25}
                        alt={"edit trip"}
                        title={"edit trip"}
                        onMouseOver={(e) => (e.currentTarget.src = editHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = editIcon)}
                      />
                      <img
                        src={deleteIcon}
                        onClick={() => {
                          if (
                            window.confirm(
                              "Are you sure you wish to delete this trip? This cannot be undone!"
                            )
                          )
                            deleteTrip(idx.id);
                        }}
                        width={25}
                        height={25}
                        alt={"delete trip"}
                        title={"delete trip"}
                        onMouseOver={(e) => (e.currentTarget.src = deleteHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = deleteIcon)}
                      />
                    </div>
                  </div>
                  {openEditPastTrip === i && (
                    <EditTripModal
                      openEditRemote={true}
                      tripId={idx.id}
                      tripGroup={idx.group}
                      tripDesc={idx.description}
                      fromDateOld={idx.from}
                      toDateOld={idx.to}
                      userID={user.id}
                      sendOpenState={() => setOpenEditPastTrip(null)}
                      refetchData={() => refetchData()}
                    />
                  )}
                  <div className="db-trip-content">
                    {openItemsPastTrips === i &&
                      idx.events.map((idx, i) => {
                        return (
                          <div className="trip-locations" key={i}>
                            <div className="trip-locations-header">
                              {idx.title && <h4>{idx.title}</h4>}
                              <p className="db-trip-date">{getDate(idx.date)}</p>
                              {idx.description && <p>{idx.description}</p>}
                              <div className="btn-group-events">
                                <img
                                  src={editIcon}
                                  onClick={() => setActiveEvent(i)}
                                  width={20}
                                  height={20}
                                  alt={"edit event"}
                                  title={"edit event"}
                                  onMouseOver={(e) => (e.currentTarget.src = editHoverIcon)}
                                  onMouseOut={(e) => (e.currentTarget.src = editIcon)}
                                />
                                <img
                                  src={deleteIcon}
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Are you sure you wish to delete this event? This cannot be undone!"
                                      )
                                    )
                                      deleteEvent(idx.id);
                                  }}
                                  width={20}
                                  height={20}
                                  alt={"delete event"}
                                  title={"delete event"}
                                  onMouseOver={(e) => (e.currentTarget.src = deleteHoverIcon)}
                                  onMouseOut={(e) => (e.currentTarget.src = deleteIcon)}
                                />
                              </div>
                              {activeEvent === i && (
                                <EditEventModal
                                  openEditRemote={true}
                                  eventId={idx.id}
                                  eventTitle={idx.title}
                                  locationTitle={idx.tripdatum.title}
                                  memory={idx.tripdatum.memories}
                                  eventDesc={idx.description}
                                  dateOld={idx.date}
                                  userID={user.id}
                                  sendOpenState={() => setActiveEvent(null)}
                                  refetchData={() => refetchData()}
                                />
                              )}
                              {activeMemory === i && (
                                <CreateMemoryModal
                                  tripDataId={idx.tripdatum.id}
                                  openRemote={true}
                                  parentCallback={() => setActiveMemory(null)}
                                  refetchData={() => refetchData()}
                                />
                              )}
                              {activeEditMemory === i && (
                                <EditMemoryModal
                                  openRemote={true}
                                  tripDataId={idx.tripdatum.id}
                                  userID={user.id}
                                  memoryId={idx.tripdatum.memories[0].id}
                                  oldTitle={idx.tripdatum.memories[0].title}
                                  oldComment={idx.tripdatum.memories[0].comment}
                                  oldDate={idx.date}
                                  publishState={idx.tripdatum.memories[0].publish}
                                  parentCallback={() => setActiveEditMemory(null)}
                                  refetchData={() => refetchData()}
                                />
                              )}
                            </div>
                            {idx.tripdatum && (
                              <div>
                                <div className="trip-location-container">
                                  <div className="trip-location-img">
                                    {idx.tripdatum.image !== null && (
                                      <Link to={`/article/${idx.tripdatum.Slug}`}>
                                        <img
                                          src={`${idx.tripdatum.image.formats.thumbnail.url}`}
                                          alt={idx.tripdatum.title}
                                          title={idx.tripdatum.title}
                                        />
                                      </Link>
                                    )}
                                  </div>
                                  <div className="trip-locations-content">
                                    <Link to={`/article/${idx.tripdatum.Slug}`}>
                                      <h4>{idx.tripdatum.title}</h4>
                                    </Link>
                                    <p className="trip-location-category">
                                      {idx.tripdatum.categories[0].category_sin}
                                    </p>
                                    <p className="trip-location-country">{idx.tripdatum.country}</p>
                                  </div>
                                </div>
                                {idx.tripdatum.memories.length === 0 ? (
                                  <p className="memory-button" onClick={() => setActiveMemory(i)}>
                                    Make memory
                                  </p>
                                ) : (
                                  <p
                                    className="memory-button"
                                    onClick={() => setActiveEditMemory(i)}>
                                    Edit memory
                                  </p>
                                )}

                                {idx.tripdatum.memories.length !== 0 && (
                                  <div className="trip-locations-memory">
                                    <div>
                                      <ul className="taglist">
                                        {idx.tripdatum.memories[0].title &&
                                          idx.tripdatum.memories[0].title
                                            .split(", ")
                                            .map((idx, i) => {
                                              return <li key={i}>{idx}</li>;
                                            })}
                                      </ul>
                                    </div>
                                    <div>
                                      <p>{idx.tripdatum.memories[0].comment}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
