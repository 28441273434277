// Dependencies
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// js pages
import EditProfileModal from "../modals/EditProfileModal";
import { useCurrentUser } from "../component/CurrentUser";
import Footer from "../public_pages/Footer";

// icons
import editIcon from "../assets/settings.svg";
import editHoverIcon from "../assets/settings_hover.svg";

export default function UserProfile(props) {
  // init
  const user = useCurrentUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState(null);

  const refetchData = async () => {
    const res = await axios.get("/api/users/me");
    setUserData(res.data);
    console.log("res.data: ", res.data);
  };

  useEffect(() => {
    refetchData();
  }, [user]);

  const handleClick = () => {
    navigate("/forgotpassword");
  };

  return (
    <div className="wrapper-container">
      <div className="wrapper">
        <div className="page-content small">
          <div className="top-section">
            <h3>My profile</h3>
            <img
              src={editIcon}
              onClick={() => setOpen(true)}
              width={25}
              height={25}
              alt={"edit"}
              title={"edit"}
              onMouseOver={(e) => (e.currentTarget.src = editHoverIcon)}
              onMouseOut={(e) => (e.currentTarget.src = editIcon)}
            />
            {open && (
              <EditProfileModal
                openRemote={open}
                user={userData}
                parentCallBack={() => setOpen(false)}
                refetchData={() => refetchData()}
              />
            )}
            <div className="db-full-width-container">
              <div className="db-container-content">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <p>
                          <strong>Username</strong>
                        </p>
                      </td>
                      <td>
                        <p>{userData && userData.username}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Email</strong>
                        </p>
                      </td>
                      <td>
                        <p>{userData && userData.email}</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>
                          <strong>Password</strong>
                        </p>
                      </td>
                      <td>
                        <button onClick={handleClick}>Reset password</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-wrapper">
          <Footer />
        </div>
      </div>
    </div>
  );
}
