// Dependencies
import { useState, useEffect } from "react";
import Select from "react-select";
import { useQuery } from "@apollo/react-hooks";

// JS pages
import StyledSelectBox from "../styles/StyledSelectBoxMap";

// Queries
import QUERY_CATEGORYGROUPS from "../queries/QUERY_CATEGORYGROUPS";

export default function SelectCategory({ reset, parentCallback, resetBack, countcategories }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [catList, setCatList] = useState(null);
  const [selectedOptionNoCount, setSelectedOptionNoCount] = useState(null);
  const { data, loading, error } = useQuery(QUERY_CATEGORYGROUPS);

  useEffect(() => {
    const SetCategoryGroups = () => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error: {JSON.stringify(error)}</p>;

      const labelMaker = (array) => {
        var catArray = [];
        for (var i = 0; i < array.length; i++) {
          const currentCat = array[i].attributes.category;

          if (countcategories && countcategories.hasOwnProperty(currentCat)) {
            var count = countcategories[currentCat];
          } else {
            count = 0;
          }

          catArray.push({
            value: array[i].id,
            label: (
              <p style={{ fontSize: "14px", padding: "2px" }}>
                {array[i].attributes.category}
                <span
                  style={{ fontSize: "11px", marginLeft: "5px", paddingTop: "5px", color: "red" }}>
                  {currentCat == "libraries" && "(beta)"}
                </span>
                <span style={{ float: "right" }}>{count !== 0 ? ` (` + count + `)` : ""}</span>
              </p>
            ),
            selectedValue: array[i].attributes.category,
            id: array[i].id,
          });
        }
        return catArray;
      };

      var allCategories = [];
      data.categoryGroups.data.map((idx) => {
        return allCategories.push({
          label: idx.attributes.group,
          options: labelMaker(idx.attributes.categories.data),
        });
      });

      setCatList(allCategories);
    };

    SetCategoryGroups();
  }, [data, selectedOption, countcategories, error, loading]);

  useEffect(() => {
    if (reset === true) {
      setSelectedOption(null);
      setSelectedOptionNoCount(null);
    }
  }, [reset]);

  const handleInputChange = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedOption(selectedOption);
      parentCallback(selectedOption.id);
      setSelectedOptionNoCount({ label: selectedOption.selectedValue });
      resetBack(false);
    } else {
      parentCallback(0);
    }
  };

  return (
    <Select
      value={selectedOptionNoCount}
      isClearable
      placeholder={"any category..."}
      isSearchable={false}
      options={catList}
      onChange={handleInputChange}
      styles={StyledSelectBox}
    />
  );
}
