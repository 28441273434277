// Dependencies
import { useRef, useState } from "react";

export default function ForgotPassword() {
  const emailRef = useRef();
  const [errorMsg, setErrorMsg] = useState(null);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const bodyData = {
      email: emailRef.current.value,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();

      if (data.data !== null) {
        // setLoading(false);
        setHasSubmitted(true);
      } else {
        setErrorMsg(data.error.message);
      }
    } catch (error) {
      //setLoading(false);
      setErrorMsg(error.message);
    }
  };

  return (
    <div className="wrapper login-wrapper">
      <div className="auth-wrapper register">
        {!hasSubmitted ? (
          <form onSubmit={handleSubmit}>
            <fieldset id="sign_up">
              <legend>
                <h4>
                  <strong>Restore your password</strong>
                </h4>
              </legend>
              <div style={{ marginBottom: "14px" }}>
                <input
                  ref={emailRef}
                  type="email"
                  name="email-address"
                  placeholder="Email"
                  id="email-address"
                  required
                />
              </div>
            </fieldset>
            {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
            <div>
              <input id={"registersubmit"} type="submit" value="Send" />
            </div>
            <div></div>
          </form>
        ) : (
          <p style={{ textAlign: "center" }}>
            <strong>An email has just been sent to restore your password</strong>
          </p>
        )}
      </div>
    </div>
  );
}
