// Dependencies

import { useQuery } from "@apollo/react-hooks";

// js pages
import DonateButton from '../component/DonateButton';
import Footer from './Footer';
import Helmet from "react-helmet";

// queries
import QUERY_DONATE from '../queries/QUERY_DONATE';

const Query = ({ children, query }) => {
  const { data, loading, error } = useQuery(query);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({data})
}

export default function Donate() {
 return (
  <Query query={QUERY_DONATE}>
  {({ data: { donate } }) => {
const { title, image } = donate.data.attributes;

    return (
      <>
      <Helmet>
          <title>Donate - Silence Spotter</title>         
        <meta name="description" content="Consider a small donation" /> 
        </Helmet>
      <div className={'wrapper-container'} >
      <div id="single-article" className={'wrapper'}>   
      <div className="wrapper-img" style={{background: `#fff url(${image.data.attributes.url}) no-repeat center`,  backgroundSize: 'cover'}}>   
      </div>
      <div className="page-container">
      <div className="page-content vsmall">
      <div className="item-title"><h1>{title}</h1></div>
      <div className={'item-wrapper'}>     
      <p style={{ fontWeight: 700}}>Silence Spotter is completely free of charge and has no revenue strategies other than this Donate button.</p>
      &nbsp;
      <p style={{ fontWeight: 700}}>If you find this app useful, consider donating a small amount to appreciate the hard work that is put into building, maintaining and improving Silence Spotter and to cover the immense costs of keeping this app online.</p>
      </div>   
      <DonateButton/>
      </div>
      </div>
      </div>
      <div className="footer-wrapper"><Footer/></div>  
      </div>
      </>  
      ) 
  }}
  </Query>
  )
};

