import React, { useEffect, useState } from "react";

// Js Pages
import generateKML from "./helpers/generateKML";
import generateKMLtransit from "./helpers/generateKMLtransit";
import { generateRoute, generateRouteTransit } from "./restQueries/generateRoutes";

// Assets
import dropDownIcon from "../assets/arrow_drop_down.svg";
import dropDownHoverIcon from "../assets/arrow_drop_down_hover.svg";
import dropUpIcon from "../assets/arrow_drop_up.svg";
import dropUpHoverIcon from "../assets/arrow_drop_up_hover.svg";

import carIcon from "../assets/transport_car.svg";
import bicycleIcon from "../assets/transport_bicycle.svg";
import walkIcon from "../assets/transport_pedestrian.svg";
import transitIcon from "../assets/transport_pt.svg";

import routeZoomIconInactive from "../assets/route-zoom.svg";
import routePdfIconInactive from "../assets/route-pdf.svg";
import routeDownloadInactiveIcon from "../assets/route-download.svg";

import routeZoomIcon from "../assets/route-zoom-active.svg";
import routeDownloadIcon from "../assets/route-download-active.svg";

import lengthIcon from "../assets/length.svg";
import durationIcon from "../assets/duration.svg";

export default function PaneRouteTab({
  centrePos,
  centreGeostring,
  dataForPaneB,
  routeCB,
  routeTransitCB,
  routeFocus,
}) {
  const [route, setRoute] = useState(null);
  const [routeTransit, setRouteTransit] = useState(null);
  const [transport, setTransport] = useState(null);
  const [displayTransitActions, setDisplayTransitActions] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");

  useEffect(() => {
    if (dataForPaneB) {
      if (
        dataForPaneB.mode === "car" ||
        dataForPaneB.mode === "bicycle" ||
        dataForPaneB.mode === "pedestrian" ||
        dataForPaneB.mode === "transit"
      ) {
        getRoute([dataForPaneB.route[0], dataForPaneB.route[1]], dataForPaneB.mode);
      } else if (dataForPaneB.mode === "details") {
        setRoute(null);
        setTransport(null);
      }
    }
  }, [dataForPaneB]);

  const getRoute = (idx, transport) => {
    setTransport(transport);
    if (centrePos === null) {
      return null;
    }
    generateRoute(centrePos, idx, transport)
      .then((result) => {
        if (result.data.status !== 404) {
          routeCB(result.data);
          setRoute(result.data);
          routeFocus(true);
          routeTransitCB(null);
          setRouteTransit(null);
        } else {
          console.log("cannot find route");
        }
      })
      .catch((err) => {
        console.log("there was an error: ", err);
      });
  };

  const getRouteTransit = (idx) => {
    setTransport("transit");

    if (centrePos === null) {
      return null;
    }

    generateRouteTransit(centrePos, idx)
      .then((result) => {
        if (result.data.data.length !== 0) {
          routeCB(null);
          routeTransitCB(result.data);
          setRouteTransit(result.data);
          console.log("result.data routeTransit: ", result.data);
          setRoute(null);
        } else {
          setErrorMsg("sorry, no route available");
          //routeCB(null);
          setRoute(null);
          console.log("cannot find route");
        }
      })
      .catch((err) => {
        console.log("there was an error: ", err);
      });
  };

  const polylineSegments = (segment) => {
    var color = "";
    switch (segment) {
      case "pedestrian":
        color = "#ffffff";
        break;
      case "regionalTrain":
        color = "red";
        break;
      case "highSpeedTrain":
        color = "red";
        break;
      case "cityTrain":
        color = "red";
        break;
      case "intercityTrain":
        color = "red";
        break;
      case "bus":
        color = "blue";
        break;
      default:
        color = "black";
    }
    return color;
  };

  const transportMode = (mode, name) => {
    var transportText = "";

    switch (mode) {
      case "pedestrian":
        transportText = "";

        break;
      case "regionalTrain":
        transportText = "Regional train ";
        break;
      case "highSpeedTrain":
        transportText = "High speed train " + name;
        break;
      case "cityTrain":
        transportText = "Commuter rail " + name;
        break;
      case "intercityTrain":
        transportText = "Intercity " + name;
        break;
      case "bus":
        transportText = "Bus " + name;
        break;
      default:
        transportText = mode;
    }

    return transportText;
  };

  const departurePlace = (type, name) => {
    var typeText = "";
    var nameText = "";

    switch (type) {
      case "place":
        typeText = "Depart from: ";
        nameText = centreGeostring;
        break;
      case "accessPoint":
        typeText = "via: ";
        nameText = name;
        break;
      default:
        nameText = name;
    }

    return typeText + nameText;
  };

  const dateConversion = (d) => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    var convDate = new Date(d);

    const displayDate =
      convDate.getDate() +
      " " +
      monthNames[convDate.getMonth()] +
      " " +
      convDate.getFullYear() +
      " - " +
      convDate.getHours() +
      ":" +
      convDate.getMinutes();

    return displayDate;
  };

  const timeConversion = (d, a) => {
    var departureStr = new Date(d);
    var arrivalStr = new Date(a);
    return (arrivalStr.getTime() - departureStr.getTime()) / 60000;
  };

  const openTransitActions = (i) => {
    const found = displayTransitActions.find((element) => element === i);
    if (found === undefined) {
      setDisplayTransitActions((oldArray) => [...oldArray, i]);
    }
    if (found !== undefined) {
      setDisplayTransitActions(displayTransitActions.filter((item) => item !== i));
    }
  };

  const convertDuration = (t) => {
    var time = t / 60;
    if (time > 60) {
      return (time / 60).toFixed(1) + "hours";
    } else {
      return time.toFixed(1) + "min";
    }
  };

  return (
    <div>
      <div className="pane-content-route-toolbar">
        <div className="pane-content-route-toolbar-left">
          <div
            className="transport-select-btns"
            style={{ backgroundColor: transport === "car" ? "#504F4F" : "#EFE9E9" }}
            onClick={() => getRoute([dataForPaneB.route[0], dataForPaneB.route[1]], "car")}>
            <img src={carIcon} height={30} width={30} alt={"Car"} title={"Car"} />
          </div>
          <div
            className="transport-select-btns"
            style={{ backgroundColor: transport === "bicycle" ? "#504F4F" : "#EFE9E9" }}
            onClick={() => getRoute([dataForPaneB.route[0], dataForPaneB.route[1]], "bicycle")}>
            <img src={bicycleIcon} height={30} width={30} alt={"Bicycle"} title={"Bicycle"} />
          </div>
          <div
            className="transport-select-btns"
            style={{ backgroundColor: transport === "pedestrian" ? "#504F4F" : "#EFE9E9" }}
            onClick={() => getRoute([dataForPaneB.route[0], dataForPaneB.route[1]], "pedestrian")}>
            <img src={walkIcon} height={30} width={30} alt={"Pedestrian"} title={"Pedestrian"} />
          </div>
          <div
            className="transport-select-btns"
            style={{ backgroundColor: transport === "transit" ? "#504F4F" : "#EFE9E9" }}
            onClick={() => getRouteTransit([dataForPaneB.route[0], dataForPaneB.route[1]])}>
            <img
              src={transitIcon}
              height={30}
              width={30}
              alt={"Public Transport"}
              title={"Public Transport"}
            />
          </div>
        </div>
        {route || routeTransit ? (
          <div className="pane-content-route-toolbar-right">
           
            <div
              className="transport-select-btns"
              onClick={() => {
                route
                  ? generateKML(
                      centreGeostring,
                      route.departure,
                      dataForPaneB.title,
                      route.arrival,
                      route.polyline.polyline,
                      transport
                    )
                  : generateKMLtransit(
                      centreGeostring,
                      routeTransit.departure,
                      dataForPaneB.title,
                      routeTransit.arrival,
                      routeTransit.data
                    );
              }}>
              <img
                src={routeDownloadIcon}
                height={30}
                width={30}
                alt={"Download as KML"}
                title={"Download as KML"}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="pane-content-route-toolbar-right">
             
              <div className="transport-select-btns">
                <img
                  src={routeDownloadInactiveIcon}
                  height={30}
                  width={30}
                  alt={"First generate a route to use this function"}
                  title={"First generate a route to use this function"}
                />
              </div>
            </div>
            <div style={{ clear: "both", paddingTop: "19px" }}>
              <div className="notification">Choose your way of transportation...</div>
            </div>
          </div>
        )}
      </div>

      <div>
        {route && (
          <div>
            <div className="dir-info">
              <div className="dir-info-length">
                <img src={lengthIcon} height={26} width={26} alt={"Length"} title={"Length"} />{" "}
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "4px 0 0 6px",
                    color: "#025f02",
                  }}>
                  {" "}
                  {(route.summary.length / 1000).toFixed(1)}km
                </div>
              </div>
              <div className="dir-info-duration">
                <img
                  src={durationIcon}
                  height={26}
                  width={26}
                  alt={"Duration"}
                  title={"Duration"}
                />
                <div
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    padding: "4px 0 0 6px",
                    color: "#025f02",
                  }}>
                  {" "}
                  {convertDuration(route.summary.duration)}
                </div>
              </div>
            </div>
            <div className="dir-list">
              {route.actions.map((idx, i) => {
                return (
                  <div key={i} className="dir-item">
                    {i !== route.actions.length - 1 && <div className="dir-strokeV"></div>}

                    <div className="dir-strokeH"></div>
                    <p className="dir-instruction">{idx.instruction}</p>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {errorMsg !== "" ? <div className="error-msg">{errorMsg}</div> : null}
        {routeTransit && (
          <div>
            <div className="dir-list">
              <div style={{ fontWeight: "bold" }}>{centreGeostring}</div>
              <div>{dateConversion(routeTransit.data[0].departureSections.time)}</div>
              {routeTransit.data.map((idx, i) => {
                return (
                  <div key={i} className="dir-item-pt">
                    {
                      <div>
                        <div className="dir-strokeH-top-pt">
                          <div
                            style={{
                              backgroundColor: polylineSegments(idx.transport.mode),
                            }}
                            className="dir-bullet-top-pt"></div>
                          <div className="dir-bullet-top-inner-pt"></div>
                        </div>
                        <div className="dir-strokeH-bottom-pt">
                          <div
                            style={{
                              backgroundColor: polylineSegments(idx.transport.mode),
                            }}
                            className="dir-bullet-bottom-pt"></div>
                          <div className="dir-bullet-bottom-inner-pt"></div>
                        </div>
                      </div>
                    }
                    <div
                      style={{ backgroundColor: polylineSegments(idx.transport.mode) }}
                      className={idx.transport.mode !== "pedestrian" ? "dir-strokeV-pt" : ""}></div>

                    <div className="dir-instruction-depart" style={{ fontWeight: "bold" }}>
                      {idx.transport.mode !== "pedestrian" ? (
                        <div>
                          <div className="dir-instruction-depart-text">
                            {departurePlace(
                              idx.departureSections.place.type,
                              idx.departureSections.place.name
                            )}
                          </div>
                          <div className="dir-instruction-depart-time">
                            {idx.departureSections.time.substring(11, 16)}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div style={{ color: "#917b05" }} className="dir-instruction-depart-text">
                            {!idx.spans && idx.transport.mode === "pedestrian" ? (
                              <div>
                                <div style={{ float: "left" }}>
                                  walk{" "}
                                  {timeConversion(
                                    idx.departureSections.time,
                                    idx.arrivalSections.time
                                  )}
                                  &nbsp;min
                                </div>{" "}
                                {idx.actions && idx.actions.length > 0 ? (
                                  <div
                                    className="dir-instruction-arrow"
                                    onClick={() => openTransitActions(i)}>
                                    {displayTransitActions.includes(i) ? (
                                      <img
                                        src={dropUpIcon}
                                        alt="items down"
                                        width={15}
                                        height={8}
                                        onMouseOver={(e) => (e.currentTarget.src = dropUpHoverIcon)}
                                        onMouseOut={(e) => (e.currentTarget.src = dropUpIcon)}
                                      />
                                    ) : (
                                      <img
                                        src={dropDownIcon}
                                        alt="items down"
                                        width={15}
                                        height={8}
                                        onMouseOver={(e) =>
                                          (e.currentTarget.src = dropDownHoverIcon)
                                        }
                                        onMouseOut={(e) => (e.currentTarget.src = dropDownIcon)}
                                      />
                                    )}
                                  </div>
                                ) : null}
                              </div>
                            ) : null}

                            {idx.spans && idx.transport.mode === "pedestrian" ? (
                              <div>
                                walk {idx.spans[0].walkAttributes}
                                {timeConversion(
                                  idx.departureSections.time,
                                  idx.arrivalSections.time
                                )}{" "}
                                min
                              </div>
                            ) : null}
                          </div>

                          <div className="dir-instruction-depart-time"></div>
                        </div>
                      )}
                    </div>

                    {idx.transport.mode !== "pedestrian" ? (
                      <div className="dir-instruction-transport">
                        {transportMode(idx.transport.mode, idx.transport.name)}
                      </div>
                    ) : null}

                    {idx.departureSections.place.platform && (
                      <p style={{ fontSize: "12px" }} className="dir-instruction">
                        Platform: {idx.departureSections.place.platform}
                      </p>
                    )}

                    {idx.actions &&
                    idx.transport.mode === "pedestrian" &&
                    displayTransitActions.includes(i)
                      ? idx.actions.map((item, j) => {
                          return (
                            <div key={j} className="dir-instruction">
                              <p style={{ fontSize: "13px" }}>{item.instruction}</p>
                            </div>
                          );
                        })
                      : null}

                    <div className="dir-instruction-arrival" style={{ fontWeight: "bold" }}>
                      {idx.transport.mode !== "pedestrian" ? (
                        <div>
                          <div className="dir-instruction-arrival-text">
                            {idx.arrivalSections.place.name}
                          </div>
                          <div className="dir-instruction-arrival-time">
                            {idx.arrivalSections.time.substring(11, 16)}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
              <div style={{ fontWeight: "bold" }}>{dataForPaneB.title}</div>
              <div>
                {dateConversion(
                  routeTransit.data[routeTransit.data.length - 1].arrivalSections.time
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
