// Dependencies
import { useEffect, useRef, useState } from "react";
import { useCurrentUser } from "./CurrentUser";
import { useNavigate } from "react-router-dom";
import SEO from "../component/SEO";

// Material UI
import CircularProgress from "@mui/material/CircularProgress";

// Queries
//import CREATE_AVATAR_ITEM from '../queries/createAvatarItem'

export default function Register() {
  //const dispatch = useDispatchCurrentUser();
  const currentUser = useCurrentUser();
  const usernameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasRegistered, setHasRegistered] = useState(false);
  const [newUser, setNewUser] = useState(null);

  useEffect(() => {
    if (currentUser.isAuthenticated) {
      navigate("/user");
    }
  });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const password = passwordRef.current.value;
    const confirmPassword = passwordConfirmRef.current.value;

    if (password !== confirmPassword) {
      setErrorMsg("passwords do not match");
      setLoading(false);
      return null;
    }

    const bodyData = {
      username: usernameRef.current.value,
      email: emailRef.current.value,
      password: passwordRef.current.value,
    };

    console.log("body: ", bodyData);

    try {
      const response = await fetch(`/api/auth/local/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(bodyData),
      });

      const data = await response.json();
      console.log("data: ", data);

      if (data.user) {
        setNewUser(data.user);
        setHasRegistered(true);
        console.log("check your email");
      } else {
        setErrorMsg(data.message);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMsg(error.message);
      console.log("err: " + JSON.stringify(error.message));
    }
  };

  return (
	<>
	      <SEO title={'Register'}/>
    <div className="wrapper login-wrapper">
      {loading && (
        <div className="maploader">
          <div className={"maploader-inner"}>
            <CircularProgress style={{ color: "white" }} />
          </div>
        </div>
      )}
      <div className="auth-wrapper register">
        {!hasRegistered ? (
          <form onSubmit={handleSubmit}>
            <fieldset id="sign_up">
              <legend>
                <h4>
                  <strong>Welcome! SIGN UP for an account</strong>
                </h4>
              </legend>
              <div style={{ marginBottom: "14px" }}>
                <input
                  ref={usernameRef}
                  type="text"
                  name="username"
                  placeholder="Username"
                  id="username"
                  required
                />
              </div>
              <div style={{ marginBottom: "14px" }}>
                <input
                  ref={emailRef}
                  type="email"
                  name="email-address"
                  placeholder="Email"
                  id="email-address"
                  required
                />
              </div>
              <div className="mv3" style={{ marginBottom: "14px" }}>
                <input
                  ref={passwordRef}
                  type="password"
                  name="password"
                  placeholder="Password"
                  id="password"
                  required
                />
              </div>
              <div className="mv3" style={{ marginBottom: "14px" }}>
                <input
                  ref={passwordConfirmRef}
                  type="password"
                  name="confirmpassword"
                  placeholder="Confirm Password"
                  id="confirmpassword"
                  required
                />
              </div>
            </fieldset>
            {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
            <div>
              <input id={"registersubmit"} type="submit" value="Register" />
            </div>
            <div></div>
          </form>
        ) : (
          <div style={{ textAlign: "center" }}>
            <h3>
              <strong>Thank you for your registration!</strong>
            </h3>
            <p>Please confirm using the email we've sent to {newUser.email}.</p>
          </div>
        )}
      </div>
    </div>
	</>
  );
}
