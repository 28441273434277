import React, { useEffect, useRef } from 'react';

const DonateButton = () => {
    const buttonRef = useRef(null);

    useEffect(() => {
        const button = window.PayPal.Donation.Button({
            env:'production',
            hosted_button_id:'5UYD22QKHV7J6',
            image: {
                src:'https://res.cloudinary.com/hebd059oa/image/upload/v1649772236/Paypal-Donate.png_ysed6m.png',
                alt:'Donate with PayPal button',
                title:'PayPal - The safer, easier way to pay online!',
            }
        });

        button.render(`#${buttonRef.current.id}`); 
    }, []);
    return (
        <div ref={buttonRef} id={"donate-button"} />
        );
};

export default DonateButton;