// Dependencies
import { useQuery } from "@apollo/react-hooks";

// js pages
import Footer from '../public_pages/Footer';

// queries
import QUERY_DONATE from '../queries/QUERY_DONATE';

const Query = ({ children, query }) => {
  const { data, loading, error } = useQuery(query);
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {JSON.stringify(error)}</p>;
  return children({data})
}

export default function Donate() {
 return (
  <Query query={QUERY_DONATE}>
  {({ data: { donate } }) => {
    return (
      <>
      <div className={'wrapper-container'} >
      <div id="single-article" className={'wrapper'}>   
      <div className="wrapper-img" style={{background: `#fff url(http://localhost:1337${donate.image.formats.medium.url}) no-repeat center`,  backgroundSize: 'cover'}}  >   > 
      </div>
      <div className="page-container">
      <div className="page-content vsmall">
      <div className="item-title"><h1>Privacy & Cookies</h1></div>
      <div className={'item-wrapper'}>     
      <p>Privacy & Cookies </p>
       </div>   
      </div>
      </div>
      </div>
      <div className="footer-wrapper"><Footer/></div>  
      </div>
      </>  
      ) 
  }}
  </Query>
  )
};

