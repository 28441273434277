import axios from "axios";

export async function generateRoute(centre, idx, transport) {

  const getRes = axios.get(`${process.env.REACT_APP_API_URL}/api/getroute`, {
    params: {
      fromLat: centre.lat,
      fromLng: centre.lng,
      toLat: idx[0],
      toLng: idx[1],
      transport: transport,
    },
  });

  return getRes;
}

export async function generateRouteTransit(centre, idx) {

  const getRes = axios.get(`${process.env.REACT_APP_API_URL}/api/getroutetransit`, {
    params: {
      fromLat: centre.lat,
      fromLng: centre.lng,
      toLat: idx[0],
      toLng: idx[1]
    },
  });

  return getRes;
}
