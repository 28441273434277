const StyledSelectBoxHero = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#fff" : "#000",
    paddingLeft: "30px",
    borderBottom: "1px dotted #eee",
  }),
  control: (provided, state) => ({
    ...provided,
    minHeight: "42px",
    borderBottomLeftRadius: "10px",
    borderTopLeftRadius: "10px",
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    border: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "20px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: "bold",
    fontSize: "19px",
  }),
  groupHeading: (provided, state) => ({
    backgroundColor: "#eee",
    padding: "10px",
    margin: 0,
    fontWeight: "bold",
  }),
};

export default StyledSelectBoxHero;
