// Dependencies
import { useState, useEffect, useRef } from "react";
import _ from "lodash";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import NativeSelect from "@mui/material/NativeSelect";
import Rating from "@mui/material/Rating";
import { Icon } from "@mui/material";

// js pages
import { useCurrentUser } from "./CurrentUser";
import SelectCountryList from "./SelectCountryList";
import SaveToTrip from "./SaveToTrip";
import AutoSuggestTo from "./autoSuggestTo";

// icons
import routeIcon from "../assets/route.svg";
import routeIconHover from "../assets/route-hover.svg";
import zoomIcon from "../assets/zoom.svg";
import zoomIconHover from "../assets/zoom-hover.svg";
import graphIcon from "../assets/graph.svg";
import graphIconHover from "../assets/graph-hover.svg";
import personGreenIcon from "../assets/person_green.svg";
import personOrangeIcon from "../assets/person_orange.svg";
import personRedIcon from "../assets/person_red.svg";
import personEmpty from "../assets/person_empty.svg";
import carIcon from "../assets/transport_car-paneA.svg";
import carHoverIcon from "../assets/transport_car-paneA-hover.svg";
import bicycleIcon from "../assets/transport_bicycle-paneA.svg";
import bicycleHoverIcon from "../assets/transport_bicycle-paneA-hover.svg";
import walkIcon from "../assets/transport_pedestrian-paneA.svg";
import walkHoverIcon from "../assets/transport_pedestrian-paneA-hover.svg";
import transitIcon from "../assets/transport_pt-paneA.svg";
import transitHoverIcon from "../assets/transport_pt-paneA-hover.svg";

const PersonEmpty = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img alt="" src={personEmpty} height={20} width={11} />
  </Icon>
);

const PersonGreen = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img alt="" src={personGreenIcon} height={20} width={11} />
  </Icon>
);

const PersonOrange = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img alt="" src={personOrangeIcon} height={20} width={11} />
  </Icon>
);

const PersonRed = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img alt="" src={personRedIcon} height={20} width={11} />
  </Icon>
);

export default function PaneA(props) {
  // init
  const { slug } = useParams();
  const navigate = useNavigate();
  const user = useCurrentUser();
  const scrollRef = useRef();
  const [open, setOpen] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState(null);
  const [quietFirst, setQuietFirst] = useState(2);
  const [itemData, setItemData] = useState(null);
  const [showTooltip, setShowTooltip] = useState(null);
  const [showTransportBar, setShowTransportBar] = useState(null);
  const [itemDataBak, setItemDataBak] = useState(null);
  const [isCountryFilter, setIsCountryFilter] = useState(false);
  const [paneSwitch, setPaneSwitch] = useState("locations");
  const [viewArticle, setViewArticle] = useState(null);
  const [hasCentre, setHasCentre] = useState(false);

  useEffect(() => {
    setHasCentre(props.centrePosition === null ? false : true);
  }, [props.centrePosition]);

  useEffect(() => {
    if (!isCountryFilter && props.itemData !== null) {
      const itemBak = structuredClone(props.itemData);
      setPaneSwitch("locations");
      setItemDataBak(itemBak);
    }

  }, [props.itemData]);

  useEffect(() => {
    var allCountriesBak = [];
    const count = {};
    if (itemDataBak !== null && props.itemData) {
      itemDataBak.map((idx) => {
        return allCountriesBak.push({ value: idx.country, label: idx.country });
      });

      setCountryList(_.uniqBy(allCountriesBak, "value"));
      const sortData = [...props.itemData];

      if (quietFirst == 1) {
        setItemData(isNotSorted(sortData));
      } else if (quietFirst == 2) {
        setItemData(isSorted(sortData));
      }

      for (const element of props.itemData) {
        if (count[element.category]) {
          count[element.category] += 1;
        } else {
          count[element.category] = 1;
        }
      }
    }
  }, [props.itemData, itemDataBak, quietFirst, props.width, country, props.act]);

  useEffect(() => {
    props.getActivity && itemSelect(props.getActivity);
  }, [props.getActivity, open]);

  const getColNum = () => {
    if (props.width < 650) {
      return "twelve";
    } else if (props.width >= 650 && props.width < 990) {
      return "six";
    } else if (props.width >= 990 && props.width < 1120) {
      return "four";
    } else {
      return "twelve";
    }
  };

  const isSorted = (d) => {
    for (var i = 0; i < d.length; i++) {
      d[i].tempSF = d[i].silent_factor_v_3 === null ? d[i].silent_factor * 2 : d[i].silent_factor_v_3;
    }

    var sorted = d.sort((a, b) => parseFloat(a.tempSF) - parseFloat(b.tempSF));
    // for (var j = 0; j < sorted.length; j++) {}

    return sorted;
  };

  const isNotSorted = (d) => {
    return d;
  };

  const isClicked = (i, mode, zoom) => {
    props.sendDataToPaneB({
      travelid: i.id,
      route: [i.lat, i.lng],
      title: i.title,
      sfInfo: i.silent_factor_json,
      mode: mode,
    });

    if (zoom === true) {
      props.singleZoom([i.lat, i.lng]);
    } else {
      props.singleZoom(null);
    }

    props.sendLinkIdToParent();
    props.closePopupCB(false);
  };

  const handleToInput = (data) => {
    setOpen(data.id);
    props.sendSingleLocationToParent(data);
    setHasCentre(false);
    setViewArticle(null);
    props.singleZoom([data.lat, data.lng]);
    //navigate(`/map/${data.slug}`);
  };

  const isActiveStyle = (i) => {
    var style;
    if (props.getActivity !== null) {
      props.getActivity === i
        ? (style = { border: "1px solid #aaa", backgroundColor: "#e3f1fd" })
        : (style = { border: "1px solid #fff" });
      return style;
    }
  };

  const getCountry = (country) => {
    props.sendCountryToParent(itemDataBak, country);
    setCountry(country);
    setIsCountryFilter(true);
    if (country === null) setIsCountryFilter(false);
  };

  const itemSelect = (id) => {
    setOpen(id);
    if (props.width > 990) {
      if (scrollRef.current !== null && scrollRef.current !== undefined) {
        scrollRef.current.scrollIntoView({ behavior: "auto", block: "start" });
      }
    }
  };

  const checkIfSaved = (id) => {
    var trips = JSON.parse(localStorage.getItem("mytrips"));
    trips = trips ? trips : [];
    const found = trips.find((element) => element == id);
    if (found !== undefined) {
      return true;
    } else {
      return false;
    }
  };

  const crowdinessFilter = (sr) => {
    if (sr < 2) {
      return "You will probably be alone here";
    } else if (sr >= 2 && sr < 4) {
      return "Maybe a few people in the vicinity";
    } else if (sr >= 4 && sr < 6) {
      return "Usually not too crowded";
    } else if (sr >= 6 && sr < 8) {
      return "Expect a lot of people";
    } else if (sr >= 8) {
      return "Could be very crowded!";
    }
  };

  useEffect(() => {
    if (slug !== undefined) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/getonetagonslug/${slug}`)
        .then((response) => {
          setOpen(response.data.id);
          props.sendSingleLocationToParent(response.data);
        })
        .catch((err) => {
          console.error("error: ", err);
        });
    }
  }, [slug]);

  const newestSF = (sf2, sf3) => {
    if (sf3 === null) {
      return Number(sf2.toFixed(1) * 2);
    } else {
      return Number(sf3.toFixed(1));
    }
  };

  const handleNewArticle = ({ title, content, tripdata }) => {
    setViewArticle({ title: title, content: content });
    props.sendArticleToParent(tripdata);
  };

  const items =
    itemData &&
    itemData.map((idx, i) => {

      const image = idx.img_url === null ? idx.url : idx.img_url;
      const imageThumb = idx.img_url_thumb === null ? idx.formats.thumbnail.url : idx.img_url_thumb;
      return (
        <div key={idx.id} style={isActiveStyle(idx.id)} className={getColNum() + " columns card-item"}>
          {open === idx.id && <div style={{ scrollMargin: "20px" }} ref={scrollRef}></div>}
          <div>
            {idx.formats !== undefined && (
              <>
                <div className="image-overlay">
                  <div className="card-item-header-container left">
                    <div className="card-item-header-btn">
                      <SaveToTrip article={idx.id} userID={user.id} isSaved={checkIfSaved(idx.id)} />
                    </div>
                    <div className="card-item-header-btn" id="zoombtn" onClick={() => isClicked(idx, "details", true)}>
                      <img
                        src={zoomIcon}
                        height={38}
                        width={38}
                        alt={"Zoom on map"}
                        title={"Zoom on map"}
                        onMouseOver={(e) => (e.currentTarget.src = zoomIconHover)}
                        onMouseOut={(e) => (e.currentTarget.src = zoomIcon)}
                      />
                    </div>
                    <div
                      className="card-item-header-btn"
                      id="routebtn"
                      onClick={() => setShowTransportBar(idx.id)}
                      onMouseOver={() => setShowTransportBar(idx.id)}
                      onMouseOut={() => setShowTransportBar(null)}
                      data-tip
                      data-for="clickme"
                      data-event="click"
                    >
                      <img
                        src={routeIcon}
                        height={38}
                        width={38}
                        alt={"get route"}
                        title={"get route"}
                        onMouseOver={(e) => (e.currentTarget.src = routeIconHover)}
                        onMouseOut={(e) => (e.currentTarget.src = routeIcon)}
                      />
                    </div>
                  </div>
                  <div className="card-item-header-container right">
                    {/*idx.silent_factor_json !== null && (
                      <div
                        className="card-item-header-btn"
                        id="graphbtn"
                        style={{ display: "inline-block", verticalAlign: "top" }}
                        onClick={() => isClicked(idx, "info", false)}>
                        <img
                          src={graphIcon}
                          height={38}
                          width={38}
                          onMouseOver={(e) => (e.currentTarget.src = graphIconHover)}
                          onMouseOut={(e) => (e.currentTarget.src = graphIcon)}
                        />
                      </div>
                    ) */}
                    <div
                      className="card-item-header-btn"
                      style={{ display: "inline-block", verticalAlign: "top" }}
                      onClick={() => setShowTooltip(idx.id)}
                      onMouseOver={() => setShowTooltip(idx.id)}
                      onMouseOut={() => setShowTooltip(null)}
                      data-tip
                      data-for="clickme"
                      data-event="click"
                    >
                      {idx.silent_factor && (
                        <div className={"s-rate-absolute"}>
                          <Rating
                            name="silent-rating"
                            precision={0.5}
                            value={newestSF(idx.silent_factor, idx.silent_factor_v_3)}
                            readOnly
                            icon={
                              newestSF(idx.silent_factor, idx.silent_factor_v_3) < 4 ? (
                                <PersonGreen />
                              ) : newestSF(idx.silent_factor, idx.silent_factor_v_3) >= 4 &&
                                newestSF(idx.silent_factor, idx.silent_factor_v_3) < 7 ? (
                                <PersonOrange />
                              ) : (
                                <PersonRed />
                              )
                            }
                            emptyIcon={<PersonEmpty />}
                            max={10}
                          />
                          <h6 style={{ color: "#fff" }}>{newestSF(idx.silent_factor, idx.silent_factor_v_3)}</h6>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={showTransportBar === idx.id ? "transportbar active" : "transportbar"}
                    onMouseOver={() => setShowTransportBar(idx.id)}
                    onMouseOut={() => setShowTransportBar(null)}
                  >
                    <div
                      className="card-item-header-sub-btn"
                      onClick={() => isClicked(idx, "car", false)}
                      data-tip
                      data-for="clickme"
                      data-event="click"
                    >
                      <img
                        src={carIcon}
                        height={38}
                        width={38}
                        alt={"get route by car"}
                        title={"get route by car"}
                        onMouseOver={(e) => (e.currentTarget.src = carHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = carIcon)}
                      />
                    </div>
                    <div
                      className="card-item-header-sub-btn"
                      onClick={() => isClicked(idx, "bicycle", false)}
                      data-tip
                      data-for="clickme"
                      data-event="click"
                    >
                      <img
                        src={bicycleIcon}
                        height={38}
                        width={38}
                        alt={"get route by bicycle"}
                        title={"get route by bicycle"}
                        onMouseOver={(e) => (e.currentTarget.src = bicycleHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = bicycleIcon)}
                      />
                    </div>
                    <div
                      className="card-item-header-sub-btn"
                      onClick={() => isClicked(idx, "pedestrian", false)}
                      data-tip
                      data-for="clickme"
                      data-event="click"
                    >
                      <img
                        src={walkIcon}
                        height={38}
                        width={38}
                        alt={"get route by foot"}
                        title={"get route by foot"}
                        onMouseOver={(e) => (e.currentTarget.src = walkHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = walkIcon)}
                      />
                    </div>
                    <div
                      className="card-item-header-sub-btn"
                      onClick={() => isClicked(idx, "transit", false)}
                      data-tip
                      data-for="clickme"
                      data-event="click"
                    >
                      <img
                        src={transitIcon}
                        height={38}
                        width={38}
                        alt={"get route by public transport"}
                        title={"get route by public transport"}
                        onMouseOver={(e) => (e.currentTarget.src = transitHoverIcon)}
                        onMouseOut={(e) => (e.currentTarget.src = transitIcon)}
                      />
                    </div>
                  </div>

                  <div
                    className={showTooltip === idx.id ? "sr-tooltip active" : "sr-tooltip"}
                    onMouseOver={() => setShowTooltip(idx.id)}
                    onMouseOut={() => setShowTooltip(null)}
                  >
                    <div style={{ float: "left", margin: "10px" }}>
                      <strong>{crowdinessFilter(newestSF(idx.silent_factor, idx.silent_factor_v_3))}</strong>
                    </div>
                    <div className="sfversion" style={{ float: "right", margin: "10px" }}>
                      {idx.silent_factor_v_3 == null ? <span>V2</span> : <span>V3</span>}
                      <span className="sfversion-tooltip">
                        Silence factor version. A higher number indicates a more accurate rating.
                      </span>
                    </div>
                    <p style={{ clear: "both" }}>
                      This factor is a rough overall indication of how quiet or crowded this place is expected to be
                      year round. Read more about the silence rating&nbsp;
                      <Link to="/howitworks">here</Link>.
                    </p>
                  </div>
                </div>
                <div className="card-item-image">
                  {
                    <div onClick={() => isClicked(idx, "details", false)}>
                      {open === idx.id ? (
                        <img
                          className="card-item-img-cover"
                          src={image}
                          height={350}
                          alt={idx.title}
                        />
                      ) : (
                        <img
                          className="card-item-img-cover"
                          src={imageThumb}
                          height={140}
                          alt={idx.title}
                        />
                      )}
                    </div>
                  }
                </div>
              </>
            )}
          </div>
          <div className="card-item-content">
            <a href="#">
              <h5 onClick={() => isClicked(idx, "details", false)}>
                <strong>
                  {idx.title}&nbsp;<span>{idx.height && `(${idx.height}m)`}</span>
                </strong>
              </h5>
            </a>
            <h6>{idx.category_sin}</h6>
          </div>
        </div>
      );
    });

  return (
    <div>
      <div className={"pane-tools"}>
        <AutoSuggestTo reset={itemData} parentCallbackTo={(c) => handleToInput(c)} />
      </div>

      <div className={"renderlist"}>
        {itemData && hasCentre ? (
          <div>
            {countryList.length > 1 ? (
              <div style={{ marginBottom: "10px" }}>
                <label>country</label>
                <SelectCountryList countries={countryList} parentCallback={(childData) => getCountry(childData)} />
              </div>
            ) : null}
            {!props.groupTitle && props.itemData.length !== 0 ? (
              <div className={"tool-section"}>
                <div className={"tool-section-left"}>
                  <NativeSelect
                    defaultValue={2}
                    onChange={(e) => setQuietFirst(e.target.value)}
                    inputProps={{
                      name: "order",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={2}>Most Quiet First</option>
                    <option value={1}>Nearest First</option>
                  </NativeSelect>
                </div>
              </div>
            ) : null}
            <div className={"tool-section"}></div>
          </div>
        ) : null}
        {props.tripInfo.group && props.itemData && (
          <div>
            <h4>
              <strong>{props.tripInfo.group.charAt(0).toUpperCase() + props.tripInfo.group.slice(1)}</strong>
            </h4>
            {props.tripInfo.description && (
              <h4>
                <em>{props.tripInfo.description}</em>
              </h4>
            )}
          </div>
        )}
        {props.itemData.length !== 0 ? items : null}
      </div>
    </div>
  );
}
