function Counting({ count }) {
  return (
    <div>
      <h1>
        { /* Choose from <span className="count-value">{count && count}</span> */ }
       Choose from <span className="count-value">63768</span>
        <span className="count-variable">locations</span> in <span className="count-value">45</span>
        <span className="count-variable"> countries</span>
      </h1>
      <p>and counting...</p>
    </div>
  );
}

export default Counting;
