// Dependencies
import { useState, useEffect } from "react";
import Select from "react-select";
import { useQuery } from "@apollo/react-hooks";

// JS pages
import StyledSelectBoxHero from "../styles/StyledSelectBoxHero";
import StyledSelectBoxMap from "../styles/StyledSelectBoxMap";

// Queries
import QUERY_TAGGROUPS from "../queries/QUERY_TAGGROUPS";

export default function SelectActivity({ reset, act, resetBack, sendObjToParent, styling }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [tagList, setTagList] = useState(null);
  const { data, loading, error } = useQuery(QUERY_TAGGROUPS);

  useEffect(() => {
    if (act) {
      setSelectedOption(getActLabel(act));
    }

    const SetTagGroups = () => {
      if (loading) return <p>Loading...</p>;
      if (error) return <p>Error: {JSON.stringify(error)}</p>;

      const labelMaker = (array) => {
        var tagArray = [];
        for (var i = 0; i < array.length; i++) {
          tagArray.push({
            value: array[i].id,
            label: array[i].attributes.tag,
            id: array[i].id,
            description: array[i].attributes.description,
          });
        }

        return tagArray;
      };

      var allTags = [];
      data.tagGroups.data.map((idx) => {
        return allTags.push({
          label: idx.attributes.group,
          options: labelMaker(idx.attributes.tags.data),
        });
      });

      setTagList(allTags);
    };

    SetTagGroups();
  }, [data, act, error, loading]);

  useEffect(() => {
    if (reset === true) {
      setSelectedOption(null);
    }
  }, [reset]);

  const getActLabel = (id) => {
    var tagArray = []; 
    for (var i = 0; i < data.tagGroups.data.length; i++) {
      var tagGroupArr = data.tagGroups.data[i].attributes.tags.data;
      tagArray = tagArray.concat(tagGroupArr);
    }
    const found = tagArray.find((element) => element.id === id);
    const selectedTag = {
      value: found.id,
      label: found.attributes.tag,
      id: found.id,
    };

    return selectedTag;
  };

  const handleInputChange = (selectedOption) => {
    if (selectedOption !== null) {
      setSelectedOption(selectedOption);

      sendObjToParent({
        id: selectedOption.value,
        group: selectedOption.label,
        description: selectedOption.description,
      });

      resetBack(false);
    } else {
      sendObjToParent({ id: 0, description: null });
      setSelectedOption(null);
    }
  };

  return (
    <Select
      value={selectedOption}
      isClearable
      placeholder={"any activity..."}
      isSearchable={false}
      options={tagList}
      onChange={handleInputChange}
      styles={styling === "StyledSelectBoxHero" ? StyledSelectBoxHero : StyledSelectBoxMap}
    />
  );
}
