// Dependencies
import { useRef, useState } from "react";

export default function ResetPassword() {
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const [errorMsg, setErrorMsg] = useState(null);
  //const [loading, setLoading] = useState(false);
  const [hasReset, setHasReset] = useState(false);

  const handleSubmit = async (e) => {
   // setLoading(true);
    e.preventDefault();

    var code = window.location.search.slice(6);
    const password = passwordRef.current.value;
    const confirmPassword = passwordConfirmRef.current.value;

    if (password !== confirmPassword) {
      setErrorMsg("passwords do not match");
      //setLoading(false);
      return null;
    }

    const bodyData = {
      code: code,
      password: passwordRef.current.value,
      passwordConfirmation: passwordRef.current.value,
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(bodyData),
      });

      const data = await response.json();
      console.log("data: ", data);

      if (data.data !== null) {
        //setLoading(false);
        setHasReset(true);
      } else {
        setErrorMsg(data.error.message);
      }
    } catch (error) {
      //setLoading(false);
      setErrorMsg(error.message);
      console.log("err: " + JSON.stringify(error.message));
    }
  };

  return (
    <div className="wrapper login-wrapper">
      <div className="auth-wrapper register">
        {!hasReset ? (
          <form onSubmit={handleSubmit}>
            <fieldset id="sign_up">
              <legend>
                <h4>
                  <strong>Reset your password</strong>
                </h4>
              </legend>
              <div className="mv3" style={{ marginBottom: "14px" }}>
                <input
                  ref={passwordRef}
                  type="password"
                  name="password"
                  placeholder="New password"
                  id="password"
                  required
                />
              </div>
              <div className="mv3" style={{ marginBottom: "14px" }}>
                <input
                  ref={passwordConfirmRef}
                  type="password"
                  name="confirmpassword"
                  placeholder="Confirm New Password"
                  id="confirmpassword"
                  required
                />
              </div>
            </fieldset>
            {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
            <div>
              <input id={"registersubmit"} type="submit" value="Reset" />
            </div>
            <div></div>
          </form>
        ) : (
          <p style={{ textAlign: "center" }}>
            <strong>Done! You can now login with your new password</strong>
          </p>
        )}
      </div>
    </div>
  );
}
