import React from "react";
import { Polyline } from "react-leaflet";

export default function RenderRouteTransitMap({ centreposition, routeresult }) {
  //console.log("routeresult in RenderRouteTransitMap: ", routeresult)
  const polylines = routeresult.polyline;
 // console.log("routeresult type: ", routeresult.sections[0].type);

  const polylineSegments = (segment) => {

    //console.log("segment in polylineSegments: ", segment)
    var color = "";

    switch (segment) {
      case "pedestrian":
        color = "yellow";
        break;
      case "regionalTrain":
        color = "red";
        break;
      case "highSpeedTrain":
        color = "red";
        break;
      case "cityTrain":
        color = "red";
        break;
      case "intercityTrain":
        color = "red";
        break;
      case "bus":
        color = "blue";
        break;
      default:
        color = "black";
    }

    return color;
  };

  const clickLine = () => {
    console.log("line clicked");
  };

  return (
    <div>
      {routeresult.data.map(
        (idx, i) =>
          i <= routeresult.data.length - 1 && (
            <Polyline
              eventHandlers={{
                click: () => clickLine(),
              }}
              key={i}
              weight={4}
              color={polylineSegments(routeresult.data[i].transport.mode)}
              positions={idx.polyline.polyline}
            />
          )
      )}
    </div>
  );
}
