// Dependencies
import { useState, memo } from "react";
import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import axios from "axios";

// JS pages
import CreateMemoryModal2 from "../modals/CreateMemoryModal";
import OneTrip from "./Query/onetrip";

import copyIcon from "../assets/copy.svg";

export function PaneDetailsTab(props) {
  const [open, setOpen] = useState(false);

  const getDate = (date) => {
    const d = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const displayDate = d.getDay() + " " + monthNames[d.getMonth()] + " " + d.getFullYear();
    return displayDate;
  };

  return (
    <div>
      <OneTrip id={props.travelideas}>
        {({ data: { tripdatum } }) => {
          const {
            Slug,
            title,
            translated_content,
            street,
            postalCode,
            City,
            country,
            lat,
            lon,
            enjoymentFactor,
            url,
            wikipedia,
            summitpost,
          } = tripdatum.data.attributes;

          const tagArray = tripdatum.data.attributes.tags.data;
          const { id } = tripdatum.data;

          //console.log("street: ", street);

          return (
            <div key={id}>
              <div className="detail-coordinates">
                <h6>
                  <span className="coords-prefix">LatLng:</span>
                  <span className="coords-content">
                  
                    {lat}, {lon}
                  </span>
                  <span
                    className="coords-copybtn"
                    alt="copy coordinates"
                    title="copy coordinates"
                    onClick={() => {
                      navigator.clipboard.writeText(lat + ", " + lon);
                    }}>
                    <img src={copyIcon} width={12} height={12} />
                  </span>
                </h6>
              </div>
              <div style={{ clear: "both" }} className="detail-title">
                <h3>{title}</h3>
              </div>
              <div className="detail-address">
                <h6>{street}</h6>
                <h6>
                  {postalCode} {City}
                </h6>
                <h6>{country}</h6>
              </div>

              <div className="taglist">
                {tagArray.map((idx, i) => {
                  return (
                    <div className="taglist-item" key={i}>
                      {idx.attributes.tag}
                    </div>
                  );
                })}
              </div>

              <div className={"e-rate"} title={"Enjoyment: " + enjoymentFactor}>
                {enjoymentFactor > 0 && (
                  <Rating
                    name="enjoyment-rating"
                    precision={0.5}
                    size="large"
                    value={enjoymentFactor}
                    readOnly
                  />
                )}
              </div>
              {<p>{translated_content}</p>}
              {url && (
                <div className="detail-url">
                  <a href={url.split(";")[0]} target="_blank" rel="noreferrer">
                    website
                  </a>
                </div>
              )}
              {wikipedia && (
                <div className="detail-url">
                  <a href={wikipedia} target="_blank" rel="noreferrer">
                    wikipedia
                  </a>
                </div>
              )}
              {summitpost && (
                <div className="detail-url">
                  <a href={summitpost} target="_blank" rel="noreferrer">
                    summitpost
                  </a>
                </div>
              )}
              {tripdatum.data.attributes.memories.data.length !== 0 ? (
                <div className={"detail-memory-heading"}>
                  <h3>
                    <strong>Memories</strong>
                  </h3>
                </div>
              ) : null}
              <div className="detail-memory-footer-content">
                <ul>
                  {tripdatum.data.attributes.memories.data.map((idx, i) => {
                    return (
                      <div key={i} className={"review-section"}>
                        <li>
                          <div className={"detail-memory-avatar"}></div>
                          <div className={"detail-memory-username"}>
                            <p>
                              <strong>
                                {idx.attributes.users_permissions_user.data.attributes.username}{" "}
                              </strong>
                            </p>
                          </div>
                          <div className={"detail-memory-rating"}>
                            <div className={"e-rate"}>
                              <Rating
                                name="enjoyment-rating"
                                precision={0.5}
                                size="medium"
                                value={parseFloat(idx.attributes.enjoyment)}
                                readOnly
                              />
                            </div>
                          </div>
                          <div className={"detail-memory-title"}>
                            <h4>{idx.attributes.title}</h4>
                            <div className={"detail-memory-date"}>
                              <h5>{getDate(idx.attributes.createdAt)}</h5>
                            </div>
                            <div className={"detail-memory-comment"}>
                              <p>
                                {idx.attributes.comment && idx.attributes.comment.substring(0, 80)}
                              </p>
                            </div>
                          </div>
                        </li>
                      </div>
                    );
                  })}
                </ul>
              </div>
              <div className={"detail-memory-footer"}>
                <div className={"detail-memory-footer-content"}></div>
              </div>
            </div>
          );
        }}
      </OneTrip>
      <CreateMemoryModal2
        tripDataId={props.travelideas}
        openRemote={open}
        parentCallback={() => setOpen(false)}
      />
    </div>
  );
}

export const MemoizedPaneDetailsTab = memo(PaneDetailsTab);
