// Dependencies
import { useState, useEffect } from "react";
import axios from "axios";

// Material ui
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import BootstrapDialogTitle from "./BootstrapDialogTitle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

// JS pages
import StyledTextField from "../styles/StyledTextField";

export default function EditMemoryModal({
  openRemote,
  userID,
  memoryId,
  oldTitle,
  oldComment,
  oldDate,
  publishState,
  parentCallback,
  refetchData,
}) {
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(oldTitle !== null ? oldTitle.split(", ") : []);
  const [comment, setComment] = useState(oldComment);
  const [outputDate, setOutputDate] = useState(null);
  const [newTags, setNewTags] = useState([]);
  const [checked, setChecked] = useState(publishState);
  const [publishLabel, setPublishLabel] = useState(
    publishState === true ? "published" : "not published"
  );

  useEffect(() => {
    openRemote && setOpen(true);
  }, [openRemote]);

  const getValue = (v) => {
    setNewTags(v);
  };

  const handlePublish = (e) => {
    setChecked(e.target.checked);
    setPublishLabel(e.target.checked === false ? "do not publish" : "publish");
  };

  const handleClose = () => {
    parentCallback();
    return null;
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    const body = {
      title: newTags.join(", "),
      users_permissions_user: userID,
      date: outputDate === null ? oldDate : outputDate,
      comment: comment,
      publish: checked,
    };

    const updateRes = await axios({
      method: "PUT",
      url: `api/mymemories/update/${memoryId}`,
      data: body,
    });
    if (updateRes.status === 200) {
      parentCallback();
      refetchData();
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Edit memory
      </BootstrapDialogTitle>
      <DialogContent>
        <label>
          <h4>What did you do here? (Type or select multiple options from the list)</h4>
        </label>
        <Autocomplete
          multiple
          id="tags-standard"
          options={title}
          freeSolo
          onChange={(event, newValue) => {
            getValue(newValue);
          }}
          getOptionLabel={(option) => option}
          defaultValue={title && title}
          renderInput={(params) => <StyledTextField {...params} variant="standard" />}
        />
        <label>
          <h4>Tell us something about your visit</h4>
        </label>
        <TextField
          style={{ width: "100%", fontSize: "30px" }}
          id="outlined-multiline-static"
          value={comment}
          multiline
          inputProps={{ maxLength: 800 }}
          rows={5}
          variant="outlined"
          onChange={(event) => {
            setComment(event.target.value);
          }}
        />
        <div>
          <label>
            <h4>Publish this memory so other people can learn from your experience</h4>
          </label>
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={checked}
                onChange={handlePublish}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={publishLabel}
          />
        </div>
        <DialogActions>
          <button className="tstbtn medium radiusall" onClick={handleClose}>
            Cancel
          </button>
          <button className="tstbtn medium radiusall" onClick={handleSubmit}>
            Update
          </button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
