// Dependencies
import Helmet from "react-helmet";

export default function SEO({ seo }) {
  const { category_sin } = seo.data[0].attributes.categories.data[0].attributes;
  const { title, country } = seo.data[0].attributes;
  const imageUrl = seo.data[0].attributes.image.data.attributes.url;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name={title} description={category_sin + " in " + country} />
      <meta property="og:title" content={title} />
      <meta property="og:twitter:title" content={title} />
      <meta property="og:imageUrl" content={imageUrl} />
      <meta property="og:image" content={imageUrl} />
      <meta property="fb:twitter:image" content={imageUrl} />
    </Helmet>
  );
}
