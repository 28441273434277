// Dependencies
import { useEffect, useRef } from "react";
import { Popup, Tooltip, Marker } from "react-leaflet";
import L from "leaflet";

// JS pages
import useWindowDimensions from "../utils/useWindowDimensions";
import getMarker from "./helpers/getMarker";

// icons
import shadow from "../assets/shadow.png";

const features = {
  className: "blinking",
  iconAnchor: [16, 16],
  popupAnchor: [0, -16],
  tooltipAnchor: [25, -5],
};

const m_shadow = new L.icon({ iconUrl: shadow, iconAnchor: [12, 20] });

export default function MarkersNearby({
  coords,
  image,
  data,
  selectedIndex,
  openPopup,
  closePopup,
  closePopupCB,
  sendLinkIdToParent,
  sendIdToParent,
  openPaneBWithLink
}) {
  const { width } = useWindowDimensions();
  const markerRef = useRef(null);

  useEffect(() => {
    if (openPopup === closePopup) {
      markerRef.current.closePopup();
    } else if (openPopup === data.id) {
      markerRef.current.openPopup();
    }
  }, [closePopup, data, openPopup]);

  const getDetails = (e) => {
    if (data.id !== undefined) {
      sendIdToParent(e);
    }
  };

  const linkToItem = (id) => {  
    openPaneBWithLink(id);
  };

  return (
    <div>
      <Marker position={coords} icon={m_shadow} ref={markerRef}></Marker>
      <Marker
        position={coords}
        icon={new L.icon({ iconUrl: getMarker(data.category_uid), ...features })}
        ref={markerRef}
        eventHandlers={{ click: (e) => getDetails(data.id) }}>
        {width > 990 && (
          <Tooltip sticky direction="right" offset={[20, 0]} opacity={0.8}>
            <h4>{data.title}</h4>
            <h6>{data.category_sin}</h6>
          </Tooltip>
        )}
        <Popup>
          <img src={image} alt="" height={80} width={50} />
          <div className="leaflet-popup-content-inner">
            <a href="#">
              <h4 onClick={() => linkToItem(selectedIndex)}>
                <strong>
                  {data.title}
                  {data.height && data.height !== 0 && " " + data.height + "m"}
                </strong>{" "}
              </h4>
            </a>
            <h6 style={{ color: "#a00707", marginTop: 0 }}>{data.category_sin}</h6>
          </div>
        </Popup>
      </Marker>
    </div>
  );
}
