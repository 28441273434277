import tokml from "geojson-to-kml";

export default function generateKMLtransit(
  departuretitle,
  departure,
  arrivaltitle,
  arrival,
  routeTransit
) {
  var features = [];

  const convertedLatLng = (coords) => {
    var convert = [];

    for (var i = 0; i < coords.length; i++) {
      convert.push([coords[i][1], coords[i][0]]);
    }
    return convert;
  };

  features.push({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [departure.lng, departure.lat],
    },
    properties: {
      name: departuretitle,
    },
  });

  for (var i = 0; i < routeTransit.length; i++) {
    var obj = {
      type: "Feature",
      geometry: {
        type: "LineString",
        coordinates: convertedLatLng(routeTransit[i].polyline.polyline),
      },
      properties: {
        name: "Route",
      },
    };
    features.push(obj);
  }

  features.push({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [arrival.lng, arrival.lat],
    },
    properties: {
      name: arrivaltitle,
    },
  });

  const geojsonObject = {
    type: "FeatureCollection",
    features: features,
  };
  const kml = tokml(geojsonObject);
  const blob = new Blob([kml], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.download = `Public-transport-route-to-${arrivaltitle}.kml`;
  link.href = url;
  link.click();
}
