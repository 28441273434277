// Dependencies
import { useState, useEffect } from "react";
//import { useQuery } from "@apollo/react-hooks";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// js pages
import SaveToTripModal from "../modals/SaveToTripModal";
import { useCurrentUser } from "./CurrentUser";
//import Logout from './Logout';

// icons
import favorite from "../assets/favorite.svg";
import favoriteHover from "../assets/favorite_hover.svg";
import favoriteSaved from "../assets/favoriteSaved.svg";

export default function SaveToTrip({ article, userID, isSaved }) {
  // init
  const navigate = useNavigate();
  const user = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [savedItem, setSavedItem] = useState();
  //const [ trips, setTrips ] = useState();
  const [saveNow, setSaveNow] = useState(false);
  const [tripData, setTripData] = useState(null);

  const refetchData = async () => {
    const res = await axios.get(`/api/users/me`);
    setTripData(res.data.trips);
  };

  useEffect(() => {
    user.isAuthenticated && refetchData();
  }, [user]);

  //var tripList = []

  const saveToFav = () => {
    if (user.isAuthenticated) {
      setSavedItem(article);
      setOpen(true);
    } else {
      navigate("/login");
    }
  };

  return (
    <div>
      <div id="savebtn" className="card-item-header-btn curve-left" >
        {isSaved || saveNow ? (
          <div>
            <img src={favoriteSaved} alt={"Saved!"} title={"Saved!"} width={20} height={20} />
          </div>
        ) : (
          <div onClick={saveToFav}>
            <img
              src={favorite}
              alt={"Save this location"}
              title={"Save this location"}
              width={38}
              height={38}
              onMouseOver={(e) => (e.currentTarget.src = favoriteHover)}
              onMouseOut={(e) => (e.currentTarget.src = favorite)}
            />
          </div>
        )}
      </div>

      <SaveToTripModal
        openRemote={open}
        savedItem={savedItem}
        itineraries={tripData}
        isSavedCB={(childData) => setSaveNow(childData)}
      />
    </div>
  );
}
