import Select from 'react-select';

export default function TestSelectCountryList({ countries, parentCallback}) { 

  function handleInputChange(selectedOption) {

    if (selectedOption !== null) {
      parentCallback(selectedOption.value)     
    } else {
      parentCallback(null)

    }
  }

  return ( 
    <Select 
    placeholder={'Select country...'}
    isSearchable={false}
    options={countries}
    isClearable={true}
    onChange={handleInputChange}
    />
    )
}
