// Dependencies
import { useState, useEffect } from "react";
import axios from "axios";

// Material ui
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/styles";
import { Icon } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import BootstrapDialogTitle from "./BootstrapDialogTitle";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";

// styling
import StyledTextField from "../styles/StyledTextField";

// JS pages
import { useCurrentUser } from "../component/CurrentUser";

// assets
import person from "../assets/person.png";
import personEmpty from "../assets/person_empty.png";

const Person = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img src={person} alt="person" height={38} width={19} />
  </Icon>
);

const PersonEmpty = () => (
  <Icon style={{ height: "initial", width: "initial" }}>
    <img src={personEmpty} alt="noperson" height={38} width={19} />
  </Icon>
);

const labels = {
  0.5: "I was / we were alone here",
  1: "I was / we were alone here",
  1.5: "A few people",
  2: "A few people",
  2.5: "Some more people than expected",
  3: "Some more people than expected",
  3.5: "A little crowded",
  4: "A little crowded",
  4.5: "Extremely crowded",
  5: "Extremely crowded",
};

const options = ["hiking", "swimming", "horse back riding"];

const StyledMultiTextField = styled(TextField)({
  root: {
    "& .MuiOutlinedInput-root": {
      fontSize: 20,
      padding: "5px",
    },
  },
});

export default function CreateMemoryModal({ tripDataId, openRemote, parentCallback, refetchData }) {
  // init
  const user = useCurrentUser();
  const [open, setOpen] = useState(false);
  const [authFactor, setAuthFactor] = useState(null);
  const [silentFactor, setSilentFactor] = useState(null);
  const [activities, setActivities] = useState([]);
  const [comment, setComment] = useState(null);
  const [isEmpty, setIsEmpty] = useState({ activity: false, authenticity: false, silent: false });
  const [outputDate, setOutputDate] = useState(null);
  const [submitSuccesful, setSubmitSuccesful] = useState(false);
  const [hover, setHover] = useState(-1);
  const [checked, setChecked] = useState(true);
  const [publishLabel, setPublishLabel] = useState("publish");

  useEffect(() => {
    openRemote && setOpen(true);
  }, [openRemote]);

  const handleClose = () => {
    setOpen(false);
    parentCallback(null);
  };

  const save = async () => {
    if (authFactor === null) {
      setIsEmpty({ authenticity: true });
      return null;
    }

    if (silentFactor === null) {
      setIsEmpty({ silent: true });
      return null;
    }

    const body = {
      title: activities === [] ? null : activities.join(", "),
      tripdatum: tripDataId,
      enjoyment: authFactor,
      users_permissions_user: user.id,
      date: outputDate,
      comment: comment,
      publish: checked,
    };

    const createRes = await axios({
      method: "POST",
      url: "/api/mymemories/create",
      data: body,
    });
    if (createRes.status === 200) {
      setOpen(false);
      refetchData();
    }
  };

  const handleAuthFactor = (event) => {
    setAuthFactor(event.target.value);
    setIsEmpty({ authenticity: false });
  };

  const handleSilentFactor = (event) => {
    setSilentFactor(event.target.value);
    setIsEmpty({ silent: false });
  };

  const getComment = (event) => {
    setComment(event.target.value);
  };

  const handlePublish = (e) => {
    console.log("publish or not: ", e.target.checked);
    setChecked(e.target.checked);
    setPublishLabel(e.target.checked === false ? "do not publish" : "publish");
  };

  const getValue = (v) => {
    console.log("activities: ", v);
    setActivities(v);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={"sm"}
      aria-labelledby="simple-dialog-title"
      open={open}
      onClose={handleClose}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Create a memory
      </BootstrapDialogTitle>
      <DialogContent>
        {submitSuccesful === false && (
          <div>
            <label>
              <h4>What did you do here? (Type or select multiple options from the list)</h4>
            </label>
            <Autocomplete
              multiple
              id="tags-filled"
              options={options.map((option) => option)}
              freeSolo
              onChange={(event, newValue) => {
                getValue(newValue);
              }}
              renderInput={(params) => <StyledTextField {...params} variant="standard" />}
            />

            <label>
              <h4>Tell something about your visit</h4>
            </label>
            <StyledMultiTextField
              style={{ width: "100%", fontSize: "30px" }}
              id="outlined-multiline-static"
              multiline
              inputProps={{ maxLength: 800 }}
              rows={5}
              variant="outlined"
              onChange={(event) => {
                getComment(event);
              }}
            />
            <label>
              <h4>How much did you enjoy this travel spot?</h4>
            </label>
            <div>
              <Rating
                name="enjoyment-rating"
                precision={0.5}
                defaultValue={0}
                size="large"
                onChange={(event) => {
                  handleAuthFactor(event);
                }}
              />
              {isEmpty.authenticity && <p style={{ color: "red" }}>please select your rating!</p>}
            </div>
            <label>
              <h4>how quiet or crowded was this location?</h4>
            </label>

            <Rating
              name="silent-rating"
              precision={0.5}
              defaultValue={0}
              size="large"
              onChange={(event) => {
                handleSilentFactor(event);
              }}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              icon={<Person />}
              emptyIcon={<PersonEmpty />}
            />
            {
              <Box style={{ display: "inline-block" }}>
                {labels[hover !== -1 ? hover : silentFactor]}
              </Box>
            }
            {isEmpty.silent && (
              <p style={{ color: "red" }}>please select how quiet the location was!</p>
            )}
            <div>
              <label>
                <h4>Publish this memory so other people can learn from your experience</h4>
              </label>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={checked}
                    onChange={handlePublish}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={publishLabel}
              />
            </div>
            <DialogActions>
              <button className="tstbtn medium radiusall" onClick={handleClose}>
                Cancel
              </button>
              <button className="tstbtn medium radiusall" onClick={save}>
                Submit
              </button>
            </DialogActions>
          </div>
        )}
        {submitSuccesful ? (
          <h4>Awesome, you've created a memory! It will be added to the location!</h4>
        ) : null}
      </DialogContent>
    </Dialog>
  );
}
